import React from 'react';
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store/configureStore';
import "antd/dist/antd.min.css";
import "./index.scss"
import {useLocation} from "react-router-dom";
import actions from "./redux/auth/authActions";

const{setRoute} = actions

const root = ReactDOM.createRoot(document.getElementById("root"))

const pathname = window.location.pathname;

store.dispatch(setRoute(pathname))

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
