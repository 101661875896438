import actions from "./contentCreatorActions";

const initState = {
    contentCreators: null,
    contentCreator: null,
    updateList: false,
    updatePage: true,
    backToList: false
}

export default function contentCreatorReducer(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_STATE:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_CONTENT_CREATOR_SUCCESS:
            return{
                ...state,
                contentCreators: action.payload.value ? action.payload.value : [],
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_CONTENT_CREATOR_BY_ID_SUCCESS:
            return {
                ...state,
                contentCreator: action.payload.value,
                updatePage: true
            };
        case actions.SAVE_NEW_CONTENT_CREATOR_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_CONTENT_CREATOR_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_CONTENT_CREATOR_SUCCESS:
            return {
                ...state,
                updateList: true
            };
        case actions.ENABLE_CONTENT_CREATOR:
            return {
                ...state,
                updateList: false
            };
        case actions.ENABLE_CONTENT_CREATOR_SUCCESS:
            return {
                ...state,
                updateList: true
            };
        default:
            return state;
    }

}