import React, {useEffect, useRef, useState} from "react";
import {Col, Input, Popconfirm, Row, Spin} from "antd";
import dateFormat from "dateformat";
import EditIcon from "../../svg/editIconBlack.svg";
import DeleteIcon from "../../svg/trashIconBlack.svg";
import {endpoints} from "../../config/site.config";
import {getToken} from "../../helpers/utility";
import {InputStyle} from "../../style/InputStyle";
import {useDispatch} from "react-redux";
import actions from "../../redux/folder/folderActions"
import {useNavigate} from "react-router-dom";
import {FolderStyle} from "../../style/FolderStyle";

export default function FolderCard(props) {

    const {editFolder} = actions
    const item = props.item
    const [images, setImages] = useState([])
    const [showField, setShowField] = useState(false)
    const [editValue, setEditValue] = useState(false)
    const [loading, setLoading] = useState(false)
    const editInput = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(images.length === 0 && item.markerUrlList){
            let requestBody = {name: item.markerUrlList[0]}
            setLoading(true)
            Promise.all(
                item.markerUrlList.map((markerUrl) =>
                    fetch(endpoints.getImageResource, {
                            method: 'post',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getToken(),
                            }),
                            body: JSON.stringify({name: markerUrl, width: 130, height: 130, fill: true})
                        }
                    ).then(result => result.blob()).then(blob => URL.createObjectURL(blob))
                )).then((markerBlobs) => {
                    setImages(markerBlobs)
                    setLoading(false)
                })
        }

        return () => {setImages("")}
    }, [])

    useEffect(() => {
        if (showField) {
            editInput.current.focus({
                cursor: 'all'
            })
        }
    }, [showField])

    useEffect(() => {
        if (item.fakeFolder) {
            setShowField(true)
            setEditValue(item.title)
        }
    }, [item.fakeFolder])

    const handleEdit = (e) => {
        e.stopPropagation()
        if (showField) {
            setShowField(false)
        } else {
            setShowField(true)
            setEditValue(item.title)
        }
    }

    const handleChange = (e) => {
        let data = {folderName: e.target.value}
        dispatch(editFolder(item.folderId, data))
        setShowField(false)
        props.onCreated()
    }

    const handleCancel = () => {
        setShowField(false)
    }

    const showFolderArtworks = (folderId) => {
        navigate("/artWork/" + folderId)
    }


    return (
        <FolderStyle>
            <Spin spinning={loading}>
            <div className={"card-container"} onClick={() => showFolderArtworks(item.folderId)}>
                <Row gutter={[32,32]}>
                    {images.length !== 0 && images.map((image) => {
                        return(
                        <Col span={12} className={"flex-center"}>
                            <div className={"image-container"} >
                                <img src={image} style={{objectFit: "contain", minWidth: "100%", minHeight: "100%"}}/>
                            </div>
                        </Col>
                        )
                    })}
                </Row>
                <div className={"folder-info"}>
                    <Row style={{height: "100%"}}>
                        <Col lg={12} sm={12} xs={12}>
                            <div className={"flex-left"}>
                                {showField ? <InputStyle
                                        borderRadius={"6px"}
                                        size={"25px"}
                                        fontSize={"16px"}
                                        fontWeight={"bold"}>
                                        <Input
                                            ref={editInput}
                                            value={editValue}
                                            onChange={(e) => setEditValue(e.target.value)}
                                            onClick={(e) => e.stopPropagation()}
                                            onBlur={() => handleCancel()}
                                            onPressEnter={(e) => handleChange(e)}
                                        >
                                        </Input>
                                    </InputStyle> :
                                    <p style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "20px",
                                        color: "black",
                                        marginBottom: "10px"
                                    }}>{item.title}</p>}
                                <p style={{
                                    fontSize: "16px",
                                    lineHeight: "16px",
                                    color: "black",
                                    marginBottom: "10px"
                                }}>{dateFormat(new Date(item.creationDataTimestamp).toISOString(), "dd-mm-yyyy")}</p>
                                {localStorage.getItem("roles") === "ROLE_ADMIN" && item.creatorUsername !== null &&
                                <p style={{
                                    fontSize: "16px",
                                    lineHeight: "16px",
                                    color: "black"
                                }}>Creato da {item.creatorUsername === localStorage.getItem("username") ? "Te" : item.creatorUsername}</p>}
                            </div>

                        </Col>
                        <Col lg={12} sm={12} xs={12}>
                            <div className={"flex-right"}>
                                <img style={{padding: "4px"}} src={EditIcon} onClick={(e) => handleEdit(e)}/>
                                <Popconfirm color={"white"}
                                                 title={"Sei sicuro di voler eliminare?"}
                                                 onClick={(e) => e.stopPropagation()}
                                                 onConfirm={(e) => props.handleDelete(item.folderId, e)}>
                                    <img style={{padding: "4px"}} src={DeleteIcon}/>
                                </Popconfirm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            </Spin>
        </FolderStyle>
    )
}