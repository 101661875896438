import React, {useEffect, useState} from "react"
import {Button, Card, Col, Form, Row} from "antd";
import {AddTenantStyle} from "../../../style/AddTenantStyle";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {getRouteLandingPage} from "../../../helpers/utility";
import actions from "../../../redux/tenant/tenantActions";
import authActions from "../../../redux/auth/authActions";
import Input from "../../Input";
import {ButtonStyle} from "../../../style/ButtonStyle";

const {addNewTenant, getTenantById, initializeState, updateTenant} = actions
const {setRoute} = authActions

function TenantAddMobile(){

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tenant = useSelector(state => state.Tenant.tenant)

    const {id} = useParams()

    const [tenantName, setTenantName] = useState(null)
    const [adminName, setAdminName] = useState(null)
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const backToList = useSelector(state => state.Tenant.backToList)

    const handleAdd = (fieldsValue) => {
        let data = {
            tenantName: fieldsValue['tenantName'],
            administratorFirstName: fieldsValue['adminName'],
            administratorLastName: "prova",
            administratorEmail: "prova.prova.it",
            username: fieldsValue['username'],
            password: fieldsValue['password'],
            enabled: true,
        }
        if (id) {
            dispatch(updateTenant(data, id))
        } else {
            dispatch(addNewTenant(data))
        }
    }

    const handleCancel = () => {
        navigate(getRouteLandingPage())
    }

    React.useEffect(() => {
        if (backToList) navigate(getRouteLandingPage())
    }, [backToList])


    const clearState = () => {
        setTenantName("")
        setAdminName("")
        setUsername("")
        setPassword("")
    }

    useEffect(() => {
        clearState()
    }, [id])

    useEffect(() => {
        if (id) {
            dispatch(getTenantById(id))
        }
        return () => {
            dispatch(initializeState())
        }
    }, [])


    useEffect(() => {
        if (tenant) {
            setTenantName(tenant.tenantName)
            setAdminName(tenant.mainAdminFirstNameLastName)
            setUsername(tenant.username)
            setPassword(null)
        }
    }, [tenant])

    return(
        <div style={{paddingLeft:20, paddingRight:20}}>
            <Form
                name={"register"}
                onFinish={handleAdd}
                key={tenantName}
            >
                <Card className="card-container-card" title={ id ? "Modifica Tenant" : "Nuovo Tenant"}>
                    <Row style={{display:"flex", justifyContent:"center"}}>
                        <Col>
                            <Form.Item
                                name={"tenantName"}
                                rules={[{required: true, message: 'Inserire il nome del Tenant'}]}
                                initialValue={tenantName}
                            >
                                <Input type={"text"} placeholder={"Nome Tenant"}/>
                            </Form.Item>
                            <Form.Item
                                name={"adminName"}
                                rules={[{required: id == null, message: "Inserire il nome dell'Admin"}]}
                                initialValue={adminName}
                            >
                                <Input type={"text"} placeholder={"Nome Amministratore"} disabled={id != null}/>
                            </Form.Item>
                            <Form.Item
                                name={"username"}
                                rules={[{required: id == null, message: "Inserire l'username dell'Admin"}]}
                                initialValue={username}
                            >
                                <Input type={"text"} placeholder={"Username"} disabled={id != null}/>
                            </Form.Item>
                            <Form.Item
                                name={"password"}
                                rules={[{required: id == null, message: "Inserire la password dell'Admin"}]}
                                initialValue={password}
                            >
                                <Input type={"password"} placeholder={"Password"} disabled={id != null}/>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle>
                                    <Button block htmlType="submit">{id ? "MODIFICA" : "AGGIUNGI"}</Button>
                                </ButtonStyle>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle color={"rgb(157,159,163)"}>
                                    <Button block onClick={handleCancel}>CANCELLA</Button>
                                </ButtonStyle>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    )
}

export default TenantAddMobile
