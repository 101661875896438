const actions = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
    SET_IDTOKEN: "SET_IDTOKEN",
    CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
    LOGOUT: "LOGOUT",
    SET_ROUTE: "SET_ROUTE",

    RECOVER_PASSWORD: "RECOVER_PASSWORD",
    RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
    RECOVER_PASSWORD_ERROR: "RECOVER_PASSWORD_ERROR",

    RESET_SUCCESS_MESSAGE: "RESET_SUCCESS_MESSAGE",
    RESET_SUCCESS_MESSAGE_SUCCESS: "RESET_SUCCESS_MESSAGE_SUCCESS",
    RESET_SUCCESS_MESSAGE_ERROR: "RESET_SUCCESS_MESSAGE_ERROR",

    LOG_AS_TENANT: "LOG_AS_TENANT",
    LOG_AS_TENANT_SUCCESS: "LOG_AS_TENANT_SUCCESS",
    LOG_AS_TENANT_ERROR: "LOG_AS_TENANT_ERROR",

    LOG_BACK: "LOG_BACK",
    LOG_BACK_SUCCESS: "LOG_BACK_SUCCESS",
    LOG_BACK_ERROR: "LOG_BACK_ERROR",

    LOADING_TRUE : "LOADING_TRUE",
    LOADING_FALSE : "LOADING_FALSE",

    checkAuthorization: () => ({
        type: actions.CHECK_AUTHORIZATION
    }),

    login: (data) => ({
        type: actions.LOGIN_REQUEST,
        payload: data
    }),

    recoverPassword: (data) => ({
        type: actions.RECOVER_PASSWORD,
        payload: data
    }),

    resetMessage: () => ({
       type: actions.RESET_SUCCESS_MESSAGE
    }),

    resetErrorMessage: () => ({
        type: actions.RESET_ERROR_MESSAGE,
    }),

    setIdToken: () => ({
        type: actions.SET_IDTOKEN
    }),

    logout: () => ({
        type: actions.LOGOUT
    }),

    setRoute: (route) => ({
        type: actions.SET_ROUTE,
        payload: route
    }),

    logAsTenant: (tenantId) => ({
        type: actions.LOG_AS_TENANT,
        payload: tenantId
    }),

    logBack: () => ({
        type: actions.LOG_BACK
    })

}

export default actions
