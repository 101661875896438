import React from "react";
import {SidebarStyle} from "../style/sidebarStyle";
//import logo from "../img/Logo.png"
import logo from '../svg/Logo.svg'
import {useLocation, useNavigate} from "react-router-dom"
import {getSidebarPages} from "../helpers/utility";
import PointerIcon from "../svg/pointer.svg";
import {useDispatch} from "react-redux";
import actions from "../redux/auth/authActions";

const {setRoute} = actions

export default function Sidebar(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const location = useLocation()
    const currentRoute = location.pathname

    const handleClick = (index, route) => {
        dispatch(setRoute(route))
        navigate(route)
    }

    return(
        <SidebarStyle>
            <div className={"sidebar-content"}>
                <div className={"pointer-container"}>
                    {getSidebarPages().map((page, index) => {

                        let visible
                            if(currentRoute.includes(page.route)){
                                visible = "visible"
                            }else{
                                visible = "hidden"
                            }

                        return(
                                <img key={"pointer" + index} src={PointerIcon} className={"pointer"} style={{visibility: visible}}/>
                            )
                    })}
                </div>
                <div className={"icon-container"}>
                    {getSidebarPages().map((page, index) => {
                        return(
                            <img key={"icon" + index} src={page.icon} className={"page-icon"} onClick={() => handleClick(index, page.route)}/>
                        )
                    })}
                </div>
                <div className={"label-container"}>
                    {getSidebarPages().map((page, index) => {

                        let visible
                            if(currentRoute.includes(page.route)){
                                visible = "visible"
                            }else{
                                visible = "hidden"
                            }

                        return(
                            <div key={"sidebarLabel" + index} className={"label"} style={{visibility: visible}}>{page.label}</div>
                        )
                    })}
                </div>
            </div>
        </SidebarStyle>
    )
}
