import React, {useEffect, useState} from "react";
import {Button, Col, Input, Popconfirm, Row, Switch, Table, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/tenant/tenantActions";
import authActions from "../../redux/auth/authActions";
import {TableStyle} from "../../style/TableStyle";
import {ButtonTenantStyle} from "../../style/ButtonTenantStyle";
import EditIcon from "../../svg/edit.svg";
import DeleteIcon from "../../svg/trash.svg";
import {ButtonStyle} from "../../style/ButtonStyle";
import {useNavigate} from "react-router-dom";
import {InputStyle} from "../../style/InputStyle";
import SearchIcon from "../../svg/search.svg";
import {createGlobalStyle} from "styled-components"
import {getRouteLandingPage} from "../../helpers/utility";
import {PRIVATE_ROUTE} from "../../route.constants";

const {Text} = Typography;
const {initializeState, getAll, enable, deleteTenant, setTenants} = actions;
const {logAsTenant} = authActions;
const {setRoute} = authActions;

const Styled = createGlobalStyle`
    body{
        .ant-popover-arrow-content{
            background-color: white !important;
        }
        .ant-popover-arrow-content::before{
            background: white ;
        }
    }
    
`;

export default function BlankPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [tenantsFiltered, setTenantsFiltered] = useState(null)
    const token = useSelector(state => state.authReducer.idToken)

    const handleAdd = () => {
        dispatch(setRoute(PRIVATE_ROUTE.ADD_TENANT))
        navigate(PRIVATE_ROUTE.ADD_TENANT);
    }

    const handleDelete = (id) => {
        dispatch(deleteTenant(id))
    }

    const handleEdit = (id) => {
        navigate(PRIVATE_ROUTE.ADD_TENANT + "/" + id)
    }

    const hadleLogAsTenant = (tenantId , row) => {
        localStorage.setItem("tenantName", row.tenantName)
        dispatch(logAsTenant(tenantId))
    }

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            setTenantsFiltered(tenants.filter((tenant) => tenant.tenantName.toLowerCase().includes(e.target.value.toLowerCase()) || tenant.administratorName.toLowerCase().includes(e.target.value.toLowerCase())))
        }
        if (e.target.value.length < 3) {
            setTenantsFiltered(null)
        }
    }

    const columns = [
        {
            title: 'ADMIN',
            dataIndex: 'tenantName',
            key: 'tenantName',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            /*sorter: (a, b) => {
                a.alias.localeCompare(b.alias)
            },*/
            render: (text) => {
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: 'AMMINISTRATORE',
            dataIndex: 'administratorName',
            key: 'administratorName',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            /*sorter: (a, b) => {
                a.alias.localeCompare(b.alias)
            },*/
            render: (text) => {
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: 'STATO',
            dataIndex: 'enabled',
            key: 'enabled',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            /*sorter: (a, b) => {
                a.alias.localeCompare(b.alias)
            },*/
            render: (enabled, row) => {
                return <Switch checked={enabled} onChange={() => onChange(row.tenantId)}/>
            }
        },
        {
            title: 'ACCEDI',
            dataIndex: 'tenantId',
            key: 'tenantId',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            /*sorter: (a, b) => {
                a.alias.localeCompare(b.alias)
            },*/
            render: (it, row) => {
                return <ButtonTenantStyle><Button onClick={() => hadleLogAsTenant(it, row)}>ACCEDI AL TENANT</Button></ButtonTenantStyle>
            }
        },
        {
            title: 'AZIONI',
            //width:'200px',
            className: 'maxWidth300px',
            align: 'center',
            /*sorter: (a, b) => {
                a.alias.localeCompare(b.alias)
            },*/
            render: (it, row) => {
                return <Row justify={"space-around"}>
                    <Col><img src={EditIcon} onClick={() => handleEdit(row.tenantId)}/></Col>
                    <Col><Popconfirm color={"white"} title={"Sei sicuro di voler eliminare?"} onConfirm={() => handleDelete(row.tenantId)}><Styled/><img src={DeleteIcon}/></Popconfirm></Col>
                </Row>
            }
        }
    ]

    const updateList = useSelector(state => state.Tenant.updateList)
    const totalResults = useSelector(state => state.Tenant.totalResults)
    const tenants = useSelector(state => state.Tenant.tenants)

    const [defaultPageSize, setDefaultPageSize] = React.useState(10)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if (!tenants || updateList) {
            dispatch(getAll())
        }
        return () => {
            dispatch(initializeState())
        }

    }, [])

    React.useEffect(() => {
        if(updateList) {
            dispatch(getAll())
        }
    }, [updateList])

    useEffect(() => {
        if(token){
            navigate(getRouteLandingPage())
        }
    },[token])

    function onChange(id) {
        dispatch(enable(id))
    }

    return (
        <TableStyle>
            <Row gutter={[10, 10]}>
                <Col span={6} offset={10}>
                    <InputStyle size={"36px"}><Input placeholder={"Search"} onChange={handleSearch}
                                                     prefix={<img src={SearchIcon}/>}/></InputStyle>
                </Col>
                <Col span={6}>
                    <ButtonStyle><Button block onClick={handleAdd}>+ AGGIUNGI</Button></ButtonStyle>
                </Col>
                <Col lg={22} sm={22} xs={22}>
                    <Table
                        //scroll={{x: 1500}}
                        scroll={{x: true}}
                        rowKey={record => record.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (e) => {
                                    //createOrModify(e, record.id)
                                },
                            };
                        }}
                        className='clearTop customTable' dataSource={tenantsFiltered ? tenantsFiltered : tenants}
                        columns={columns}
                        pagination={{
                            total: totalResults,
                            pageSizeOptions: ['10', '20', '30', '50', '100'],
                            showSizeChanger: true,
                            defaultPageSize: defaultPageSize
                        }}
                        onChange={(pagination, filters, sorter, extra) => {
                            //updatePaginationData(pagination, sorter)
                            //dispatch(getAll(createPaginationData(pagination, sorter)))
                        }}
                    />
                </Col>
            </Row>
        </TableStyle>
    )
}
