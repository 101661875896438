import styled from "styled-components"

export const HomeStyle = styled.div`
    height: 100%;
    background: rgb(37,59,96);
    background: linear-gradient(135deg, rgba(37,59,96,1) 0%, rgba(14,14,0,1) 88%);

    .content-container{
        padding-left: 350px;
        padding-top: 150px;
        height: 100%;
    }
    
    .content{
        height: 100vh;
        overflow: hidden auto;
        padding-bottom: 200px;
    }
`
