import React, {useEffect} from "react";
import {PRIVATE_MOBILE_ROUTE, PRIVATE_ROUTE, PUBLIC_ROUTE} from "./route.constants";
import Login from "./Pages/Login";
import Home from "./Pages/Home"
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import Tenants from "./Pages/tenant/tenants";
import AddTenant from "./Pages/tenant/addTenant";
import Admins from "./Pages/admin/admins";
import addAdmin from "./Pages/admin/addAdmin";
import ArtWork from "./Pages/artWork/artWorks";
import AddArtWork from "./Pages/artWork/addArtWork"
import Folders from "./Pages/folder/folders"
import HomePageAdmin from "./Pages/admin/homePageAdmin";
import HomePageContentCreator from "./Pages/contentCreator/homePageContentCreator";
import HomeAdminMobile from "./component/mobile/admin/HomeAdminMobile";
import HomeContentCreatorMobile from "./component/mobile/contentCreator/HomeContentCreatorMobile";
import TenantMobile from "./component/mobile/tenant/TenantMobile";
import TenantAddMobile from "./component/mobile/tenant/TenantAddMobile";
import HomeMobile from "./component/mobile/HomeMobile";
import UsersMobile from "./component/mobile/admin/UsersMobile";
import ArtWorkMobile from "./component/mobile/artWork/ArtWorkMobile";
import AddArtWorkMobile from "./component/mobile/artWork/AddArtWorkMobile";
import FolderMobile from "./component/mobile/folder/FoldersMobile";
import Catalog from "./Pages/catalog/catalog"
import {getWindowSize} from "./helpers/utility";
import NewUsers from "./component/mobile/newUsers/NewUsers";
import AddCatalog from "./Pages/catalog/addCatalog";

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: Login
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: Login
    },
    {
        path: "/redirect",
        component: Login
    }
]

const privateRoute = [
    {
        path: PRIVATE_ROUTE.MANAGE_TENANT,
        component: Tenants
    },
    {
        path: PRIVATE_ROUTE.ADD_TENANT,
        component: AddTenant
    },
    {
        path: PRIVATE_ROUTE.MODIFY_TENANT,
        component: AddTenant
    },
    {
        path: PRIVATE_ROUTE.MANAGE_ADMIN,
        component: Admins
    },
    {
        path: PRIVATE_ROUTE.MODIFY_ADMIN_USERS,
        component: addAdmin
    },
    {
        path: PRIVATE_ROUTE.ADD_ADMIN_USER,
        component: addAdmin
    },
    {
        path: PRIVATE_ROUTE.ADD_DEFAULT_ADMIN,
        component: addAdmin
    },
    {
        path: PRIVATE_ROUTE.ART_WORK,
        component: ArtWork
    },
    {
        path: PRIVATE_ROUTE.FOLDER_ART_WORK,
        component: ArtWork
    },
    {
        path: PRIVATE_ROUTE.ADD_ART_WORK,
        component: AddArtWork
    },
    {
        path: PRIVATE_ROUTE.MODIFY_ART_WORK,
        component: AddArtWork
    },
    {
        path: PRIVATE_ROUTE.FOLDER,
        component: Folders
    },
    {
        path: PRIVATE_ROUTE.HOME_PAGE_ADMIN,
        component: HomePageAdmin
    },
    {
        path: PRIVATE_ROUTE.HOME_PAGE_CONTENT_CREATOR,
        component: HomePageContentCreator
    },
    // {
    //     path: PRIVATE_ROUTE.CATALOG,
    //     component: Catalog
    // },
    // {
    //     path: PRIVATE_ROUTE.ADD_CATALOG,
    //     component: AddCatalog
    // },
    // {
    //     path: PRIVATE_ROUTE.MODIFY_CATALOG,
    //     component: AddCatalog
    // }

]

const privateMobileRoute = [
    {
        path: PRIVATE_MOBILE_ROUTE.MANAGE_TENANT,
        component: TenantMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.ADD_TENANT,
        component: TenantAddMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.MODIFY_TENANT,
        component: TenantAddMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.MANAGE_ADMIN,
        component: UsersMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.MODIFY_ADMIN_USERS,
        component: NewUsers
    },
    {
        path: PRIVATE_MOBILE_ROUTE.ADD_ADMIN_USER,
        component: NewUsers
    },
    {
        path: PRIVATE_MOBILE_ROUTE.ADD_DEFAULT_ADMIN,
        component: NewUsers
    },
    {
        path: PRIVATE_MOBILE_ROUTE.ART_WORK,
        component: ArtWorkMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.FOLDER_ART_WORK,
        component: ArtWorkMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.ADD_ART_WORK,
        component: AddArtWorkMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.MODIFY_ART_WORK,
        component: AddArtWorkMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.FOLDER,
        component: FolderMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.HOME_PAGE_ADMIN,
        component: HomeAdminMobile
    },
    {
        path: PRIVATE_MOBILE_ROUTE.HOME_PAGE_CONTENT_CREATOR,
        component: HomeContentCreatorMobile
    }
]

export default function Router() {

    const isLoggedIn = useSelector(state => state.authReducer.idToken)
    const hasCatalogEnabled = localStorage.getItem("hasCatalogEnabled")
    if(hasCatalogEnabled === 'true'){
        privateRoute.push(
            {
                path: PRIVATE_ROUTE.CATALOG,
                component: Catalog
            },
            {
                path: PRIVATE_ROUTE.ADD_CATALOG,
                component: AddCatalog
            },
            {
                path: PRIVATE_ROUTE.MODIFY_CATALOG,
                component: AddCatalog
            }
        )
    }
    return(
        <BrowserRouter>
            <Routes>
                {publicRoutes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                ))}

                <Route path={""}
                       element={isLoggedIn ? getWindowSize() > 500 ? <Home/> : <HomeMobile/> : <Navigate to="/" replace />}
                >

                    {window.innerWidth > 500 ?
                        privateRoute.map((route, index) => (
                        <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                    )) : privateMobileRoute.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                        ))}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

