import actions from "./artWorkActions";

const initState = {
    artWorks: null,
    artWork: null,
    updateList: false,
    backToList: false,
    markerFile: null,
    mediaFile: null,
    mediaFileName: null,
    artWorksImages: [],
    markerQuality: null,
}

export default function artWorkReducer(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_STATE:
            return {
                initState
            };
        case actions.SAVE_NEW_ARTWORK_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.GET_ALL_ARTWORKS_SUCCESS:

            return {
                ...state,
                artWorks: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            }
        case actions.GET_ALL_ARTWORKS_BY_FILTERS_SUCCESS:
            return {
                ...state,
                artWorks: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            }
        case actions.GET_ARTWORK_BY_ID_SUCCESS:
            return {
                ...state,
                artWork: action.payload.value,
                markerQuality: action.payload.value.markerQuality,
                updatePage: true
            };
        case actions.UPDATE_ARTWORK_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_ARTWORK_SUCCESS:
            return {
                ...state,
                updateList: true
            }
        case actions.DELETE_ARTWORK_ERROR:
            return {
                ...state
            }
        case actions.DOWNLOAD_MEDIA_FILE_SUCCESS:
            return {
                ...state,
                mediaFile: action.blob,
                mediaFileName: action.fileName,
                updateList: false,
                backToList: false
            }
        case actions.DOWNLOAD_MARKER_FILE_SUCCESS:
            if(action.artworkId){
                let newArtWorksImages = [...state.artWorksImages]
                action.payload.blob().then(blob => {
                    newArtWorksImages.push({artWorkId: action.artworkId, image: blob})
                    return {
                        ...state,
                        artWorksImages: newArtWorksImages
                    }
                })
            }else{
                return {
                    ...state,
                    markerFile: action.payload,
                    updateList: false,
                    backToList: false
                }
            }
        case actions.CHECK_ARTWORK_QUALITY_SUCCESS:
            return {
                ...state,
                markerQuality: action.payload.value
            }
        default:
            return state;
    }

}
