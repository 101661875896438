import React from "react";
import {TopbarStyle} from "../style/TopbarStyle";
import logoutIcon from "../svg/logout.svg"
import {useDispatch} from "react-redux";
import actions from "../redux/auth/authActions";
import {Roles} from "../helpers/Roles";
import {Button} from "antd";
import {ButtonTenantStyle} from "../style/ButtonTenantStyle";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
//import logo from "../img/Logo.png";
import logo from '../svg/Logo.svg'
import {useNavigate} from "react-router";

const {logout, logBack} = actions

export default function Topbar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleGoBackClick = () => {
        dispatch(logBack())
    }

    const goHome = () =>{
        if(localStorage.getItem("roles").includes(Roles.ADMIN)){
            navigate("/adminHome")
        }else{
            navigate("/contentCreatorHome")
        }
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Button onClick={(e) => {
                    e.preventDefault()
                    dispatch(logout())
                }}>
                    <p>LOGOUT</p>
                </Button>
            </Menu.Item>
        </Menu>
    );

    return(
        <TopbarStyle>
            <img src={logo} className={"logo"} onClick={goHome}/>
            {
                localStorage.getItem("old_token") && localStorage.getItem("roles").includes(Roles.ADMIN) &&
                <div className={"middleDiv"}>
                    <p className={"label-current-tenant"}>Stai operando come Amministratore del Tenant {localStorage.getItem("tenantName")}</p>
                    <ButtonTenantStyle>
                        <Button className={"exitFromTenantBtn"} onClick={handleGoBackClick}>
                            ESCI DAL TENANT
                        </Button>
                    </ButtonTenantStyle>
                </div>

            }
            <Dropdown overlay={menu} arrow={{ pointAtCenter: true }} placement="bottomCenter">
                <img src={logoutIcon} className={"logoutIcon"}/>
            </Dropdown>
        </TopbarStyle>
    )
}
