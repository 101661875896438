import styled from "styled-components";

export const ButtonTenantStyle = styled.div`
    .ant-btn {
        height: 40px;
        border-radius: 20px;
        border-color: rgb(51, 85, 145);
        border-width: 2px;
        background-color: black;
        color: white;
        font-family: GothamRoundedBold;
    }
`