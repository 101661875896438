import styled from "styled-components";

export const HomeStyledDivMobile = styled.div`   
    height: 100%;
    width: 100%;
    
    .add-artwork-card{
        margin: 20px;
        height: 170px;
        width: 90vw;
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
    
    .add-artwork-label{
        font-size: 16px;
        color: white;
    }
    
    .last-artwork-card{
        margin: 20px;
        height: 30%;
        width: 90vw;
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
    }
    
    .last-artwork-label{
        color: white;
        font-size: 16px;
        font-family: GothamBlack,sans-serif;
        margin-bottom: 5px;
    }
    
    .last-artwork-label-title{
        color: white;
        font-size: 20px;
        font-family: GothamBlack,sans-serif;
    }
    
    .last-artwork-label-description{
      color: white;
      font-size: 16px;
      font-family: Gotham, sans-serif;
    }
    
    .browse-folder-label{
        text-decoration: underline;
        font-size: 14px;
        font-family: GothamBook,sans-serif;
        color: white;
        cursor: pointer;
    }
    
    .folder-label{
        font-size: 14px;
        font-family: GothamBook,sans-serif;
        color: white;
    }
    
    .line-chart-container{
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        padding: 20px;
        height:450px;
    }
    
    .bar-chart-container{
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        padding: 20px 20px 20px 10px;
        height: 450px;
    }
    
    .line-chart-label{
        color: white;
        font-size: 20px;
        font-family: GothamBlack,sans-serif;
    }
    
`
