const actions = {

    INITIALIZE_FOLDER_STATE: "INITIALIZE_FOLDER_STATE",


    GET_ALL_FOLDER: "GET_ALL_FOLDER",
    GET_ALL_FOLDER_SUCCESS: "GET_ALL_FOLDER_SUCCESS",
    GET_ALL_FOLDER_ERROR: "GET_ALL_FOLDER_ERROR",

    UPDATE_FOLDER: "UPDATE_FOLDER",
    UPDATE_FOLDER_SUCCESS: "UPDATE_FOLDER_SUCCESS",
    UPDATE_FOLDER_ERROR: "UPDATE_FOLDER_ERROR",

    DELETE_FOLDER: "DELETE_FOLDER",
    DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
    DELETE_FOLDER_ERROR: "DELETE_FOLDER_ERROR",

    getAllFolder: () => ({
        type: actions.GET_ALL_FOLDER,
    }),

    initializeFolderState: () => ({
        type: actions.INITIALIZE_FOLDER_STATE
    }),

    editFolder: (folderId, updateData) => ({
        type: actions.UPDATE_FOLDER,
        folderId: folderId,
        updateData: updateData
    }),

    deleteFolder: (folderId) => ({
        type: actions.DELETE_FOLDER,
        folderId: folderId,
    })

}

export default actions