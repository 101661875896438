import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import artWorkActions from "../../../redux/artWork/artWorkActions";
import authActions from "../../../redux/auth/authActions";
import {InputStyle} from "../../../style/InputStyle";
import {Button, Col, Input, List, Row} from "antd";
import SearchIcon from "../../../svg/search.svg";
import {ButtonStyle} from "../../../style/ButtonStyle";
import ArtworkCard from "../../../Pages/artWork/artWorkCard";
import {ArtWorksStyleDivMobile} from "../../../style/mobileStyle/ArtWorksStyleDivMobile";
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";

const {initializeArtWorkState, getAllArtWork, deleteArtWork,getAllArtWorkByFilters} = artWorkActions;
const {setRoute} = authActions;

function ArtWorkMobile(){

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {id} = useParams()

    const artWorkList = useSelector(state => state.ArtWork.artWorks)
    const artWorksImages = useSelector(state => state.ArtWork.artWorksImages)
    const updateArtWorkList = useSelector(state => state.ArtWork.updateList)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if(!artWorkList || updateArtWorkList) {
            setLoading(true)
            if(id){
                dispatch(getAllArtWorkByFilters(id))
            }else{
                dispatch(getAllArtWork())
            }
        }
        return () => {
            dispatch(initializeArtWorkState())
        }
    }, [])

    useEffect(() => {
        if(id){
            dispatch(getAllArtWorkByFilters(id))
        }else{
            dispatch(getAllArtWork())
        }

    },[id])


    React.useEffect(() => {
        if(updateArtWorkList){
            dispatch(getAllArtWork())
        }
    }, [updateArtWorkList])

    const handleSearch = (e) => {};

    const handleAdd = () => {
        dispatch(setRoute(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK))
        navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK)
    };

    const handleEdit = (artWorkId) => {
        navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK + "/" + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    return(
        <ArtWorksStyleDivMobile>
            <div style={{paddingBottom:20}}>
                <InputStyle size={"36px"}>
                    <Input placeholder={"Search"} onChange={handleSearch} prefix={<img src={SearchIcon}/>}/>
                </InputStyle>
            </div>
            <div style={{paddingBottom:20}}>
                <ButtonStyle>
                    <Button block onClick={handleAdd}>+ AGGIUNGI</Button>
                </ButtonStyle>
            </div>
            <div className={"last-artwork-card"}>
                <Row style={{display:"flex", justifyContent:"center"}}>
                    <Col>
                        <List
                            size="default"
                            dataSource={artWorkList ?? []}
                            renderItem={item => {
                                return (<ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete} cardHeight={"250px"} cardWidth={"250px"} setMarginBottom={"40px"} setMarginTop={"40px"} clean={false}/>)
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </ArtWorksStyleDivMobile>
    )
}

export default ArtWorkMobile
