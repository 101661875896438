import React, {useEffect, useState} from "react";
import {Button, Collapse, Input, Popconfirm, Switch} from 'antd';
import {CollapseStyleMobile} from "../../../style/mobileStyle/CollapseStyle";
import { CaretRightOutlined } from "@ant-design/icons";
import actions from "../../../redux/tenant/tenantActions";
import authActions from "../../../redux/auth/authActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getRouteLandingPage} from "../../../helpers/utility";
import EditIcon from "../../../svg/edit.svg";
import DeleteIcon from "../../../svg/trash.svg";
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";
import SearchIcon from "../../../svg/search.svg";
import {InputStyle} from "../../../style/InputStyle";
import {ButtonStyle} from "../../../style/ButtonStyle";
const { Panel } = Collapse;

const {initializeState, getAll, enable, deleteTenant} = actions;
const {logAsTenant} = authActions;
const {setRoute} = authActions;

function TenantMobile(){

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [tenantsFiltered, setTenantsFiltered] = useState(null)
    const token = useSelector(state => state.authReducer.idToken)

    const handleAdd = () => {
        dispatch(setRoute("/addTenant"))
        navigate(PRIVATE_MOBILE_ROUTE.ADD_TENANT);
    }

    const handleDelete = (id) => {
        dispatch(deleteTenant(id))
    }

    const handleEdit = (id) => {
        navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK + id)
    }

    const hadleLogAsTenant = (tenantId , element) => {
        localStorage.setItem("tenantName", element.tenantName)
        dispatch(logAsTenant(tenantId))
    }

    const updateList = useSelector(state => state.Tenant.updateList)
    const tenants = useSelector(state => state.Tenant.tenants)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if (!tenants || updateList) {
            dispatch(getAll())
        }
        return () => {
            dispatch(initializeState())
        }

    }, [])

    React.useEffect(() => {
        if(updateList) {
            dispatch(getAll())
        }
    }, [updateList])

    useEffect(() => {
        if(token){
            navigate(getRouteLandingPage())
        }
    },[token])

    function onChange(id) {
        dispatch(enable(id))
    }

    function  getList() {
        if(tenants){
            return tenants
        }if(tenantsFiltered){
            return tenantsFiltered
        }else{
            return null
        }
    }

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            setTenantsFiltered(tenants.filter((tenant) => tenant.tenantName.toLowerCase().includes(e.target.value.toLowerCase()) || tenant.administratorName.toLowerCase().includes(e.target.value.toLowerCase())))
        }
        if (e.target.value.length < 3) {
            setTenantsFiltered(null)
        }
    }

    return(
        <CollapseStyleMobile>
            <div style={{paddingBottom:20}}>
                <InputStyle size={"36px"}>
                    <Input placeholder={"Search"} onChange={handleSearch} prefix={<img src={SearchIcon}/>}/>
                </InputStyle>
            </div>
            <div style={{paddingBottom:20}}>
                <ButtonStyle>
                    <Button block onClick={handleAdd}>+ AGGIUNGI</Button>
                </ButtonStyle>
            </div>
            <Collapse
                defaultActiveKey={['1']}
                bordered={false}
                expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}>
                {getList() ?  getList().map((element, index)=>(
                    <Panel header={element.tenantName} key={index}>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Nome</p>
                            <p>{element.administratorName}</p>
                        </div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Stato</p>
                            <p><Switch checked={element.enabled} onChange={() => onChange(element.tenantId)}/></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Azioni</p>
                            <p><a style={{textDecoration:"underline", cursor:"pointer"}} onClick={() => hadleLogAsTenant(element.tenantId, element)}>Accedi al tenant</a></p>
                            <p>
                                <img style={{width:20}} src={EditIcon} onClick={() => handleEdit(element.tenantId)}/>
                            </p>
                            <p>
                                <Popconfirm color={"white"}  title={"Sei sicuro di voler eliminare?"} onConfirm={() => handleDelete(element.tenantId)}>
                                    <img style={{width:20}} src={DeleteIcon}/>
                                </Popconfirm>
                            </p>
                        </div>
                    </Panel>
                    )) : null}
            </Collapse>
        </CollapseStyleMobile>
    )
}

export default TenantMobile
