import React, {useState} from "react";
import {InputStyle} from "../../../style/InputStyle";
import {Button, Collapse, Input, Popconfirm, Radio, Switch, Typography} from "antd";
import SearchIcon from "../../../svg/search.svg";
import {ButtonStyle} from "../../../style/ButtonStyle";
import {CaretRightOutlined} from "@ant-design/icons";
import EditIcon from "../../../svg/edit.svg";
import DeleteIcon from "../../../svg/trash.svg";
import {CollapseStyleMobile} from "../../../style/mobileStyle/CollapseStyle";
import {getUserId} from "../../../helpers/utility";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import adminActions from "../../../redux/admin/adminActions";
import contentCreatorActions from "../../../redux/contentCreator/contentCreatorActions";
import authActions from "../../../redux/auth/authActions";
const { Panel } = Collapse;

const {initializeAdminState, getAllAdmins, enableAdmin, deleteAdmin} = adminActions;
const {initializeContentCreatorState, getAllContentCreator, enableContentCreator, deleteContentCreator} = contentCreatorActions;
const {setRoute} = authActions;

function UsersMobile(){

    const dispatch = useDispatch()

    const handleEdit = (row) => {
        let id = row.id
        if (sourceRadioButton === 1) {
            navigate("/addOrModify/admin/" + id)
        }
        if (sourceRadioButton === 2) {
            navigate("/addOrModify/user/" + id)
        }
    }

    const handleDelete = (id) => {
        if (id !== getUserId()) {
            if (sourceRadioButton === 1) {
                dispatch(deleteAdmin(id))
            }
            if (sourceRadioButton === 2) {
                dispatch(deleteContentCreator(id))
            }
        }

    }

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            if (sourceRadioButton === 1) {
                setSourceDataFiltered(admins.filter((admin) => admin.username.toLowerCase().includes(e.target.value.toLowerCase()) || admin.firstNameLastName.toLowerCase().includes(e.target.value.toLowerCase())))
            }
            if (sourceRadioButton === 2) {
                setSourceDataFiltered(contentCreators.filter((creator) => creator.username.toLowerCase().includes(e.target.value.toLowerCase()) || creator.firstNameLastName.toLowerCase().includes(e.target.value.toLowerCase())))
            }
        } else {
            setSourceDataFiltered(null)
        }
    }

    const handleAdd = () => {
        if (sourceRadioButton === 1) {
            dispatch(setRoute("/mobile/addOrModify/admin"))
            navigate("/mobile/addOrModify/admin")
        }
        if (sourceRadioButton === 2) {
            dispatch(setRoute("/mobile/addOrModify/user"))
            navigate("/mobile/addOrModify/user")
        }
    }

    const navigate = useNavigate()

    const updateAdminList = useSelector(state => state.Admin.updateList)
    const admins = useSelector(state => state.Admin.admins)

    const updateContentCreatorList = useSelector(state => state.ContentCreator.updateList)
    const contentCreators = useSelector(state => state.ContentCreator.contentCreators)

    const [sourceRadioButton, setSourceRadioButton] = React.useState(1)

    const [sourceData, setSourceData] = React.useState([])
    const [sourceDataFiltered, setSourceDataFiltered] = useState(null)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if (sourceRadioButton === 1 || updateAdminList) {
            dispatch(getAllAdmins())
        }
        if (sourceRadioButton === 2 || updateContentCreatorList) {
            dispatch(getAllContentCreator())
        }
        return () => {
            dispatch(initializeAdminState())
            dispatch(initializeContentCreatorState())
        }
    }, [])

    React.useEffect(() => {
        if (updateAdminList === true) {
            dispatch(getAllAdmins())
        }
    }, [updateAdminList])

    React.useEffect(() => {
        if (updateContentCreatorList === true) {
            dispatch(getAllContentCreator())
        }
    }, [updateContentCreatorList])

    React.useEffect(() => {
        if (sourceRadioButton === 1) {
            setSourceData(admins)
        }
        if (sourceRadioButton === 2) {
            setSourceData(contentCreators)
        }
    }, [admins, contentCreators])

    function onChange(user) {
        if (sourceRadioButton === 1) dispatch(enableAdmin(user.id))
        if (sourceRadioButton === 2) dispatch(enableContentCreator(user.id))
    }

    function updateTable(source) {
        if (source.target.value === 1) {
            dispatch(getAllAdmins())
        } else if (source.target.value === 2) {
            dispatch(getAllContentCreator())
        }
        setSourceRadioButton(source.target.value)
    }

    function getList() {
        if(sourceDataFiltered && sourceDataFiltered.length > 0){
            return sourceDataFiltered
        }else if(sourceData && sourceData.length > 0){
            return sourceData
        }else{
            return null
        }
    }

    return(
        <CollapseStyleMobile>
            <div style={{paddingBottom:20}}>
                <InputStyle size={"36px"}>
                    <Input placeholder={"Search"} onChange={handleSearch} prefix={<img src={SearchIcon}/>}/>
                </InputStyle>
            </div>
            <div style={{paddingBottom:20}}>
                <div style={{padding:25}}>
                    <Radio.Group onChange={updateTable} defaultValue={sourceRadioButton}>
                        <Radio value={1} style={{color:"white"}}>Mostra Amministratori</Radio>
                        <Radio value={2} style={{color:"white"}}>Mostra Content Creator</Radio>
                    </Radio.Group>
                </div>
                <ButtonStyle>
                    <Button block onClick={handleAdd}>{sourceRadioButton === 1 ? "+ AGGIUNGI ADMIN" : "+ AGGIUNGI CONTENT CREATOR"}</Button>
                </ButtonStyle>
            </div>
            <Collapse
                defaultActiveKey={['1']}
                bordered={false}
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}>
                {getList() ? getList().map((element, index)=>(
                    <Panel header={element.firstNameLastName} key={index}>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Username</p>
                            <p>{element.username}</p>
                        </div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Mail</p>
                            <p>{element.email}</p>
                        </div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Stato</p>
                            <p><Switch checked={element.enabled} onChange={() => onChange(element)}/></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <p style={{marginLeft:20}}>Azioni</p>
                            <p>
                                <img style={{width:20}} src={EditIcon} onClick={() => handleEdit(element)}/>
                            </p>
                            <p>
                                <Popconfirm color={"white"}  title={"Sei sicuro di voler eliminare?"} onConfirm={() => handleDelete(element)}>
                                    <img style={{width:20}} src={DeleteIcon}/>
                                </Popconfirm>
                            </p>
                        </div>
                    </Panel>
                )) : null}
            </Collapse>
        </CollapseStyleMobile>
    )
}

export default UsersMobile
