import React, {useState} from "react";
import {Col, List, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../redux/auth/authActions"
import homeActions from "../../redux/home/homeActions"
import artWorkActions from "../../redux/artWork/artWorkActions";
import {useNavigate} from "react-router-dom";
import artWorkIcon from "../../svg/artwork.svg";

import {HomeStyledDiv} from "./homeStyledDiv";
import StatsUser from "./ApexCharts/StatsUser";
import Views from "./ApexCharts/Views";
import ArtworkCard from "../artWork/artWorkCard";


const {setRoute} = authActions;
const {initializeHomeState, getHomeAdmin} = homeActions
const {deleteArtWork} = artWorkActions;

const theme = {
    backgroundImage: "https://static2-viaggi.corriereobjects.it/wp-content/uploads/2019/10/iStock-905351688.jpg"
}

export default function HomePageAdmin() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const lastArtWorks = useSelector(state => state.Home.lastArtWorks)
    const userStatistics = useSelector(state => state.Home.uiUserStatistics)
    const visualizationStatistics = useSelector(state => state.Home.uiVisualizationStatistics)
    const updateList = useSelector(state => state.Home.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        dispatch(getHomeAdmin())
        return ()=>{
            dispatch(initializeHomeState())
        }
    }, [])

    React.useEffect(()=>{
        if(updateList) dispatch(getHomeAdmin())
    },[updateList])

    const handleEdit = (artWorkId) => {
        navigate("/addArtWork/" + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    const setSize = () =>{
        let size = window.innerWidth
        if(size > 1600){
            return "260px"
        }else{
            return "200px"
        }
    }

    return (
        <HomeStyledDiv>
            <Row gutter={[60,60]}>
                <Col span={10}>
                    <div className={"add-artwork-card"} onClick={() => navigate("/addArtwork")}>
                        <img src={artWorkIcon}/>
                        <p className={"add-artwork-label"}>AGGIUNGI ARTWORK</p>
                    </div>
                </Col>
                <Col span={14}>
                    <div className={"last-artwork-card"}>
                        <p className={"last-artwork-label"}>Ultimi Artwork</p>
                        <List
                            itemLayout="horizontal"
                            size="default"
                            grid={{
                                gutter: [50,50],
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 2,
                                xs: 2,
                            }}
                            dataSource={lastArtWorks ?? []}
                            renderItem={item => {
                                return (<List.Item key={item.id}>
                                    <ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete} cardHeight={setSize} cardWidth={setSize}/>
                                </List.Item>)
                            }}
                        />
                        <p className={"browse-folder-label"} onClick={() => navigate("/artWork")}>Sfoglia Artwork</p>
                    </div>
                </Col>
                <Col span={14}>
                    <div className={"line-chart-container"}>
                        <p className={"line-chart-label"}>Statistiche Utente</p>
                        <StatsUser options={userStatistics}/>
                    </div>
                </Col>
                <Col span={10}>
                    <div className={"bar-chart-container"}>
                        <p className={"line-chart-label"} style={{paddingLeft:10}}>Visualizzazioni</p>
                        <Views options={visualizationStatistics}/>
                    </div>
                </Col>
            </Row>
        </HomeStyledDiv>
        /*<HomeStyledDiv>
            <Col lg={22} sm={22} xs={22}>
                <Row gutter={[60, 30]}>
                    <Col lg={7} sm={7} xs={7}>
                        <Card>
                            <div className={"centered fullHeight"} onClick={() => {alert("Stai aggiungendo un nuovo artwork")}}>
                                <img src={artWork}/>
                                <h2 style={{marginTop: "10%"}}>AGGIUNGI UN ARTWORK</h2>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={14} sm={14} xs={14}>
                        <Card className={"centered"}>
                            <h2>Ultimi Artwork</h2>
                            <Row gutter={[60,20]}>
                                <Col lg={12} sm={24} xs={24}>
                                    <StyledCard backgroundImage={"https://static2-viaggi.corriereobjects.it/wp-content/uploads/2019/10/iStock-905351688.jpg"}>
                                        <Card className={"whiteCard customizedBackground"}>
                                            <div>
                                                <Row style={{textAlign: "right"}}>
                                                    VIEWS
                                                </Row>
                                                <Row style={{textAlign: "right"}}>
                                                    124
                                                </Row>
                                                <Row>
                                                    Titolo
                                                </Row>
                                                <Row gutter={0} style={{flexDirection: "initial"}}>
                                                    <Col lg={12} sm={12} xs={12}>
                                                        gg/mm/aaa
                                                    </Col>
                                                    <Col lg={8} sm={8} xs={8}>
                                                        bottoni
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    </StyledCard>
                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <StyledCard backgroundImage={"https://www.symbola.net/wp-content/uploads/2020/04/mappe-satellite.jpg"}>
                                        <Card className={"whiteCard customizedBackground"}>
                                        </Card>
                                    </StyledCard>
                                </Col>
                            </Row>
                            <a href="#" onClick={() => {alert("Stai sfogliando tutti gli artwork")}}><p style={{textAlign: "right"}}>Sfoglia Artwork</p></a>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[60, 30]}>
                    <Col lg={13} sm={13} xs={13}>
                        <Card>
                            <Row>
                                <Col lg={12} sm={24} xs={24}>
                                    <h3>Statistiche Utenti</h3>
                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <h3 style={{textAlign: "right"}}>Questa settimana</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={8} sm={8} xs={8}>
                        <Card>
                            <Row>
                                <Col lg={12} sm={24} xs={24}>
                                    <h3>Visualizzazioni</h3>
                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <h3 style={{textAlign: "right"}}>Questa settimana</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </HomeStyledDiv>*/
    )
}
