import styled from "styled-components";

export const AddArtWorkStyleMobile = styled.div`
    background: rgba(255, 255, 255, 0.05) !important;
    border-radius: 26px !important;
    padding: 20px !important;
    
    p {
        color: white;
        font-size: 25px;
    }
    
    .form-content {
        width: 100% !important;
    }
    
    .ant-row {
        width: 100% !important;
    }
    
    .ant-row .ant-checkbox-wrapper {
        color: white !important
    }
    
    .dashedBackground {
        background-color: transparent !important;
        border-radius: 10px !important;
        border-style: dashed !important;
        border-color: white !important;;
        height: 250px !important;
    }
    
    .dashedBackground .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    
    .ant-upload {
        padding: unset !important;
    }
    
    .ant-upload-drag { 
        background-color: transparent !important;
        border: none !important;
    }
    
    .dashedBackground .ant-card-body p {
        margin: unset;
    }
    
    .centeredRow {
        display: flex;
        justify-content: center;
    }
    
    .alignEndColumn {
        display: flex;
        justify-content: end;
    }
    
    .alignStartColumn {
        display: flex;
        justify-content: start;
    }
    
    .ant-upload-list-item-info {
        color: white !important;
    }
    
    .ant-upload-text-icon {
        filter: invert(100%) sepia(0%) saturate(7470%) hue-rotate(85deg) brightness(95%) contrast(108%) !important;
    }
    
    .ant-checkbox-checked .ant-checkbox-inner{
      background-color: #4EE23B;
      border-color: #4EE23B;
    }
    
    .ant-select-dropdown{
      border-radius: 26px;
    }
    
    .ant-select-multiple .ant-select-selection-item{
      background-color: #335591;
      height: 31px;
      border-radius: 15.5px;
    }
    
    .ant-select-multiple .ant-select-selection-item-content{
      color: white;
      font-size: 16px;
      line-height: 28px;
    }
    
    .ant-select-multiple .ant-select-selection-item-remove{
      color: white;
      line-height: 28px;
    }
    
    .ant-select-multiple .ant-select-selection-item-remove{
      font-size: 16px;
    }
    
    .ant-tabs-tab.ant-tabs-tab-active-tabs{
      color: #1890ff !important;
    }
    
    .marginInput{
      margin-top: 15%;
    }
    
`
