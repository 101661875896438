import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row, Switch} from "antd";
import Input from "../../component/Input";
import {AddTenantStyle} from "../../style/AddTenantStyle";
import {ButtonStyle} from "../../style/ButtonStyle";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/tenant/tenantActions"
import {useNavigate, useParams} from "react-router-dom";
import authActions from "../../redux/auth/authActions";
import {getRouteLandingPage} from "../../helpers/utility";

const {addNewTenant, getTenantById, initializeState, updateTenant} = actions
const {setRoute} = authActions

export default function AddTenant() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tenant = useSelector(state => state.Tenant.tenant)

    const {id} = useParams()

    const [tenantName, setTenantName] = useState(null)
    const [adminName, setAdminName] = useState("")
    const [mail, setMail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [hasCatalogEnabled, setHasCatalogEnabled] = useState(false)
    const backToList = useSelector(state => state.Tenant.backToList)

    const handleAdd = (fieldsValue) => {
        let data = {
            tenantName: fieldsValue['tenantName'],
            administratorFirstName: fieldsValue['adminName'],
            administratorLastName: "prova",
            administratorEmail: fieldsValue['mail'],
            username: fieldsValue['username'],
            password: fieldsValue['password'],
            enabled: true,
            hasCatalogEnabled: hasCatalogEnabled
        }
        if (id) {
            dispatch(updateTenant(data, id))
        } else {
            dispatch(addNewTenant(data))
        }
    }

    const handleCancel = () => {
        navigate(getRouteLandingPage())
    }

    React.useEffect(() => {
        if (backToList) navigate(getRouteLandingPage())
    }, [backToList])


    const clearState = () => {
        setTenantName("")
        setAdminName("")
        setUsername("")
        setPassword("")
        setHasCatalogEnabled(false)
    }

    useEffect(() => {
        clearState()
    }, [id])

    useEffect(() => {
        if (id) {
            dispatch(getTenantById(id))
        }
        return () => {
            dispatch(initializeState())
        }
    }, [])


    useEffect(() => {
        if (tenant) {
            setTenantName(tenant.tenantName)
            setAdminName(tenant.mainAdminFirstNameLastName)
            setMail(tenant.mail)
            setUsername(tenant.username)
            setPassword(null)
            setHasCatalogEnabled(tenant.hasCatalogEnabled)
        }
    }, [tenant])

    useEffect(()=>{
    },[hasCatalogEnabled])


    return (
        <AddTenantStyle>
            <Row>
                <Col span={16} offset={4}>
                    <div className={"form-header"}>
                        <span>{id ? "Modifica Tenant" : "Nuovo Tenant"}</span>
                    </div>
                    <div className={"form-content"}>
                        <Form
                            name={"register"}
                            onFinish={handleAdd}
                            key={tenantName}
                        >
                            <Row gutter={[30, 60]} className={"form-input"}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"tenantName"}
                                        rules={[{required: true, message: 'Inserire il nome del Tenant'}]}
                                        initialValue={tenantName}
                                    >
                                        <Input type={"text"} placeholder={"Nome Tenant"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"adminName"}
                                        rules={[{required: id == null, message: "Inserire il nome dell'Admin"}]}
                                        initialValue={adminName}
                                    >
                                        <Input type={"text"} placeholder={"Nome Amministratore"} disabled={id != null}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"mail"}
                                        rules={[{required: id == null, message: "Inserire la mail dell'Admin"}]}
                                        initialValue={mail}
                                    >
                                        <Input type={"text"} placeholder={"Mail"} disabled={id != null}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"username"}
                                        rules={[{required: id == null, message: "Inserire l'username dell'Admin"}]}
                                        initialValue={username}
                                    >
                                        <Input type={"text"} placeholder={"Username"} disabled={id != null}/>
                                    </Form.Item>
                                </Col>
                                {/*<Col span={6}/>*/}
                                <Col span={12}>
                                    <Form.Item
                                        name={"password"}
                                        rules={[{required: id == null, message: "Inserire la password dell'Admin"}]}
                                        initialValue={password}
                                    >
                                        <Input type={"password"} placeholder={"Password"} disabled={id != null}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                                    <p style={{color:"white", fontSize: "25px", marginRight: "20px"}}>Abilita Catalogo</p>
                                    <Form.Item
                                        name={"hasCatalogEnabled"}
                                        valuePropName={"checked"}
                                        initialValue={hasCatalogEnabled}
                                    >
                                        <Switch checked={hasCatalogEnabled} onChange={setHasCatalogEnabled}/>
                                    </Form.Item>
                                </Col>
                                {/*<Col span={6}/>*/}
                                {/*<Col span={12}  style={{display: "flex", justifyContent:"center", alignItems: "center"}}>*/}
                                {/*</Col>*/}
                            </Row>

                            <Row gutter={[30]} className={"action-container"} style={{margin:50}}>
                                <Col span={12}>
                                    <Form.Item>
                                        <ButtonStyle color={"rgb(157,159,163)"}><Button block
                                                                                        onClick={handleCancel}>CANCELLA</Button></ButtonStyle>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <ButtonStyle><Button block
                                                             htmlType="submit">{id ? "MODIFICA" : "AGGIUNGI"}</Button></ButtonStyle>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Col>
            </Row>
        </AddTenantStyle>
    )
}
