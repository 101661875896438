import {combineReducers} from "redux";
import authReducer from "./auth/authReducer";
import Tenant from "./tenant/tenantReducer";
import Admin from "./admin/adminReducer"
import ContentCreator from "./contentCreator/contentCreatorReducer";
import ArtWork from "./artWork/artWorkReducer";
import Folder from "./folder/folderReducer";
import Home from "./home/homeReducer";
import Catalog from "./catalog/catalogReducer"

export default combineReducers({
    authReducer,
    Tenant,
    Admin,
    ContentCreator,
    ArtWork,
    Folder,
    Home,
    Catalog
})
