import React, {useEffect, useState} from "react";
import {Button, Col, Input, List, Row} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {ListStyle} from "../../style/ListStyle";
import {InputStyle} from "../../style/InputStyle";
import SearchIcon from "../../svg/search.svg";
import {ButtonStyle} from "../../style/ButtonStyle";
import artWorkActions from "../../redux/artWork/artWorkActions";
import authActions from "../../redux/auth/authActions";
import {useNavigate, useParams} from "react-router-dom";
import ArtworkCard from "./artWorkCard";

const {initializeArtWorkState, getAllArtWork, deleteArtWork,getAllArtWorkByFilters, downloadAllMarker} = artWorkActions;
const {setRoute} = authActions;

export default function ArtWorks() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {id} = useParams()

    const artWorkList = useSelector(state => state.ArtWork.artWorks)
    const artWorksImages = useSelector(state => state.ArtWork.artWorksImages)
    const updateArtWorkList = useSelector(state => state.ArtWork.updateList)
    const [loading, setLoading] = React.useState(false)
    const [sourceDataFiltered, setSourceDataFiltered] = useState(null)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if(!artWorkList || updateArtWorkList) {
            setLoading(true)
            if(id){
                dispatch(getAllArtWorkByFilters(id))
            }else{
                dispatch(getAllArtWork())
            }
        }
        return () => {
            dispatch(initializeArtWorkState())
        }
    }, [])

    /*useEffect(() => {
        if(id){
            dispatch(getAllArtWorkByFilters(id))
        }else{
            dispatch(getAllArtWork())
        }

    },[id])*/



    React.useEffect(() => {
        if(updateArtWorkList){
            dispatch(getAllArtWork())
        }
    }, [updateArtWorkList])

    const onLoadMore = () => {};

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            setSourceDataFiltered(artWorkList.filter((artWork) => artWork.title.toLowerCase().includes(e.target.value.toLowerCase())))
        }else{
            setSourceDataFiltered(null)
        }
    };

    const handleAdd = () => {
        dispatch(setRoute("/addArtWork"))
        navigate("/addArtWork")
    };

    const handleEdit = (artWorkId) => {
        navigate("/addArtWork/" + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    return (
        <ListStyle>
            <div>
                <Row gutter={10} style={{paddingBottom: "10px"}}>
                    <Col span={11}>
                        <p>ARTWORKS</p>
                    </Col>
                    <Col span={5}>
                        <InputStyle size={"36px"}><Input placeholder={"Search"} onChange={handleSearch}
                                                         prefix={<img src={SearchIcon}/>}/></InputStyle>
                    </Col>
                    <Col span={7}>
                        <ButtonStyle><Button block
                                             onClick={handleAdd}>AGGIUNGI</Button></ButtonStyle>
                    </Col>
                </Row>
                <List
                    itemLayout="horizontal"
                    size="default"
                    grid={{
                        gutter: [50,50],
                        xxl: 3,
                        xl: 2,
                        lg: 1,
                        md: 1,
                        xs: 1,
                    }}
                    pagination={{
                        onChange: page => {
                        },
                        pageSize: 6,
                    }}
                    dataSource={sourceDataFiltered ? sourceDataFiltered : artWorkList ?? []}
                    renderItem={item => {
                        return (<List.Item key={item.artWorkId}>
                            <ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete}/>
                        </List.Item>)
                    }}
                />
            </div>
        </ListStyle>
    )
}
