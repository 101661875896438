import * as React from "react";
import ReactApexChart from "react-apexcharts";

function StatsUser(props){

    const {android_y, ios_y} = props.options ?? []

    const base_options = {
        legend: {
            show: true,
                showForSingleSeries: true,
                fontSize: "16px",
                horizontalAlign: 'left',
                fontFamily: "GothamBook",
                labels: {
                colors: "#FFFFFF",
                    useSeriesColors: false,
            },
        },
        chart: {
            height: 350,
            type: "line",
                id: "basic-bar",
                toolbar:{
                show: false

            }
        },
        xaxis: {
            categories: ["L","M","M","G","V","S","D"],
                labels: {
                style:{
                    colors: "#FFFFFF",
                        fontSize: "16px",
                        fontFamily: "GothamBook"
                }
            },
            axisBorder: {
                show: false
            },
            tooltip:{
                enabled: false
            }
        },
        yaxis:{
            labels: {
                style:{
                    colors: "#FFFFFF",
                        fontSize: "16px",
                        fontFamily: "GothamBook"
                }
            }
        },
        stroke: {
            show: true,
                curve: 'smooth'
        },
    };

    const base_series = [
        {
            name: "IOS",
            data: android_y ?? []
        },
        {
            name: "ANDROID",
            data: ios_y ?? []
        },
    ]

    return(
        <div >
            <ReactApexChart
                type={"line"} options={base_options} series={base_series} height={310}
            />
        </div>
    )
}

export default StatsUser
