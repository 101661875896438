import actions from "./tenantActions";

const initState = {
    tenants: null,
    tenant: null,
    updateList: false,
    backToList: false,
}

export default function tenantReducer(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_STATE:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_TENANT_SUCCESS:
            return{
                ...state,
                tenants: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            }
        case actions.ENABLE_TENANT:
            return {
                ...state,
                updateList: false
            }
        case actions.ENABLE_TENANT_SUCCESS:
            return {
                ...state,
                updateList: true
            };
        case actions.SAVE_NEW_TENANT_SUCCESS:
            return{
                ...state,
                updateList: true,
                backToList: true
            }
        case actions.UPDATE_TENANT:
            return{
                ...state,
                updateList: false
            }
        case actions.UPDATE_TENANT_SUCCESS:
            return{
                ...state,
                updateList: false,
                backToList: true
            }
        case actions.DELETE_TENANT:
            return{
                ...state,
                updateList: false
            }
        case actions.DELETE_TENANT_SUCCESS:
            return {
                ...state,
                updateList: true
            }
        case actions.GET_TENANT_BY_ID:
            return {
                ...state,
                tenant: null
            }
        case actions.GET_TENANT_BY_ID_SUCCESS:
            return {
                ...state,
                tenant: action.payload
            }
        case actions.RESET_STATE:
            return{
                initState
            }
        case actions.SET_TENANTS:
            return {
                ...state,
                tenants: action.tenants
            }
        default:
            return state;
    }

}