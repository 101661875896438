import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./artWorkActions";
import authActions from "../auth/authActions"
import createNotification from "../../helpers/Notification";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_ARTWORKS, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAllArtWorks, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ARTWORKS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ARTWORKS_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllByFolderId() {

    yield takeEvery(actions.GET_ALL_ARTWORKS_BY_FILTERS, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findArtWorkByFolderId + "/?folderId=" + data.folderId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ARTWORKS_BY_FILTERS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ARTWORKS_BY_FILTERS_ERROR,
                payload: error
            });
        }
    })
}

export function* getArtWorkById() {

    yield takeEvery(actions.GET_ARTWORK_BY_ID, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findArtWorkById + "/" + data.artWorkId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ARTWORK_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ARTWORK_BY_ID_ERROR,
                payload: error
            });
        }
    })
}

export function* saveNewArtWork() {

    yield takeLatest(actions.SAVE_NEW_ARTWORK, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveNewArtWork, data.newArtWork, true, true)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_ARTWORK_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_ARTWORK_ERROR,
                payload: error
            });
        }
    })
}

export function* updateArtWork() {

    yield takeEvery(actions.UPDATE_ARTWORK, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateArtWork + "/" + data.artWorkId, data.updatedData, true, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.UPDATE_ARTWORK_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_ARTWORK_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteNewArtWork() {

    yield takeEvery(actions.DELETE_ARTWORK, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteArtWork + "/" + data.artWorkId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_ARTWORK_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_ARTWORK_ERROR,
                payload: error
            });
        }
    })
}

export function* downloadMarkerFile() {

    yield takeEvery(actions.DOWNLOAD_MARKER_FILE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.downloadFile, data.request, true, null, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DOWNLOAD_MARKER_FILE_SUCCESS,
                payload: response,
                artworkId: data.artworkId
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DOWNLOAD_MARKER_FILE_ERROR,
                payload: error
            });
        }
    })
}

export function* downloadMediaFile() {

    yield takeEvery(actions.DOWNLOAD_MEDIA_FILE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.downloadFile, data.request, true, null, null, true)
        if (response) {

            let blob = yield response.blob()
            let fileName = response.headers.get('Content-disposition').split(";")[1].split("\"")[1]
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DOWNLOAD_MEDIA_FILE_SUCCESS,
                blob: blob,
                fileName: fileName
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DOWNLOAD_MEDIA_FILE_ERROR,
                payload: error
            });
        }
    })
}

export function* getArtWorkQuality() {
    yield takeEvery(actions.CHECK_ARTWORK_QUALITY, function* (data) {
        yield put({type: authActions.LOADING_TRUE})
        const {response, error} = yield call(SuperFetch.post, endpoints.checkMarkerQuality, data.payload, true, true)
        yield put({type: authActions.LOADING_FALSE})

        if(response){
            yield put({
                type: actions.CHECK_ARTWORK_QUALITY_SUCCESS,
                payload: response
            })
            if(response.value < 50){
                createNotification("warning", "Pessima riconoscibilità dell'immagine", "La stima della riconoscibilità dell'immagine è pari a: " + response.value + ". La scansione dell'artwork potrebbe non funzionare. Un valore di riconoscibilità ottimale deve essere compreso tra 75 e 100")
            }else if(response.value >= 50 && response.value < 75){
                createNotification("warning", "Scarsa riconoscibilità dell'immagine", "La stima della riconoscibilità dell'immagine è pari a: " + response.value + ". La scansione dell'artwork potrebbe non funzionare. Un valore di riconoscibilità ottimale deve essere compreso tra 75 e 100")
            }
        }else{
            yield put({
                type: actions.CHECK_ARTWORK_QUALITY_ERROR
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(getAllByFolderId),
        fork(getArtWorkById),
        fork(saveNewArtWork),
        fork(deleteNewArtWork),
        fork(updateArtWork),
        fork(downloadMarkerFile),
        fork(downloadMediaFile),
        fork(getArtWorkQuality)
    ]);
}

