 const actions = {

    INITIALIZE_STATE: "INITIALIZE_STATE",

    GET_ALL_CONTENT_CREATOR: "GET_ALL_CONTENT_CREATOR",
    GET_ALL_CONTENT_CREATOR_SUCCESS: "GET_ALL_CONTENT_CREATOR_SUCCESS",
    GET_ALL_CONTENT_CREATOR_ERROR: "GET_ALL_CONTENT_CREATOR_ERROR",

    GET_CONTENT_CREATOR_BY_ID: "GET_CONTENT_CREATOR_BY_ID",
    GET_CONTENT_CREATOR_BY_ID_SUCCESS: "GET_CONTENT_CREATOR_BY_ID_SUCCESS",
    GET_CONTENT_CREATOR_BY_ID_ERROR: "GET_CONTENT_CREATOR_BY_ID_ERROR",

    SAVE_NEW_CONTENT_CREATOR: "SAVE_NEW_CONTENT_CREATOR",
    SAVE_NEW_CONTENT_CREATOR_SUCCESS: "SAVE_NEW_CONTENT_CREATOR_SUCCESS",
    SAVE_NEW_CONTENT_CREATOR_ERROR: "SAVE_NEW_CONTENT_CREATOR_ERROR",

    UPDATE_CONTENT_CREATOR: "UPDATE_CONTENT_CREATOR",
    UPDATE_CONTENT_CREATOR_SUCCESS: "UPDATE_CONTENT_CREATOR_SUCCESS",
    UPDATE_CONTENT_CREATOR_ERROR: "UPDATE_CONTENT_CREATOR_ERROR",

    ENABLE_CONTENT_CREATOR: "ENABLE_CONTENT_CREATOR",
    ENABLE_CONTENT_CREATOR_SUCCESS: "ENABLE_CONTENT_CREATOR_SUCCESS",
    ENABLE_CONTENT_CREATOR_ERROR: "ENABLE_CONTENT_CREATOR_ERROR",

    DELETE_CONTENT_CREATOR: "DELETE_CONTENT_CREATOR",
    DELETE_CONTENT_CREATOR_SUCCESS: "DELETE_CONTENT_CREATOR_SUCCESS",
    DELETE_CONTENT_CREATOR_ERROR: "DELETE_CONTENT_CREATOR_ERROR",

    initializeContentCreatorState: () => ({
        type: actions.INITIALIZE_STATE
    }),

    getAllContentCreator: () => ({
        type: actions.GET_ALL_CONTENT_CREATOR
    }),

    getContentCreatorById: (contentCreatorId) => ({
        type: actions.GET_CONTENT_CREATOR_BY_ID,
        contentCreatorId: contentCreatorId
    }),

    saveContentCreator: (data) => ({
        type: actions.SAVE_NEW_CONTENT_CREATOR,
        newContentCreator: data
    }),

    updateContentCreator: (contentCreatorId, data) => ({
        type: actions.UPDATE_CONTENT_CREATOR,
        contentCreatorId: contentCreatorId,
        updatedData: data
    }),

    deleteContentCreator: (contentCreatorId) => ({
        type: actions.DELETE_CONTENT_CREATOR,
        contentCreatorId: contentCreatorId
    }),

    enableContentCreator: (contentCreatorId) => ({
        type: actions.ENABLE_CONTENT_CREATOR,
        id: contentCreatorId
    }),

}

export default actions