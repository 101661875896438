import styled from "styled-components"

export const SidebarStyle = styled.div`
    height: 100%;
    width: 350px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    
    .sidebar-content{
        margin-top: 205px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .pointer-container{
        width: 110px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 53px;
    }
    
    .icon-container{
        width: 82px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 49px;
        height: fit-content;
        background: rgb(255,255,255);
        background: linear-gradient(135deg, rgba(255,255,255,0.01) 0%, rgba(255,250,250,0.15) 100%);
        border: solid 0.5px rgba(255, 255, 255, .33);
        border-radius: 26px;
    }
    
    .label-container{
        width: 158px;
        height: 100%;
        padding-top: 52.43px;
        font-family: GothamBook;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        color: white;
    }
    
    .pointer{
        position: relative;
        height: 25px;
        float: right;
        margin-right: 14px;
        margin-bottom: 45px;
    }
    
    .page-icon{
        margin-bottom: 37px;
        height: 32px;
    }
    
    .label{
        margin-bottom: 45.86px;
        margin-left: 10px;
    }
    
    .icon-container{
        img{
            cursor: pointer;
        }
    }
    
`
