import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./authActions";
import {getToken, isTokenExpired} from "../../helpers/utility";

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.login, data.payload, false)
        if(response){
            yield localStorage.setItem('expires_at', response.expires_at);
            yield localStorage.setItem('id_token', response.access_token);
            yield localStorage.setItem('roles', response.roles);
            yield localStorage.setItem('id', response.id);
            yield localStorage.setItem('username', response.username)
            yield localStorage.setItem('refresh_token', response.refresh_token)
            yield localStorage.setItem('hasCatalogEnabled', response.hasCatalogEnabled)
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.access_token
            })
        }else{
            yield put({
                type: actions.LOGIN_ERROR,
                payload: error
            })
        }
    })
}

export function* recoverRequest() {
    yield takeEvery(actions.RECOVER_PASSWORD, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.refreshPassword, data.payload, false)
        if(response){
            yield put({
                type: actions.RECOVER_PASSWORD_SUCCESS,
                message: response.message
            })
        }else{
            yield put({
                type: actions.RECOVER_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
        if(isTokenExpired()){

        }else{
            const token = getToken();
            if(token){
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token: localStorage.getItem("id_token")
                })
            }
        }
    })

}

export function* logAsTenant() {
    yield takeEvery(actions.LOG_AS_TENANT, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.logAsTenant + "/" + data.payload, null, true)
        if(response){
            yield localStorage.setItem('expires_at', response.value.expires_at);
            yield localStorage.setItem('id_token', response.value.access_token);
            yield localStorage.setItem('roles', response.value.roles);
            yield localStorage.setItem('id', response.value.id);
            yield localStorage.setItem('username', response.value.username)
            yield localStorage.setItem('refresh_token', response.value.refresh_token)
            yield localStorage.setItem('old_token', response.value.old_access_token)
            yield localStorage.setItem('hasCatalogEnabled', response.value.hasCatalogEnabled)
            yield put({
                type: actions.LOG_AS_TENANT_SUCCESS,
                token: response.access_token
            })
        }else{
            yield put({
                type: actions.LOG_AS_TENANT_ERROR,
                payload: error
            })
        }
    })
}

export function* logBack() {
    yield takeEvery(actions.LOG_BACK, function* (data) {
        yield localStorage.setItem('id_token', localStorage.getItem('old_token'))
        const {response, error} = yield call(SuperFetch.post, endpoints.logBack, null, true)
        if(response){
            yield localStorage.setItem('expires_at', response.value.expires_at);
            yield localStorage.setItem('id_token', response.value.access_token);
            yield localStorage.setItem('roles', response.value.roles);
            yield localStorage.setItem('id', response.value.id);
            yield localStorage.setItem('username', response.value.username);
            yield localStorage.setItem('refresh_token', response.value.refresh_token);
            yield localStorage.removeItem('old_token');
            yield localStorage.removeItem('tenantName');
            yield localStorage.removeItem('hasCatalogEnabled')
            yield put({
                type: actions.LOG_BACK_SUCCESS,
                token: response.access_token
            })
        }else{
            yield put({
                type: actions.LOG_BACK_ERROR,
                payload: error
            })
        }
    })
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        localStorage.clear()
    })

}

export default function* rootSaga() {
    yield all([
        fork(loginRequest),
        fork(recoverRequest),
        fork(checkAuthorization),
        fork(logout),
        fork(logAsTenant),
        fork(logBack)
    ]);
}

