const actions = {

    INITIALIZE_ADMIN_STATE: "INITIALIZE_ADMIN_STATE",

    GET_ALL_ADMIN: "GET_ALL_ADMIN",
    GET_ALL_ADMIN_SUCCESS: "GET_ALL_ADMIN_SUCCESS",
    GET_ALL_ADMIN_ERROR: "GET_ALL_ADMIN_ERROR",

    GET_ADMIN_BY_ID: "GET_ADMIN_BY_ID",
    GET_ADMIN_BY_ID_SUCCESS: "GET_ADMIN_BY_ID_SUCCESS",
    GET_ADMIN_BY_ID_ERROR: "GET_ADMIN_BY_ID_ERROR",

    SAVE_NEW_ADMIN: "SAVE_NEW_ADMIN",
    SAVE_NEW_ADMIN_SUCCESS: "SAVE_NEW_ADMIN_SUCCESS",
    SAVE_NEW_ADMIN_ERROR: "SAVE_NEW_ADMIN_ERROR",

    UPDATE_ADMIN: "UPDATE_ADMIN",
    UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
    UPDATE_ADMIN_ERROR: "UPDATE_ADMIN_ERROR",

    ENABLE_ADMIN: "ENABLE_ADMIN",
    ENABLE_ADMIN_SUCCESS: "ENABLE_ADMIN_SUCCESS",
    ENABLE_ADMIN_ERROR: "ENABLE_ADMIN_ERROR",

    DELETE_ADMIN: "DELETE_ADMIN",
    DELETE_ADMIN_SUCCESS: "DELETE_ADMIN_SUCCESS",
    DELETE_ADMIN_ERROR: "DELETE_ADMIN_ERROR",

    initializeAdminState: () => ({
        type: actions.INITIALIZE_ADMIN_STATE
    }),

    getAllAdmins: () => ({
        type: actions.GET_ALL_ADMIN
    }),

    getAdminById: (adminId) => ({
        type: actions.GET_ADMIN_BY_ID,
        adminId: adminId
    }),

    saveAdmin: (data) => ({
        type: actions.SAVE_NEW_ADMIN,
        newAdmin: data
    }),

     updateAdmin: (adminId, data) => ({
         type: actions.UPDATE_ADMIN,
         adminId: adminId,
         updatedData: data
     }),

    deleteAdmin: (adminId) => ({
        type: actions.DELETE_ADMIN,
        adminId: adminId
    }),

    enableAdmin: (adminId) => ({
        type: actions.ENABLE_ADMIN,
        id: adminId
    }),

}

export default actions
