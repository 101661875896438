import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./folderActions";
import authActions from "../auth/authActions"

export function* getAllFolder() {
    yield takeEvery(actions.GET_ALL_FOLDER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAllFolder, null, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_FOLDER_SUCCESS,
                payload: response
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_FOLDER_ERROR,
                payload: error
            })
        }

    })

}
export function* deleteFolder() {
    yield takeEvery(actions.DELETE_FOLDER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteFolder + "/" + data.folderId, null, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_FOLDER_SUCCESS,
                payload: response
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_FOLDER_ERROR,
                payload: error
            })
        }

    })

}
export function* updateFolder() {
    yield takeEvery(actions.UPDATE_FOLDER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateFolder + "/" + data.folderId, data.updateData, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_FOLDER_SUCCESS,
                payload: response
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_FOLDER_ERROR,
                payload: error
            })
        }

    })

}

export default function* rootSaga() {
    yield all([
        fork(getAllFolder),
        fork(deleteFolder),
        fork(updateFolder)
    ]);
}
