import styled from "styled-components";

export const TableStyle = styled.div`
    height: 100%;

    .ant-table{
        border-radius: 26px;
        background-color: transparent;
    }
    
    .ant-table-tbody{
        background: rgb(255,250,250);
        background: linear-gradient(315deg, rgba(255,250,250,0.16) 0%, rgba(255,252,252,0.11) 70%, rgba(255,255,255,0.015) 100%);
        font-family: GothamBook;
        font-size: 18px;
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
    }
    
    
    table{
        border-radius: 26px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 26px;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
        border-top-right-radius: 26px;
    }
    
    .ant-table-cell{
        color: white
    }
    .ant-table-cell-row-hover{
        background-color: transparent !important;
    }
    
    .ant-table-tbody > tr > td{
        border-bottom: 1px solid rgb(255,255,255, 0.20);
    }
    
    .ant-table-tbody > tr:last-child > td{
        border-bottom: none;
    }
    
    .ant-table-tbody > tr:last-child > td:first-child{
        border-bottom-left-radius: 26px;
    }
    
    .ant-table-tbody > tr:last-child > td:last-child{
        border-bottom-right-radius: 26px;
    }
    
    .ant-table-thead > tr > th {
        background: rgb(101,113,134);
        background: linear-gradient(0deg, rgba(101,113,134,0.22) 25%, rgba(51,85,145,0.62) 100%);
        color: white;
        font-family: GothamBlack,sans-serif;
        font-size: 16px;
        border-bottom: 2px solid white;
    }
    
    .ant-typography{
        color: white;
    }
    
    .ant-pagination-prev .ant-pagination-item-link{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold,sans-serif;
    }
    
    .ant-pagination-item{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-pagination-next .ant-pagination-item-link{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-select-selector{
        border-radius: 16px !important;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-select-arrow{
        color: white;
    }
    
    .ant-select-dropdown{
        border-radius: 16px;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-table-tbody > tr.ant-table-placeholder:hover > td{
        background: transparent;
    }
    
    .ant-empty-description{
        color: white;
    }
    
    .ant-pagination-item a{
        color: white;
    }
    
    .ant-pagination-item-active a {
        color: #1890ff;
    }
    
    .ant-popover-arrow-content{
        background-color: white !important;
    }
    
    .ant-radio-wrapper {
        color: white;
        font-family: GothamBook, sans-serif;
        font-size: 16px;
    }
    
    .ant-radio-inner{
        border-width: 0;
    }
    
    .ant-radio-inner::after{
        background-color: rgb(51,85,145)
    }
    
    .ant-radio-checked .ant-radio-inner::after{
        transform: scale(0.7);
    }
    
    .ant-switch-checked{
        background-color: white;
    }
    
    .ant-switch-handle::before{
        background-color: rgb(63,239,21)
    }
    
    .icon-disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    
    img{
        cursor: pointer;
    }
    
    
`
