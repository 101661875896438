import styled from "styled-components"

export const HomeStyleMobile = styled.div`
    height: 100%;
    background: rgb(37,59,96);
    background: linear-gradient(135deg, rgba(37,59,96,1) 0%, rgba(14,14,0,1) 88%);
    
    .content-container{
       overflow:auto;
        height : calc(100% - 230px);
        margin-bottom: 50px;
    }
    
    .content{
        height: 100%;
        overflow-y: scroll !important;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
    }
    
    .spin-wrapper{
        height: 100%;
    }

`
