const actions = {

    INITIALIZE_STATE: "INITIALIZE_STATE",

    GET_ALL_TENANT: "GET_ALL_TENANT",
    GET_ALL_TENANT_SUCCESS: "GET_ALL_TENANT_SUCCESS",
    GET_ALL_TENANT_ERROR: "GET_ALL_TENANT_ERROR",

    GET_TENANT_BY_ID: "GET_TENANT_BY_ID",
    GET_TENANT_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
    GET_TENANT_BY_ID_ERROR: "GET_BY_ID_ERROR",

    SAVE_NEW_TENANT: "SAVE_NEW_TENANT",
    SAVE_NEW_TENANT_SUCCESS: "SAVE_NEW_TENANT_SUCCESS",
    SAVE_NEW_TENANT_ERROR: "SAVE_NEW_TENANT_ERROR",

    UPDATE_TENANT: "UPDATE_TENANT",
    UPDATE_TENANT_SUCCESS: "UPDATE_TENANT_SUCCESS",
    UPDATE_TENANT_ERROR: "UPDATE_TENANT_ERROR",

    ENABLE_TENANT: "ENABLE_TENANT",
    ENABLE_TENANT_SUCCESS: "ENABLE_SUCCESS",
    ENABLE_TENANT_ERROR: "ENABLE_ERROR",

    DELETE_TENANT: "DELETE_TENANT",
    DELETE_TENANT_SUCCESS: "DELETE_TENANT_SUCCESS",
    DELETE_TENANT_ERROR: "DELETE_TENANT_ERROR",

    RESET_STATE: "RESET_STATE",

    SET_TENANTS: "SET_TENANTS",

    initializeState: () => ({
        type: actions.INITIALIZE_STATE
    }),

    getAll: () => ({
        type: actions.GET_ALL_TENANT
    }),

    enable: (tenantId) => ({
        type: actions.ENABLE_TENANT,
        id: tenantId
    }),

    addNewTenant: (data) => ({
        type: actions.SAVE_NEW_TENANT,
        payload: data
    }),

    deleteTenant: (tenantId) => ({
        type: actions.DELETE_TENANT,
        id: tenantId
    }),

    getTenantById: (tenantId) => ({
        type: actions.GET_TENANT_BY_ID,
        id: tenantId
    }),

    setTenants: (tenants) => ({
        type: actions.SET_TENANTS,
        tenants: tenants
    }),

    updateTenant: (data, tenantId) => ({
        type: actions.UPDATE_TENANT,
        data: data,
        id: tenantId
    }),

}

export default actions