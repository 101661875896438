import * as React from "react"
import ReactApexChart from "react-apexcharts";

function Views(props){

    const {y} = props.options ?? []
    const weekday = ["D","L","M","M","G","V","S"];

    function getDayOfWeekNameFrom(dayOfWeekNumber) {
        const calculatedDay = new Date()

        calculatedDay.setDate(calculatedDay.getDate() + dayOfWeekNumber)

        return weekday[calculatedDay.getDay()]
    }

    return(
        <div>
            <ReactApexChart
                type={"bar"}
                options={{
                    legend: {
                        show: false
                    },
                    chart: {
                        type: "line",
                        id: "basic-bar",
                        toolbar:{
                            show: false

                        }
                    },
                    xaxis: {
                        categories:
                            Array.from({length:7},(v,k)=>-k).reverse().map( it =>
                                getDayOfWeekNameFrom(it)
                            ),
                        labels: {
                            style:{
                                colors: "#FFFFFF",
                                fontSize: "16px",
                                fontFamily: "GothamBook"
                            }
                        },
                        axisBorder: {
                            show: false
                        },
                        tooltip:{
                            enabled: false
                        }
                    },
                    yaxis:{
                        labels: {
                            style:{
                                colors: "#FFFFFF",
                                fontSize: "16px",
                                fontFamily: "GothamBook"
                            }
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth'
                    },
                }}
                series={[
                    {
                        name: "Visualizzazioni",
                        data: y ?? []
                    },
                ]}
                height={287}
            />
        </div>
    )
}

export default Views
