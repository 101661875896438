import actions from "./authActions";

const initState = {
    idToken: null,
    errorMessage: null,
    currentRoute: null,
    loading: false,
    loadingCounter: 0,
    recoverPasswordSuccess: false,
    message: null
}

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return{
                ...state,
                idToken: action.token,
            }
        case actions.LOGIN_ERROR:
            return{
                ...state,
                errorMessage: action.payload
            }
        case actions.RESET_SUCCESS_MESSAGE:
            return {
                ...state,
                message: null
            }
        case actions.RESET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: null
            }
        case actions.SET_IDTOKEN:
            return {
                ...state,
                idToken: localStorage.getItem("id_token")
            }
        case actions.LOGOUT:
            return initState
        case actions.SET_ROUTE:
            return {
                ...state,
                currentRoute: action.payload
            }
        case actions.LOG_AS_TENANT_SUCCESS:
            return {
                ...state,
                idToken: action.token
            }
        case actions.LOG_AS_TENANT_ERROR:
            return{
                ...state,
                errorMessage: action.payload.message
            }
        case actions.LOG_BACK_SUCCESS:
            return {
                ...state,
                idToken: action.token
            }
        case actions.LOG_BACK_ERROR:
            return{
                ...state,
                errorMessage: action.payload.message
            }
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: true,
                loadingCounter: state.loadingCounter + 1
            }
        case actions.LOADING_FALSE:
            let tempLoadingCounter = state.loadingCounter - 1

            return {
                ...state,
                loading: !(tempLoadingCounter === 0),
                loadingCounter: tempLoadingCounter
            }
        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                recoverPasswordSuccess: true,
                message: action.message
            }
        default:
            return state;
    }

}
