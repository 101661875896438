import styled from "styled-components"

export const TopbarStyle = styled.div`
    height: 150px;
    width: 100%;
    position: fixed;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
   
    .logo{
      height: 40px;
      margin-top: 70px;
      margin-left: 80px;
    }
    
    .middleDiv{
      display: flex;
      justify-content: flex-end; 
      flex-direction: row;
      width:100vw;
    }
    
    .logoutIcon{
      height: 40px;
      margin-top: 67px;
      margin-right: 80px;
    }
    
    .label-current-tenant{
        position: relative;
        float: right;
        margin-right: 60px;
        margin-top: 70px;
        color: white;
        font-size: 18px;
        font-style: italic;
        font-weight: lighter;
    }
    
    .exitFromTenantBtn{
        position: relative;
        float: right;
        margin-right: 45px;
        margin-top: 67px;
        cursor:pointer;
    }
    
`
