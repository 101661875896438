import React, {useState} from "react";
import {
    Button,
    Card,
    Carousel,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Select,
    Tabs,
    Upload
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import authActions from "../../../redux/auth/authActions";
import {AddArtWorkStyleMobile} from "../../../style/mobileStyle/AddArtWorkStyleMobile";
import {InputStyle} from "../../../style/InputStyle";
import MarkerPlaceholder from "../../../svg/markerPlaceholder.svg";
import {ButtonStyle} from "../../../style/ButtonStyle";
import MediaPlaceholder from "../../../svg/mediaPlaceholder.svg";
import {ChromePicker} from "react-color";
import {getQualityValue, isValidUrl} from "../../../helpers/utility";
import artWorkActions from "../../../redux/artWork/artWorkActions";
import folderActions from "../../../redux/folder/folderActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

const { TabPane } = Tabs;
const {setRoute} = authActions
const {getArtWorkById, addNewArtWork, updateArtWork, initializeArtWorkState, downloadMarker, downloadMedia, checkMarkerQuality} = artWorkActions
const {getAllFolder, initializeFolderState} = folderActions
const {TextArea} = Input
const {Dragger} = Upload;
const {Option} = Select;


function AddArtWorkMobile(){

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let showSwitch = false

    let {type, id} = useParams()

    const [form] = Form.useForm();

    const artWork = useSelector(state => state.ArtWork.artWork)
    const downloadedMarkerFile = useSelector(state => state.ArtWork.markerFile)
    const markerQuality = useSelector(state => state.ArtWork.markerQuality)
    const downloadedMediaFile = useSelector(state => state.ArtWork.mediaFile)
    const mediaFileName = useSelector(state => state.ArtWork.mediaFileName)
    const backToArtWorkList = useSelector(state => state.ArtWork.backToList)
    const folders = useSelector(state => state.Folder.folders)

    const [markerFile, setMarkerFile] = useState(null)
    const [mediaFile, setMediaFile] = useState(null)
    const [mediaUrl, setMediaUrl] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState(null)
    const [transparentBackground, setTransparentBackground] = useState(false)
    const [showColorPickerPopover, setShowColorPickerPopover] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState(null)
    const [firstFrameAsCover, setFirstFrameAsCover] = useState(false)
    const [folderMap, setFolderMap] = useState([])
    const [defaultFolderMap, setDefaultFolderMap] = useState(null)
    const [videoName, setVideoName] = useState("")
    const [showURLField, setShowURLField] = useState(false)
    const [activeKey, setActiveKey] = useState("1")
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onKeyChange = (key) => setActiveKey(key)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        dispatch(getAllFolder())
        if (id) {
            dispatch(getArtWorkById(id))
        }
        return () => {
            dispatch(initializeArtWorkState())
            dispatch(initializeFolderState())
        }
    }, [])

    React.useEffect(() => {

        if (backToArtWorkList) {
            navigate(-1)
        }
    }, [backToArtWorkList])

    React.useEffect(() => {
        if (artWork) {
            form.setFieldsValue({
                title: artWork.title,
                description: artWork.description
            })
            setTitle(artWork.title)
            setDescription(artWork.description)
            setBackgroundColor(artWork.backgroundColor)
            setFirstFrameAsCover(artWork.firstFrameAsCover)
            setTransparentBackground(artWork.transparentBackground)
            setDefaultFolderMap(artWork.folderMap)
            setFolderMap(Object.keys(artWork.folderMap))


            if (artWork.markerUrl) {

                var data = {
                    "name": artWork.markerUrl,
                    "width": 150,
                    "height": 150,
                    "fill": true
                }

                dispatch(downloadMarker(data))
            }
            if(isValidUrl(artWork.mediaUrl)){
                setMediaUrl(artWork.mediaUrl)
                setShowURLField(true)
            }else{
                if (artWork.mediaUrl) {
                    var data = {
                        "name": artWork.mediaUrl,
                    }

                    dispatch(downloadMedia(data))
                }
            }
        }
    }, [artWork])

    React.useEffect(() => {
        if (downloadedMarkerFile) {
            downloadedMarkerFile.blob()
                .then(blob => {
                    setMarkerFile(blob)
                })
        }
    }, [downloadedMarkerFile])

    React.useEffect(() => {
        if (downloadedMediaFile) {
            setMediaFile(downloadedMediaFile)
        }
    }, [downloadedMediaFile])

    //TEMP

    //TEMP

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleAdd = (fieldsValue) => {
        let data = {
            "markerFile": markerFile,
            "mediaFile": mediaFile,
            "mediaUrl": fieldsValue['mediaURL'],
            "title": fieldsValue['title'],
            "description": fieldsValue['description'],
            "transparentBackground": transparentBackground,
            "backgroundColor": transparentBackground ? backgroundColor : null,
            "folderList": folderMap
        }

        if (!fieldsValue['markerFile']) {
            delete data.markerFile
        }

        if (!fieldsValue['mediaFile']) {
            delete data.mediaFile
        }

        if (id) {
            dispatch(updateArtWork(id, data))
        } else {
            dispatch(addNewArtWork(data))
        }
    };

    const handleSearch = (e) => {
    };

    const onCancel = () => {
        navigate(-1)
    };

    const handleVisibleChange = () => {
        setShowColorPickerPopover(false)
    };

    const handleDropdownVisibleChange = (open) => {
        if (open) {
            dispatch(getAllFolder())
        }
    }

    function handleChange(value, id) {
        setFolderMap([])
        var newFolderMap = []
        id.forEach((element, index) => {
            if (element.key) {
                newFolderMap.push(element.key)
            } else {
                newFolderMap.push(value[index])
            }
        });
        setFolderMap(newFolderMap)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    React.useEffect(()=>{
        dispatch(setRoute("/mobile/addArtWork"))
    },[])

    return(
        <Form
            form={form}
            name={"register"}
            onFinish={handleAdd}
            key={"formArtwork"}>
        <Tabs defaultActiveKey="1" activeKey={activeKey}>
            <TabPane tab="Step 1" key="1">
                <AddArtWorkStyleMobile>
                    <Col lg={24} sm={24} xs={24}>
                        <div className={"form-content"}>
                            <Row style={{marginTop: "2%"}} gutter={[10, 10]}>
                                <Col lg={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={"markerFile"}
                                        valuePropName={"markerFile"}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (markerFile) {
                                                        return Promise.resolve()
                                                    } else {
                                                        return Promise.reject(new Error("Marker il campo è obbligatorio"))
                                                    }
                                                }

                                            }
                                        ]}
                                    >
                                        <Dragger
                                            onChange={(e) => {
                                                //if(e.fileList.length > 1) e.fileList.shift()
                                                if(e.file.status === "done"){
                                                    setMarkerFile(e.file.originFileObj)
                                                    dispatch(checkMarkerQuality({markerFile: e.file.originFileObj}))
                                                }
                                            }}
                                            customRequest={({file, onSuccess}) => {
                                                //Ovverride custom antd action with a dummy one saying the loading is completed.
                                                setTimeout(() => {
                                                    onSuccess("ok");
                                                }, 0);
                                            }}
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={file => {
                                                const isPNG = file.type === 'image/png';
                                                const isJPG = file.type === 'image/jpg';
                                                const isJPEG = file.type === 'image/jpeg';
                                                if(file.size > 6291456){
                                                    message.error("La grandezza del file è maggiore di 6MB")
                                                    return Upload.LIST_IGNORE
                                                }
                                                if (!isPNG && !isJPG && !isJPEG) {
                                                    message.error(`${file.name} non è un .png o .jpg.`);
                                                    return Upload.LIST_IGNORE
                                                }else{
                                                    return true
                                                }
                                            }}
                                            accept={".jpg,.png"}
                                        >
                                            <Card className={"dashedBackground"}>
                                                <img src={MarkerPlaceholder}
                                                     style={{
                                                         width: "70px",
                                                         height: "70px",
                                                         objectFit: "contain"
                                                     }}/>
                                                <div style={{color:"white"}}>
                                                    {markerQuality !== null ? <>
                                                            <label>Stima della riconoscibilità dell'immagine immagine: </label>
                                                            {getQualityValue(markerQuality)}
                                                        </>
                                                        : null}
                                                </div>
                                                <ButtonStyle color={"white"} textColor={"#335591"}
                                                             height={"34px"}
                                                             fontSize={"14px"}
                                                             style={{marginTop: "3%"}}>
                                                    <Button style={{width: "200px"}}>SELEZIONA FILE</Button>
                                                </ButtonStyle>
                                                <p style={{marginTop: "3%", fontSize: "12px"}}>.jpg .png (max file size 6MB,
                                                    dimensioni: min 320px, max 5000px)</p>
                                            </Card>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <div style={{display:"flex",justifyContent:"center"}}>
                                        <Carousel style={{height:240, width:"90vw"}}>
                                            <div>
                                                <Form.Item
                                                    name={"mediaFile"}
                                                    valuePropName={"mediaFile"}
                                                    initialValue={mediaFile}
                                                    rukes={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (mediaFile) {
                                                                    return Promise.resolve()
                                                                } else {
                                                                    return Promise.reject(new Error("Contenuto il campo è obbligatorio"))
                                                                }
                                                            }

                                                        }
                                                    ]}
                                                >
                                                    <Dragger
                                                        onChange={(e) => {
                                                            if(e.file.status === "done"){
                                                                setMediaFile(e.file.originFileObj)
                                                                setVideoName(e.file.name)
                                                            }
                                                        }}
                                                        customRequest={({file, onSuccess}) => {
                                                            //Ovverride custom antd action with a dummy one saying the loading is completed.
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                        multiple={false}
                                                        maxCount={1}
                                                        accept={"video/*,.jpg,.png,.gif"}
                                                    >
                                                        <Card className={"dashedBackground"} key={mediaFile}>
                                                            <img src={MediaPlaceholder} style={{
                                                                    objectFit: "contain",
                                                                    width:"70px",
                                                                    height:"70px"
                                                                }}/>
                                                            <ButtonStyle color={"white"} textColor={"#335591"}
                                                                         height={"34px"}
                                                                         fontSize={"14px"}
                                                                         style={{marginTop: "3%"}}>
                                                                <Button style={{width: "250px"}}>SELEZIONA CONTENUTO</Button>
                                                            </ButtonStyle>
                                                            <p style={{marginTop: "3%", fontSize: "12px"}}>.jpg .png (max file size 6MB,
                                                                dimensioni: min 320px, max 5000px) o video o gif (dimensione massima
                                                                100MB) </p>
                                                        </Card>
                                                    </Dragger>
                                                </Form.Item>
                                            </div>
                                            <div>
                                                <Card className={"dashedBackground"} key={mediaFile}>
                                                    <img src={MediaPlaceholder} style={{objectFit: "contain"}}/>
                                                    <p style={{marginTop: "3%"}}>Inserisci URL esterno</p>
                                                    <InputStyle borderRadius={"17px"}
                                                                size={"34px"}
                                                                fontSize={"16px"}
                                                    >
                                                        <Form.Item
                                                            name={"mediaURL"}
                                                            rules={[{
                                                                required: !mediaFile,
                                                                message: "URL il campo è obbligatorio",
                                                                type: "string"
                                                            }]}
                                                            initialValue={mediaUrl}
                                                        >
                                                            <Input placeholder={"Inserisci URL di un video esterno"}/>
                                                        </Form.Item>
                                                    </InputStyle>
                                                </Card>
                                            </div>
                                        </Carousel>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"marginInput"}>
                                <Col lg={24} sm={24} xs={24} style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                    <ButtonStyle>
                                        <Button style={{width: "250px", margin:10}} onClick={()=>{onKeyChange('2')}}>
                                            CONTINUA
                                        </Button>
                                    </ButtonStyle>
                                    <ButtonStyle color={"black"} border={"2px solid #335591"}>
                                        <Button style={{width: "250px", margin:10}} onClick={onCancel}>
                                            CANCELLA
                                        </Button>
                                    </ButtonStyle>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </AddArtWorkStyleMobile>
            </TabPane>
            <TabPane tab="Step 2" key="2">
                <AddArtWorkStyleMobile>
                    <Row>
                        <Col lg={24} sm={24} xs={24}>
                            <InputStyle borderRadius={"17px"}>
                                <Form.Item
                                    name={"title"}
                                    rules={[{
                                        required: true,
                                        message: "Titolo il campo è obbligatorio",
                                        type: "string"
                                    }]}
                                >
                                    <Input placeholder={"Titolo"}/>
                                </Form.Item>
                            </InputStyle>
                        </Col>
                        <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name={"folderMap"}
                                valuePropName={"folderMap"}
                                initialValue={folderMap}
                            >
                                <InputStyle borderRadius={"17px"}>
                                    <Select
                                        mode="tags"
                                        allowClear
                                        key={defaultFolderMap}
                                        defaultValue={defaultFolderMap ? Object.keys(defaultFolderMap) : []}
                                        style={{width: '100%', height: "54px"}}
                                        placeholder="Seleziona Cartelle"
                                        onDropdownVisibleChange={handleDropdownVisibleChange}
                                        onChange={handleChange}
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                    >
                                        {folders && folders.map((folder) => {
                                            if (folder.fakeFolder !== true) {
                                                return (
                                                    <Option key={folder.folderId}>{folder.title}</Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </InputStyle>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10} style={{marginTop: "2%"}}>
                        <Col lg={24} sm={24} xs={24}>
                            <InputStyle borderRadius={"17px"} size={"150px"}>
                                <Form.Item
                                    name={"description"}
                                    rules={[{
                                        required: true,
                                        message: "Descrizione il campo è obbligatorio",
                                        type: "string"
                                    }]}
                                >
                                    <TextArea rows="10" placeholder={"Descrizione"}/>
                                </Form.Item>
                            </InputStyle>
                        </Col>
                        <Col lg={1}/>
                        <Col lg={12} sm={24} xs={24}
                             style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <Row>
                                <Col>
                                    <Form.Item
                                        name={"email"}
                                        valuePropName={"transparentBackground"}
                                        initialValue={transparentBackground}
                                    >
                                        <Popover
                                            content={
                                                <ChromePicker
                                                    onChange={(e) => {
                                                        setBackgroundColor(e.hex)

                                                    }}
                                                    disableAlpha={true}
                                                    color={backgroundColor ?? "#ffffff"}
                                                />
                                            }
                                            visible={showColorPickerPopover}
                                            onVisibleChange={handleVisibleChange}
                                        >
                                            <Checkbox checked={transparentBackground} onChange={(e) => {
                                                if (e.target.checked) setShowColorPickerPopover(true)
                                                else setBackgroundColor(null)
                                                setTransparentBackground(e.target.checked)
                                            }}>Il video è in chroma key</Checkbox>
                                        </Popover>
                                    </Form.Item>
                                    <div style={{display:"flex", flexDirection:"row", color:"white"}}>
                                        <FontAwesomeIcon icon={faCircleInfo} style={{marginTop:9}}/>
                                        <div style={{textDecoration:"underline", cursor:"pointer", marginTop:5, marginLeft:10}} onClick={showModal}>Clicca qui per avere maggiori informazioni sulla riconoscibilità immagine</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{
                                        width: "30px",
                                        height: "30px",
                                        backgroundColor: backgroundColor ? backgroundColor : "transparent"
                                    }} onClick={() => {
                                        setShowColorPickerPopover(true)
                                    }}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"marginInput"}>
                        <Col lg={24} sm={24} xs={24} style={{display:"flex", alignItems:"center", flexDirection:"column"}} >
                            <ButtonStyle>
                                <Button style={{width: "250px", margin:10}} htmlType={"submit"}>
                                    {id ? "SALVA" : "AGGIUNGI"}
                                </Button>
                            </ButtonStyle>
                            <ButtonStyle color={"black"} border={"2px solid #335591"}>
                                <Button style={{width: "250px", margin:10}} onClick={()=>{onKeyChange('1')}}>
                                    CANCELLA
                                </Button>
                            </ButtonStyle>
                        </Col>
                    </Row>
                    <Modal visible={isModalVisible}
                           footer={[
                               <ButtonStyle>
                                   <Button style={{width: "100px"}} key="back" type="primary" onClick={handleOk} onCancel={handleCancel}>OK</Button>
                               </ButtonStyle>
                           ]}>
                        <p>
                            <h3>
                                <b>Suggerimenti per la selezione delle immagini di riferimento</b>
                            </h3>
                        </p>
                        <ul>
                            <li>La risoluzione dell'immagine deve essere di <b>almeno 300 x 300 pixel.</b> L'utilizzo di immagini ad alta risoluzione non migliora le prestazioni.</li>
                            <li>Le immagini di riferimento possono essere fornite in <b>formato file PNG o JPEG.</b></li>
                            <li><b>Le informazioni sul colore non vengono utilizzate.</b> Sia le immagini in scala di colori che quelle equivalenti possono essere utilizzate come immagini di riferimento o dagli utenti in fase di runtime.</li>
                            <li><b>Evita immagini con una compressione elevata,</b> perché interferiscono con l'estrazione delle funzionalità.</li>
                            <li><b>Evita immagini con un gran numero di elementi geometrici o pochi elementi</b> (ad es. codici a barre, codici QR, loghi e altri elementi grafici) poiché ciò potrebbe compromettere il rilevamento e il monitoraggio del rendimento.</li>
                            <li><b>Evita immagini con pattern ripetuti,</b> in quanto ciò può causare problemi di rilevamento e monitoraggio.</li>
                            <li><b>Consigliamo un punteggio di almeno 75.</b></li>
                        </ul>
                    </Modal>
                </AddArtWorkStyleMobile>
            </TabPane>
        </Tabs>
    </Form>
    )
}

export default AddArtWorkMobile
