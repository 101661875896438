import React, {useState} from "react";
import {Button, Col, Input, Popconfirm, Radio, Row, Switch, Table, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import adminActions from "../../redux/admin/adminActions";
import contentCreatorActions from "../../redux/contentCreator/contentCreatorActions";
import authActions from "../../redux/auth/authActions"
import {TableStyle} from "../../style/TableStyle";
import EditIcon from "../../svg/edit.svg";
import DeleteIcon from "../../svg/trash.svg";
import {InputStyle} from "../../style/InputStyle";
import SearchIcon from "../../svg/search.svg";
import {ButtonStyle} from "../../style/ButtonStyle";
import {useNavigate} from "react-router-dom";
import {createGlobalStyle} from "styled-components";
import {getUserId} from "../../helpers/utility";

const {Text} = Typography;
const {initializeAdminState, getAllAdmins, enableAdmin, deleteAdmin} = adminActions;
const {initializeContentCreatorState, getAllContentCreator, enableContentCreator, deleteContentCreator} = contentCreatorActions;
const {setRoute} = authActions;

const Styled = createGlobalStyle`
    body{
        .ant-popover-arrow-content{
            background-color: white !important;
        }
        .ant-popover-arrow-content::before{
            background: white ;
        }
    }
    
`;

export default function BlankPage() {
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'USERNAME',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            render: (text) => {
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: 'NOME E COGNOME',
            dataIndex: 'firstNameLastName',
            key: 'firstNameLastName',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            render: (text) => {
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            render: (text) => {
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: 'STATO',
            dataIndex: 'enabled',
            key: 'enabled',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            render: (enabled, user) => {
                return <Switch disabled={user.mainAdmin || user.id === getUserId()} checked={enabled} onChange={() => onChange(user)}/>
            }
        },
        {
            title: 'AZIONI',
            align: 'center',
            //width:'200px',
            className: 'maxWidth300px',
            render: (it, row) => {
                return <Row justify={"space-around"} gutter={[5, 5]}>
                    <Col><img style={{width:25}} src={EditIcon} onClick={() => handleEdit(row)}/></Col>
                    <Col><Popconfirm disabled={row.id === getUserId() || row.mainAdmin} color={"white"}
                                     title={"Sei sicuro di voler eliminare?"}
                                     onConfirm={() => handleDelete(row.id)}><Styled/><img style={{width:25}}
                        className={row.id === getUserId() || row.mainAdmin ? "icon-disabled" : ""}
                        src={DeleteIcon}/></Popconfirm></Col>
                </Row>

            }
        }
    ]

    const handleEdit = (row) => {
        let id = row.id
        if (sourceRadioButton === 1) {
            navigate("/addOrModify/admin/" + id)
        }
        if (sourceRadioButton === 2) {
            navigate("/addOrModify/user/" + id)
        }
    }

    const handleDelete = (id) => {
        if (id !== getUserId()) {
            if (sourceRadioButton === 1) {
                dispatch(deleteAdmin(id))
            }
            if (sourceRadioButton === 2) {
                dispatch(deleteContentCreator(id))
            }
        }

    }

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            if (sourceRadioButton === 1) {
                setSourceDataFiltered(admins.filter((admin) => admin.username.toLowerCase().includes(e.target.value.toLowerCase()) || admin.firstNameLastName.toLowerCase().includes(e.target.value.toLowerCase())))
            }
            if (sourceRadioButton === 2) {
                setSourceDataFiltered(contentCreators.filter((creator) => creator.username.toLowerCase().includes(e.target.value.toLowerCase()) || creator.firstNameLastName.toLowerCase().includes(e.target.value.toLowerCase())))
            }
        } else {
            setSourceDataFiltered(null)
        }
    }

    const handleAdd = () => {
        if (sourceRadioButton === 1) {
            dispatch(setRoute("/addOrModify/admin"))
            navigate("/addOrModify/admin")
        }
        if (sourceRadioButton === 2) {
            dispatch(setRoute("/addOrModify/user"))
            navigate("/addOrModify/user")
        }
    }

    const navigate = useNavigate()

    const updateAdminList = useSelector(state => state.Admin.updateList)
    const totalAdminResults = useSelector(state => state.Admin.totalResults)
    const admins = useSelector(state => state.Admin.admins)


    const updateContentCreatorList = useSelector(state => state.ContentCreator.updateList)
    const totalContentCreatorResults = useSelector(state => state.ContentCreator.totalResults)
    const contentCreators = useSelector(state => state.ContentCreator.contentCreators)


    const [defaultPageSize, setDefaultPageSize] = React.useState(10)
    const [sourceRadioButton, setSourceRadioButton] = React.useState(1)

    const [sourceData, setSourceData] = React.useState([])
    const [sourceDataFiltered, setSourceDataFiltered] = useState(null)
    const [sourceDataTotalResults, setSoutceDataTotalResults] = React.useState(0)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if (sourceRadioButton === 1 || updateAdminList) {
            dispatch(getAllAdmins())
        }
        if (sourceRadioButton === 2 || updateContentCreatorList) {
            dispatch(getAllContentCreator())
        }
        return () => {
            dispatch(initializeAdminState())
            dispatch(initializeContentCreatorState())
        }
    }, [])

    React.useEffect(() => {
        if (updateAdminList === true) {
            dispatch(getAllAdmins())
        }
    }, [updateAdminList])

    React.useEffect(() => {
        if (updateContentCreatorList === true) {
            dispatch(getAllContentCreator())
        }
    }, [updateContentCreatorList])

    React.useEffect(() => {
        if (sourceRadioButton === 1) {
            setSourceData(admins)
            setSoutceDataTotalResults(totalAdminResults)
        }
        if (sourceRadioButton === 2) {
            setSourceData(contentCreators)
            setSoutceDataTotalResults(totalContentCreatorResults)
        }
    }, [admins, contentCreators])

    function onChange(user) {
        if (sourceRadioButton === 1) dispatch(enableAdmin(user.id))
        if (sourceRadioButton === 2) dispatch(enableContentCreator(user.id))
    }

    function updateTable(source) {
        if (source.target.value === 1) {
            dispatch(getAllAdmins())
        } else if (source.target.value === 2) {
            dispatch(getAllContentCreator())
        }
        setSourceRadioButton(source.target.value)
    }

    return (
        <TableStyle>
            <Row gutter={[10, 10]} align={"middle"}>
                <Col span={10}>
                    <Radio.Group onChange={updateTable} defaultValue={sourceRadioButton}>
                        <Radio value={1}>Mostra Amministratori</Radio>
                        <Radio value={2}>Mostra Content Creator</Radio>
                    </Radio.Group>
                </Col>
                <Col span={5}>
                    <InputStyle size={"36px"}><Input placeholder={"Search"} onChange={handleSearch}
                                                     prefix={<img src={SearchIcon}/>}/></InputStyle>
                </Col>
                <Col span={7}>
                    <ButtonStyle><Button block
                                         onClick={handleAdd}>{sourceRadioButton === 1 ? "+ AGGIUNGI ADMIN" : "+ AGGIUNGI CONTENT CREATOR"}</Button></ButtonStyle>
                </Col>
                <Col lg={22} sm={22} xs={22}>

                    <Table
                        //scroll={{x: 1500}}
                        scroll={{x: true}}
                        rowKey={record => record.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (e) => {
                                    //createOrModify(e, record.id)
                                },
                            };
                        }}
                        className='clearTop customTable'
                        dataSource={sourceDataFiltered ? sourceDataFiltered : sourceData}
                        columns={columns}
                        pagination={{
                            total: sourceDataTotalResults,
                            pageSizeOptions: ['10', '20', '30', '50', '100'],
                            showSizeChanger: true,
                            defaultPageSize: defaultPageSize
                        }}
                        onChange={(pagination, filters, sorter, extra) => {
                            //updatePaginationData(pagination, sorter)
                            //dispatch(getAll(createPaginationData(pagination, sorter)))
                        }}
                    />

                </Col>
            </Row>
        </TableStyle>
    )
}
