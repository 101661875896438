import React, {useEffect, useState} from "react";
import {TableStyle} from "../../style/TableStyle";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Input, Popconfirm, Row, Switch, Table, Typography} from "antd";
import {getUserId} from "../../helpers/utility";
import EditIcon from "../../svg/edit.svg";
import DeleteIcon from "../../svg/trash.svg";
import {createGlobalStyle} from "styled-components";
import {InputStyle} from "../../style/InputStyle";
import SearchIcon from "../../svg/search.svg";
import {ButtonStyle} from "../../style/ButtonStyle";
import {ListStyle} from "../../style/ListStyle";
import catalogActions from "../../redux/catalog/catalogActions"
import {useNavigate} from "react-router-dom";

const {Text} = Typography;
const {getAll, enableCatalog, disableCatalog, deleteCatalog} = catalogActions

const Styled = createGlobalStyle`
    body{
        .ant-popover-arrow-content{
            background-color: white !important;
        }
        .ant-popover-arrow-content::before{
            background: white ;
        }
    }
    
`;
export default function Catalog() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const catalogs = useSelector(state => state.Catalog.catalogs)
    const updateList = useSelector(state => state.Catalog.updateList)

    const [defaultPageSize, setDefaultPageSize] = React.useState(10)
    const [sourceDataTotalResults, setSoutceDataTotalResults] = React.useState(0)

    const [catalogFiltered, setCatalogFiltered] = useState(null)


    useEffect(()=>{
        dispatch(getAll())
    },[])

    useEffect(()=>{
    },[catalogs])

    useEffect(()=>{
        if(updateList === true){
            dispatch(getAll())
        }
    },[updateList])

    const handleAdd = () => {
       navigate("/addOrModifyCatalog")
    }

    const handleEdit = (row) =>{
        navigate("/addOrModifyCatalog/"+ row.id)
    }

    const handleDelete = (rowId) => {
        dispatch(deleteCatalog(rowId))
    }

    function onChange(catalog){
        if(catalog.enabled){
            dispatch(disableCatalog(catalog.id))
        }else{

            dispatch(enableCatalog(catalog.id))
        }
    }

    const columns = [
        {
            title:"NOME",
            key: "name",
            dataIndex: "name",
            align: "center",
            width: "50%",
            render:(text)=>{
                return <Text className="styleBlack">{text}</Text>
            }
        },
        {
            title: "STATO",
            key: "enabled",
            dataIndex: "enabled",
            align: "center",
            width: "25%",
            // className:"maxWidth300px",
            render: (enabled, catalog) => {
                return <Switch checked={enabled} onChange={() => onChange(catalog)}/>
            }
        },
        {
            title: "AZIONI",
            align: "center",
            width: "25%",
            // className: "maxWidh300px",
            render: (it, row) => {
                return <Row justify={"space-around"} gutter={[5, 5]}>
                    <Col><img style={{width:25}} src={EditIcon} onClick={() => handleEdit(row)}/></Col>
                    <Col><Popconfirm disabled={row.id === getUserId() || row.mainAdmin} color={"white"}
                                     title={"Sei sicuro di voler eliminare?"}
                                     onConfirm={() => handleDelete(row.id)}
                                     ><Styled/>
                                     <img style={{width:25}}
                                          className={row.id === getUserId() || row.mainAdmin ? "icon-disabled" : ""}
                                          src={DeleteIcon}/>
                        </Popconfirm>
                    </Col>
                </Row>

            }
        }
    ]

    const handleSearch = (e) => {
        if(e.target.value.length >= 3){
            setCatalogFiltered(catalogs.filter((catalog)=> catalog.name.toLowerCase().includes(e.target.value.toLowerCase())))
        }else{
            setCatalogFiltered(null)
        }
    }

    return(
        <TableStyle style={{marginRight: "20px",padding: "20px"}}>
                <Row gutter={[10, 10]} align={"middle"} style={{alignItems:"center"}}>
                    <Col span={12}>
                        <div style={{color:"white", fontSize:"25px", height: "100%", display: "flex", alignItems: "center", padding: "20px" }}>CATALOGO</div>
                    </Col>
                    <Col span={5}>
                        <InputStyle size={"36px"}><Input placeholder={"Search"} onChange={handleSearch}
                                                         prefix={<img src={SearchIcon}/>}/></InputStyle>
                    </Col>
                    <Col span={7}>
                        <ButtonStyle><Button block onClick={handleAdd}>+ AGGIUNGI CATALOGO</Button></ButtonStyle>
                    </Col>
                    <Col lg={24} sm={24} xs={24}>
                        <Table
                            scroll={{x:true}}
                            rowKey={record => record.id}
                            columns={columns}
                            className='clearTop customTable'
                            dataSource={catalogFiltered ? catalogFiltered : catalogs}
                            pagination={{
                                total: sourceDataTotalResults,
                                pageSizeOptions: ['10', '20', '30', '50', '100'],
                                showSizeChanger: true,
                                defaultPageSize: defaultPageSize
                            }}
                        />
                    </Col>
                </Row>
        </TableStyle>
    )
}
