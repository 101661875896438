import React from 'react';
import Router from './router'
import Boot from './redux/boot'

const App = () => (
    <Router/>
);

Boot().then(() => App())

export default App;
