const actions = {

    GET_ALL: "GET_ALL",
    GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
    GET_ALL_ERROR: "GET_ALL_ERROR",

    GET_BY_ID: "GET_BY_ID",
    GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
    GET_BY_ID_ERROR: "GET_BY_ID_ERROR",

    SAVE_CATALOG: "SAVE_CATALOG",
    SAVE_CATALOG_SUCCESS: "SAVE_CATALOG_SUCCESS",
    SAVE_CATALOG_ERROR: "SAVE_CATALOG_ERROR",

    UPDATE_CATALOG: "UPDATE_CATALOG",
    UPDATE_CATALOG_SUCCESS: "UPDATE_CATALOG_SUCCESS",
    UPDATE_CATALOG_ERROR: "UPDATE_CATALOG_ERROR",

    ENABLE_CATALOG: "ENABLE_CATALOG",
    ENABLE_CATALOG_SUCCESS: "ENABLE_CATALOG_SUCCESS",
    ENABLE_CATALOG_ERROR: "ENABLE_CATALOG_ERROR",

    DISABLE_CATALOG: "DISABLE_CATALOG",
    DISABLE_CATALOG_SUCCESS: "DISABLE_CATALOG_SUCCESS",
    DISABLE_CATALOG_ERROR:"DISABLE_CATALOG_ERROR",

    DELETE_CATALOG: "DELETE_CATALOG",
    DELETE_CATALOG_SUCCESS: "DELETE_CATALOG_SUCCESS",
    DELETE_CATALOG_ERROR: "DELETE_CATALOG_ERROR",

    RESET_SAVE_UPDATE:"RESET_SAVE_UPDATE",

    resetSavedUpdates: ()=>({
       type: actions.RESET_SAVE_UPDATE
    }),

    getAll: ()=>({
        type: actions.GET_ALL
    }),

    getCatalog: (catalogId)=>({
       type: actions.GET_BY_ID,
       id: catalogId
    }),

    saveCatalog: (data)=>({
        type: actions.SAVE_CATALOG,
        newCatalog: data
    }),

    updateCatalog: (data) =>({
       type: actions.UPDATE_CATALOG,
       updatedCatalog: data,
       id: data.id
    }),

    enableCatalog:(catalogId)=>({
        type: actions.ENABLE_CATALOG,
        id: catalogId
    }),

    disableCatalog: (catalogId)=>({
        type: actions.DISABLE_CATALOG,
        id: catalogId
    }),

    deleteCatalog:(catalogId)=>({
        type: actions.DELETE_CATALOG,
        id: catalogId
    })
}

export default actions