import styled from "styled-components";

export const ListStyle = styled.div`
    
    //background: rgb(37,59,96) !important;
    background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%) !important;
    border-radius: 26px !important;
    margin-right: 20px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;

    .ant-row {
        display: flex;
        justify-content: end;
        padding-top: 20px;
    }
   
    .ant-list {
        background-color: transparent !important
    }
    
    p {
        color: white;
        font-size: 25px;
    }
    
    .ant-list .ant-row {
        display: flex !important;
        justify-content: flex-start;
    }
    
    .ant-empty-description{
        color: white;
    }
    
    .ant-pagination-item a{
        color: white;
    }
    
    .ant-pagination-item-active a {
        color: #1890ff;
    }
    
    .ant-pagination-prev .ant-pagination-item-link{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold,sans-serif;
    }
    
    .ant-pagination-item{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-pagination-next .ant-pagination-item-link{
        border-radius: 16px;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-select-selector{
        border-radius: 16px !important;
        border-color: rgb(51, 85, 145) !important;
        border-width: 2px !important;
        background-color: black !important;
        color: white !important;
        font-family: GothamRoundedBold, sans-serif;
    }
    
    .ant-select-arrow{
        color: white;
    }
    
    .ant-popover-arrow-content{
        background-color: white !important;
    }
`
