import React from "react";
import {HomeStyledDivMobile} from "../../../style/mobileStyle/HomeStyledDivMobile";
import {Col, List, Row, Carousel} from "antd";
import artWorkIcon from "../../../svg/artwork.svg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ArtworkCard from "../../../Pages/artWork/artWorkCard";
import authActions from "../../../redux/auth/authActions";
import homeActions from "../../../redux/home/homeActions";
import artWorkActions from "../../../redux/artWork/artWorkActions";
import StatsUser from "../../../Pages/admin/ApexCharts/StatsUser";
import Views from "../../../Pages/admin/ApexCharts/Views";
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";
import moment from "moment";

const {setRoute} = authActions;
const {initializeHomeState, getHomeAdmin} = homeActions
const {deleteArtWork} = artWorkActions;

function HomeAdminMobile() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const format = 'DD-MM-YYYY'

    const lastArtWorks = useSelector(state => state.Home.lastArtWorks)
    const userStatistics = useSelector(state => state.Home.uiUserStatistics)
    const visualizationStatistics = useSelector(state => state.Home.uiVisualizationStatistics)
    const updateList = useSelector(state => state.Home.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        dispatch(getHomeAdmin())
        return ()=>{
            dispatch(initializeHomeState())
        }
    }, [])

    React.useEffect(()=>{
        dispatch(getHomeAdmin())
    },[updateList])

    const onChange = (currentSlide) => {
    };

    const handleEdit = (artWorkId) => {
        navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    function getLastArtWork(){

        let newArray = [];

        if(lastArtWorks && lastArtWorks.length > 0){
            newArray.push(lastArtWorks[0])
            return newArray
        }else{
            return []
        }
    }

    function getCreator() {
        let currentRole = localStorage.getItem("roles")
        let currentUser = localStorage.getItem("username")
        if(lastArtWorks && currentRole === "ROLE_ADMIN"){
            if(currentUser === lastArtWorks[0].creatorUsername){
                return "Te"
            }else{
                return lastArtWorks[0].creatorUsername
            }
        }
    }

    function getDate(date) {
        return moment(date).format(format)
    }

    return(
        <HomeStyledDivMobile>
            <Row>
                <Col>
                    <div className={"add-artwork-card"} onClick={() => navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK)}>
                        <img src={artWorkIcon} style={{height:100, marginTop:10}}/>
                        <p className={"add-artwork-label"}>AGGIUNGI ARTWORK</p>
                    </div>
                    <div className={"last-artwork-card"}>
                        <p className={"last-artwork-label-title"}>Ultimi Artwork</p>
                        <Row style={{display:"flex", justifyContent:"space-between"}}>
                            <Col>
                                {lastArtWorks && lastArtWorks > 0 ?
                                <div style={{marginTop:"20%"}}>
                                    <p className={"folder-label"}>{lastArtWorks[0].title}</p>
                                    <p className={"folder-label"}>{getDate(lastArtWorks[0].creationDataTimestamp)}</p>
                                    <p className={"browse-folder-label"} style={{paddingTop:45}} onClick={() => navigate(PRIVATE_MOBILE_ROUTE.ART_WORK)}>Sfoglia Artwork</p>
                                </div> : null}
                            </Col>
                            <Col>
                                <List
                                    size="default"
                                    dataSource={getLastArtWork()}
                                    renderItem={item => {
                                        return (<ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete} cardHeight={"160px"} cardWidth={"160px"} clean={true}/>)
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <Carousel afterChange={onChange} style={{height:440, width:"100vw", paddingRight:20, paddingLeft:20}}>
                            <div className={"line-chart-container"}>
                                <p className={"line-chart-label"}>Statistiche Utente</p>
                                <StatsUser options={userStatistics}/>
                            </div>
                            <div className={"bar-chart-container"}>
                                <p className={"line-chart-label"} style={{paddingLeft:10}}>Visualizzazioni</p>
                                <Views options={visualizationStatistics}/>
                            </div>
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </HomeStyledDivMobile>
    )
}

export default HomeAdminMobile
