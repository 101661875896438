 import React, {useState} from "react";
 import {InputStyle} from "../../../style/InputStyle";
 import {Button, Col, Input, List, Row} from "antd";
 import SearchIcon from "../../../svg/search.svg";
 import {ButtonStyle} from "../../../style/ButtonStyle";
 import {ArtWorksStyleDivMobile} from "../../../style/mobileStyle/ArtWorksStyleDivMobile";
 import {useDispatch, useSelector} from "react-redux";
 import {useNavigate} from "react-router-dom";
 import folderActions from "../../../redux/folder/folderActions";
 import authActions from "../../../redux/auth/authActions";
 import FolderCardMobile from "./FolderCardMobile";

 const {getAllFolder, initializeFolderState, deleteFolder} = folderActions;
 const {setRoute} = authActions;

function FolderMobile() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const folderList = useSelector(state => state.Folder.folders)
    const [filteredFolderList, setFilteredFolderList] = useState([])
    const [showNewFolder, setShowNewFolder] = useState(false)
    //const folderImages = useSelector(state => state.ArtWork.artWorksImages)
    const updateFolderList = useSelector(state => state.Folder.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if(!folderList || updateFolderList) {
            dispatch(getAllFolder())
        }
        return () => {
            dispatch(initializeFolderState())
        }
    }, [])

    React.useEffect(() => {
        if(folderList){
            let newFolderList =folderList.filter(folder => folder.fakeFolder === false)
            setFilteredFolderList(newFolderList)
        }
    }, [folderList])

    React.useEffect(() => {
        if(updateFolderList){
            dispatch(getAllFolder())
        }
    }, [updateFolderList])


    const handleSearch = (e) => {};

    const handleDelete = (folderId, e) => {
        e.stopPropagation()
        dispatch(deleteFolder(folderId))
    };

    const handleAdd = () => {
        setShowNewFolder(true)
    }

    return(
        <ArtWorksStyleDivMobile>
            <div style={{paddingBottom:20}}>
                <InputStyle size={"36px"}>
                    <Input placeholder={"Search"} onChange={handleSearch} prefix={<img src={SearchIcon}/>}/>
                </InputStyle>
            </div>
            <div style={{paddingBottom:20}}>
                <ButtonStyle>
                    <Button block onClick={handleAdd}>+ AGGIUNGI</Button>
                </ButtonStyle>
            </div>
            <div className={"last-artwork-card"}>
                <Row style={{display:"flex", justifyContent:"center"}}>
                    <Col>
                        <List
                            itemLayout="horizontal"
                            size="default"
                            dataSource={showNewFolder ? folderList ?? [] : filteredFolderList}
                            renderItem={item => {
                                return (<List.Item key={item.folderId}>
                                    <FolderCardMobile item={item} handleDelete={handleDelete} onCreated={() => setShowNewFolder(false)}/>
                                </List.Item>)
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </ArtWorksStyleDivMobile>
    )
}

export default FolderMobile
