import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./homeActions";
import authActions from "../auth/authActions"

export function* getHomeAdmin() {

    yield takeEvery(actions.GET_HOME_ADMIN, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getHomeAdmin, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_HOME_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_HOME_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* getHomeContentCreator() {

    yield takeEvery(actions.GET_HOME_CONTENT_CREATOR, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getHomeContentCreator, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_HOME_CONTENT_CREATOR_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_HOME_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all( [
            fork(getHomeAdmin),
            fork(getHomeContentCreator)
        ]);
}
