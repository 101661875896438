import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Form, Row} from "antd";
import Input from "../../Input";
import {ButtonStyle} from "../../../style/ButtonStyle";
import adminActions from "../../../redux/admin/adminActions";
import contentCreatorActions from "../../../redux/contentCreator/contentCreatorActions";
import authActions from "../../../redux/auth/authActions";

const {setRoute} = authActions
const {getAdminById, updateAdmin, saveAdmin, initializeAdminState} = adminActions
const {getContentCreatorById, updateContentCreator, initializeContentCreatorState, saveContentCreator} = contentCreatorActions

function NewUsers(){

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let showSwitch = false

    let {type, id} = useParams()

    if (!type) {
        type = "admin"
        showSwitch = true
    }


    const admin = useSelector(state => state.Admin.admin)
    const contentCreator = useSelector(state => state.ContentCreator.contentCreator)
    const backToListContentCreator = useSelector(state => state.ContentCreator.backToList)
    const backToListAdmin = useSelector(state => state.Admin.backToList)

    const [adminName, setAdminName] = useState("")
    const [username, setUsername] = useState("")
    const [contentCreatorFirstName, setContentCreatorFirstName] = useState("")
    const [contentCreatorLastName, setContentCreatorLastName] = useState("")
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")

    const handleAdd = (fieldsValue) => {
        if (type === "admin") {
            let data = {
                firstName: fieldsValue['adminName'],
                lastName: fieldsValue['lastName'],
                username: fieldsValue['adminUsername'],
                email: fieldsValue['mail'],
                password: fieldsValue['password'],
                enabled: true,
            }
            if (id) {
                dispatch(updateAdmin(id, data))
            } else {
                dispatch(saveAdmin(data))
            }
        } else if (type === "user") {
            let data = {
                firstName: fieldsValue['firstName'],
                lastName: fieldsValue['lastName'],
                username: fieldsValue['username'],
                email: fieldsValue['email'],
                password: fieldsValue['password'],
                enabled: true,
            }
            if (id) {
                dispatch(updateContentCreator(id, data))
            } else {
                dispatch(saveContentCreator(data))
            }
        }
    }

    const handleCancel = () => {
        navigate("/mobile/manageAdmin")
    }

    const clearState = () => {
        setAdminName("")
        setUsername("")
        setContentCreatorFirstName("")
        setContentCreatorLastName("")
        setMail("")
        setPassword("")
    }

    useEffect(() => {
        clearState()
        if (id) {
            if (type === "admin") {
                dispatch(getAdminById(id))
            } else if (type === "user") {
                dispatch(getContentCreatorById(id))
            }
        }
        return () => {
            dispatch(initializeAdminState())
            dispatch(initializeContentCreatorState())
        }
    }, [])


    useEffect(() => {
        if (admin) {
            setAdminName(admin.firstName)
            setMail(admin.email)
            setUsername(admin.username)
        }
    }, [admin])

    useEffect(() => {
        clearState()
    }, [id, type])

    useEffect(() => {
        if (contentCreator) {
            setContentCreatorFirstName(contentCreator.firstName)
            setContentCreatorLastName(contentCreator.lastName)
            setMail(contentCreator.email)
            setUsername(contentCreator.username)
        }
    }, [contentCreator])

    useEffect(() => {
        if (backToListContentCreator || backToListAdmin) {
            navigate("/mobile/manageAdmin")
        }
    }, [backToListContentCreator, backToListAdmin])

    return(
        <div style={{paddingLeft:20, paddingRight:20}}>
            {type === "admin" ? <Card className="card-container-card" title={ id ? "Modifica Amministratore" : "Nuovo Amministratore"}>
                <Row style={{display:"flex", justifyContent:"center"}}>
                    <Col>
                        <Form
                            name={"register"}
                            onFinish={handleAdd}
                            key={adminName}
                        >
                            <Form.Item
                                name={"adminName"}
                                rules={[{
                                    required: true,
                                    message: "Inserire il nome dell'Admin",
                                    type: "string"
                                }]}
                                style={{overflow: "hidden", textOverflow: "ellipsis"}}
                                initialValue={adminName}
                            >
                                <Input placeholder={"Nome e Cognome"}/>
                            </Form.Item>
                            <Form.Item
                                name={"adminUsername"}
                                rules={[{
                                    required: true,
                                    message: "Inserire l'username dell'Admin",
                                    type: "string"
                                }]}
                                initialValue={username}
                            >
                                <Input placeholder={"Username"}/>
                            </Form.Item>
                            <Form.Item
                                name={"mail"}
                                rules={[{required: true, message: "Formato email non valido", type: "email"}]}
                                initialValue={mail}
                            >
                                <Input placeholder={"Mail"}/>
                            </Form.Item>
                            <Form.Item
                                name={"password"}
                                rules={[{required: !id, message: "Inserire una password"}]}
                                initialValue={password}
                            >
                                <Input type={"password"} placeholder={"Password"}/>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle>
                                    <Button block htmlType="submit">{id ? "MODIFICA" : "AGGIUNGI"}</Button>
                                </ButtonStyle>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle color={"rgb(157,159,163)"}>
                                    <Button block onClick={handleCancel}>CANCELLA</Button>
                                </ButtonStyle>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card> : <Card className="card-container-card" title={ id ? "Modifica Content Creator" : "Nuovo Content Creator"}>
                <Row style={{display:"flex", justifyContent:"center"}}>
                    <Col>
                        <Form
                            name={"register"}
                            onFinish={handleAdd}
                            key={contentCreatorFirstName}
                        >
                            <Form.Item
                                name={"firstName"}
                                rules={[{
                                    required: true,
                                    message: "Inserire il nome del Content Creator",
                                    type: "string"
                                }]}
                                initialValue={contentCreatorFirstName}
                            >
                                <Input placeholder={"Nome"}/>
                            </Form.Item>
                            <Form.Item
                                name={"lastName"}
                                rules={[{
                                    required: true,
                                    message: "Inserire il cognome del Content Creator",
                                    type: "string"
                                }]}
                                initialValue={contentCreatorLastName}
                            >
                                <Input placeholder={"Cognome"}/>
                            </Form.Item>
                            <Form.Item
                                name={"email"}
                                rules={[{required: true, message: "Formato email non valido", type: "email"}]}
                                initialValue={mail}
                            >
                                <Input placeholder={"Mail"}/>
                            </Form.Item>
                            <Form.Item
                                name={"username"}
                                rules={[{
                                    required: true,
                                    message: "Inserire l'username del Content Creator",
                                    type: "string"
                                }]}
                                initialValue={username}
                            >
                                <Input placeholder={"Username"}/>
                            </Form.Item>
                            <Form.Item
                                name={"password"}
                                rules={[{required: !id, message: "Inserire una password"}]}
                                initialValue={password}
                            >
                                <Input type={"password"} placeholder={"Password"}/>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle>
                                    <Button block htmlType="submit">{id ? "MODIFICA" : "AGGIUNGI"}</Button>
                                </ButtonStyle>
                            </Form.Item>
                            <Form.Item>
                                <ButtonStyle color={"rgb(157,159,163)"}>
                                    <Button block onClick={handleCancel}>CANCELLA</Button>
                                </ButtonStyle>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>}
        </div>
    )
}

export default NewUsers
