const actions = {

    INITIALIZE_STATE: "INITIALIZE_STATE",

    GET_ALL_ARTWORKS: "GET_ALL_ARTWORKS",
    GET_ALL_ARTWORKS_SUCCESS: "GET_ALL_ARTWORKS_SUCCESS",
    GET_ALL_ARTWORKS_ERROR: "GET_ALL_ARTWORKS_ERROR",

    GET_ALL_ARTWORKS_BY_FILTERS: "GET_ALL_ARTWORKS_BY_FILTERS",
    GET_ALL_ARTWORKS_BY_FILTERS_SUCCESS: "GET_ALL_ARTWORKS_BY_FILTERS_SUCCESS",
    GET_ALL_ARTWORKS_BY_FILTERS_ERROR: "GET_ALL_ARTWORKS_BY_FILTERS_ERROR",

    GET_ARTWORK_BY_ID: "GET_ARTWORK_BY_ID",
    GET_ARTWORK_BY_ID_SUCCESS: "GET_ARTWORK_BY_ID_SUCCESS",
    GET_ARTWORK_BY_ID_ERROR: "GET_ARTWORK_BY_ID_ERROR",

    SAVE_NEW_ARTWORK: "SAVE_NEW_ARTWORK",
    SAVE_NEW_ARTWORK_SUCCESS: "SAVE_NEW_ARTWORK_SUCCESS",
    SAVE_NEW_ARTWORK_ERROR: "SAVE_NEW_ARTWORK_ERROR",

    UPDATE_ARTWORK: "UPDATE_ARTWORK",
    UPDATE_ARTWORK_SUCCESS: "UPDATE_ARTWORK_SUCCESS",
    UPDATE_ARTWORK_ERROR: "UPDATE_ARTWORK_ERROR",

    ENABLE_ARTWORK: "ENABLE_ARTWORK",
    ENABLE_ARTWORK_SUCCESS: "ENABLE_ARTWORK_SUCCESS",
    ENABLE_ARTWORK_ERROR: "ENABLE_ARTWORK_ERROR",

    DELETE_ARTWORK: "DELETE_ARTWORK",
    DELETE_ARTWORK_SUCCESS: "DELETE_ARTWORK_SUCCESS",
    DELETE_ARTWORK_ERROR: "DELETE_ARTWORK_ERROR",

    DOWNLOAD_MARKER_FILE: "DOWNLOAD_MARKER_FILE",
    DOWNLOAD_MARKER_FILE_SUCCESS: "DOWNLOAD_MARKER_FILE_SUCCESS",
    DOWNLOAD_MARKER_FILE_ERROR: "DOWNLOAD_MARKER_FILE_ERROR",

    DOWNLOAD_ALL_MARKER_FILE: "DOWNLOAD_ALL_MARKER_FILE",

    DOWNLOAD_MEDIA_FILE: "DOWNLOAD_MEDIA_FILE",
    DOWNLOAD_MEDIA_FILE_SUCCESS: "DOWNLOAD_MEDIA_FILE_SUCCESS",
    DOWNLOAD_MEDIA_FILE_ERROR: "DOWNLOAD_MEDIA_FILE_ERROR",

    GET_ALL_FOLDER: "GET_ALL_FOLDER",
    GET_ALL_FOLDER_SUCCESS: "GET_ALL_FOLDER_SUCCESS",

    CHECK_ARTWORK_QUALITY: "CHECK_ARTWORK_QUALITY",
    CHECK_ARTWORK_QUALITY_SUCCESS: "CHECK_ARTWORK_QUALITY_SUCCESS",
    CHECK_ARTWORK_QUALITY_ERROR: "CHECK_ARTWORK_QUALITY_ERROR",

    initializeArtWorkState: () => ({
        type: actions.INITIALIZE_STATE
    }),

    getAllArtWork: () => ({
        type: actions.GET_ALL_ARTWORKS
    }),

    getAllArtWorkByFilters: (folderId) => ({
        type: actions.GET_ALL_ARTWORKS_BY_FILTERS,
        folderId: folderId
    }),

    getArtWorkById: (artWorkId) => ({
        type: actions.GET_ARTWORK_BY_ID,
        artWorkId: artWorkId
    }),

    addNewArtWork: (data) => ({
        type: actions.SAVE_NEW_ARTWORK,
        newArtWork: data
    }),

    updateArtWork: (artWorkId, data) => ({
        type: actions.UPDATE_ARTWORK,
        artWorkId: artWorkId,
        updatedData: data
    }),

    deleteArtWork: (artWorkId) => ({
        type: actions.DELETE_ARTWORK,
        artWorkId: artWorkId
    }),

    downloadMarker: (fileRequest, artworkId) => ({
        type: actions.DOWNLOAD_MARKER_FILE,
        request: fileRequest,
        artworkId: artworkId
    }),

    downloadAllMarker: (artWorks) => ({
        type: actions.DOWNLOAD_ALL_MARKER_FILE,
        artWorks: artWorks
    }),

    downloadMedia: (fileRequest) => ({
        type: actions.DOWNLOAD_MEDIA_FILE,
        request: fileRequest
    }),

    getAllFolder: () => ({
        type: actions.GET_ALL_FOLDER,
    }),

    checkMarkerQuality: (data) => ({
        type: actions.CHECK_ARTWORK_QUALITY,
        payload: data
    })

}

export default actions