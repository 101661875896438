 import React, {useState} from "react";
import {Button, Col, Input, List, Row} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {ListStyle} from "../../style/ListStyle";
import {InputStyle} from "../../style/InputStyle";
import SearchIcon from "../../svg/search.svg";
import folderActions from "../../redux/folder/folderActions";
import authActions from "../../redux/auth/authActions";
import {useNavigate} from "react-router-dom";
import FolderCard from "./folderCard";
import {ButtonStyle} from "../../style/ButtonStyle";

const {getAllFolder, initializeFolderState, deleteFolder} = folderActions;
const {setRoute} = authActions;

export default function Folders() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const folderList = useSelector(state => state.Folder.folders)
    const [filteredFolderList, setFilteredFolderList] = useState([])
    const [showNewFolder, setShowNewFolder] = useState(false)
    //const folderImages = useSelector(state => state.ArtWork.artWorksImages)
    const updateFolderList = useSelector(state => state.Folder.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        if(!folderList || updateFolderList) {
            dispatch(getAllFolder())
        }
        return () => {
            dispatch(initializeFolderState())
        }
    }, [])

    React.useEffect(() => {
        if(folderList){
            let newFolderList =folderList.filter(folder => folder.fakeFolder === false)
            setFilteredFolderList(newFolderList)
        }
    }, [folderList])

    React.useEffect(() => {
        if(updateFolderList){
            dispatch(getAllFolder())
        }
    }, [updateFolderList])


    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            setFilteredFolderList(folderList.filter((folder) => folder.title.toLowerCase().includes(e.target.value.toLowerCase()) && folder.fakeFolder === false))
        }
    };

    const handleDelete = (folderId, e) => {
        e.stopPropagation()
        dispatch(deleteFolder(folderId))
    };

    const handleAdd = () => {
        setShowNewFolder(true)
    }

    return (
        <ListStyle>
            <div>
                <Row gutter={10} style={{paddingBottom: "10px"}}>
                    <Col span={11}>
                        <p>CARTELLE</p>
                    </Col>
                    <Col span={5}>
                        <InputStyle size={"36px"}><Input placeholder={"Search"} onChange={handleSearch}
                                                         prefix={<img src={SearchIcon}/>}/></InputStyle>
                    </Col>
                    <Col span={7}>
                        <ButtonStyle><Button block
                                             onClick={handleAdd}>AGGIUNGI</Button></ButtonStyle>
                    </Col>
                </Row>
                <List
                    itemLayout="horizontal"
                    size="default"
                    grid={{
                        gutter: [50,50],
                        xxl: 3,
                        xl: 2,
                        lg: 1,
                        md: 1,
                        xs: 1,
                    }}
                    pagination={{
                        showSizeChanger: true,
                        onChange: page => {
                        },
                        defaultPageSize: 6,
                    }}
                    dataSource={showNewFolder ? folderList ?? [] : filteredFolderList}
                    renderItem={item => {
                            return (<List.Item key={item.folderId}>
                                <FolderCard item={item} handleDelete={handleDelete} onCreated={() => setShowNewFolder(false)}/>
                            </List.Item>)
                    }}
                />
            </div>
        </ListStyle>
    )
}
