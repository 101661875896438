import styled from "styled-components"

export const ButtonStyle = styled.div`
    .ant-btn{
        height: ${props => props.height ? props.height : "46px"};
        width: ${props => props.expand ? props.expand : "100%"};
        border-radius: 23px;
        background-color: ${props => props.color ? props.color : "#335591"};
        border: ${props => props.border ? props.border : "none"};
        color: ${props => props.textColor ? props.textColor : "white"};
        font-family: GothamRoundedBold,sans-serif;
        font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    }
`
