import actions from "./folderActions";

const initState = {
    folders: null,
    updateList: false,
}

export default function artWorkReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_FOLDER_SUCCESS:
            return {
                ...state,
                folders: action.payload.value,
                updateList: false,
            }
        case actions.DELETE_FOLDER_SUCCESS:
            return {
                ...state,
                updateList: true,
            }
        case actions.UPDATE_FOLDER_SUCCESS:
            return {
                ...state,
                updateList: true,
            }
        case actions.INITIALIZE_FOLDER_STATE:
            return initState
        default:
            return state;
    }
}

