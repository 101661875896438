import {notification} from "antd";
import {Roles} from "./Roles";
import AddIcon from "../svg/add.svg";
import ManageIcon from "../svg/manage.svg";
import UsersIcon from "../svg/users.svg";
import ArtWork from "../svg/artWorks.svg";
import Folder from "../svg/folder.svg";
import HomePageIcon from "../svg/homepage.svg";
import LogoutIcon from "../svg/logout.svg"
import Empty from "../svg/empty.svg"
import Catalog from "../svg/Catalog.svg"
import React from "react";

export function isTokenExpired() {

    let expires_at = parseInt(localStorage.getItem('expires_at'));
    if (!expires_at) {
        expires_at = 0
    }
    let currentTimestamp = new Date().getTime();

    if (currentTimestamp >= expires_at) {
        return true;
    } else {
        return false;
    }
}

export function getWindowSize(){
    return window.innerWidth
}

export function createNotification(type, message) {
    notification[type]({
        message: type,
        description: message,
    });
};

export function getToken() {
    const idToken = localStorage.getItem('id_token');
    return idToken;
}

export function clearToken() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('timestamp');
    localStorage.removeItem('rememberMe');

}

export function getRole() {
    return localStorage.getItem('roles')
}

export function getRouteLandingPage() {
    let width = getWindowSize() > 500


    if(width){
        return getSidebarPages()[0].route
    }else{
        return getSidebarMobilePages()[0].route
    }
}

export function isValidUrl(string) {
    let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}


export function getSidebarPages() {
    const role = getRole()
    switch (role) {
        case Roles.SUPER_ADMIN:
            return [
                {
                    icon: ManageIcon,
                    label: "Gestisci Tenant",
                    route: "/manageTenant"
                },
                {
                    icon: AddIcon,
                    label: "Aggiungi Tenant",
                    route: "/addTenant"
                }
            ]
        case Roles.ADMIN:
            if(localStorage.getItem("hasCatalogEnabled") === 'true'){
                return [
                    {
                        icon: HomePageIcon,
                        label: "Homepage",
                        route: "/adminHome"
                    },
                    {
                        icon: ArtWork,
                        label: "ArtWork",
                        route: "/artWork",
                    },
                    {
                        icon: UsersIcon,
                        label: "Utenti",
                        route: "/manageAdmin"
                    },
                    {
                        icon: Folder,
                        label: "Cartelle",
                        route: "/folder"
                    },
                    {
                        icon: Catalog,
                        label: "Catalogo",
                        route: "/catalog"
                    }
                ]
            }else{
                return [
                    {
                        icon: HomePageIcon,
                        label: "Homepage",
                        route: "/adminHome"
                    },
                    {
                        icon: ArtWork,
                        label: "ArtWork",
                        route: "/artWork",
                    },
                    {
                        icon: UsersIcon,
                        label: "Utenti",
                        route: "/manageAdmin"
                    },
                    {
                        icon: Folder,
                        label: "Cartelle",
                        route: "/folder"
                    }
                ]
            }

        case Roles.CONTENT_CREATOR:
            return [
                {
                    icon: HomePageIcon,
                    label: "Homepage",
                    route: "/contentCreatorHome"
                },
                {
                    icon: ArtWork,
                    label: "ArtWork",
                    route: "/artWork"
                },
                {
                    icon: Folder,
                    label: "Cartelle",
                    route: "/folder"
                }
            ]
    }
}

export function getSidebarMobilePages(){
    const role = getRole()
    switch (role) {
        case Roles.SUPER_ADMIN:
            return [
                {
                    icon: ManageIcon,
                    label: "Gestisci Tenant",
                    route: "/mobile/manageTenant"
                },
                {
                    icon: LogoutIcon,
                    label: "Logout",
                    route: "/"
                }
            ]
        case Roles.ADMIN:
            return [
                {
                    icon: HomePageIcon,
                    label: "Homepage",
                    route: "/mobile/admin/home"
                },
                {
                    icon: ArtWork,
                    label: "ArtWork",
                    route: "/mobile/artWork",
                },
                {
                    icon: Empty
                },
                {
                    icon: UsersIcon,
                    label: "Utenti",
                    route: "/mobile/manageAdmin"
                },
                {
                    icon: Folder,
                    label: "Cartelle",
                    route: "/mobile/folder"
                },
                {
                    icon: Folder,
                    label: "Catalogo",
                    route: "/mobile/catalog"
                }
            ]
        case Roles.CONTENT_CREATOR:
            return [
                {
                    icon: HomePageIcon,
                    label: "Homepage",
                    route: "/mobile/contentCreator/home"
                },
                {
                    icon: ArtWork,
                    label: "ArtWork",
                    route: "/mobile/artWork"
                },
                {
                    icon: Folder,
                    label: "Cartelle",
                    route: "/mobile/folder"
                }
            ]
    }
}


export function getUserId() {
    return localStorage.getItem("id")
}

export function getQualityValue(value) {
    if(value <= 50)
        return <label className={"blinkAnimation"} style={{color: "red"}}>{value}</label>
    if(value > 50 && value < 75)
        return <label className={"blinkAnimation"} style={{color: "yellow"}}>{value}</label>
    if(value >= 75)
        return <label style={{color: "green", fontSize: "20px"}}>{value}</label>
}



