import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import artWorkActions from "../../../redux/artWork/artWorkActions";
import authActions from "../../../redux/auth/authActions";
import ArtworkCard from "../../../Pages/artWork/artWorkCard";
import {Col, List, Row} from "antd";
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";
import artWorkIcon from "../../../svg/artwork.svg";
import {ArtWorkStyleDivMobile} from "../../../style/mobileStyle/ArtWorkStyleDivMobile";
import homeActions from "../../../redux/home/homeActions";

const {setRoute} = authActions;
const {initializeHomeState, getHomeContentCreator} = homeActions
const {deleteArtWork} = artWorkActions;

function HomeContentCreatorMobile(){

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const lastArtWorks = useSelector(state => state.Home.lastArtWorks)
    const updateList = useSelector(state => state.Home.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        dispatch(getHomeContentCreator())
        return ()=>{
            dispatch(initializeHomeState())
        }
    }, [])

    React.useEffect(()=>{
        dispatch(getHomeContentCreator())
    },[updateList])

    const handleEdit = (artWorkId) => {
        navigate("/addArtWork/" + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    function getLastArtWork(){

        let newArray = [];

        if(lastArtWorks){
            newArray.push(lastArtWorks[0])
            return newArray
        }else{
            return []
        }
    }

    return(
        <ArtWorkStyleDivMobile>
            <Row>
                <Col>
                    <div className={"add-artwork-card"} onClick={() => navigate(PRIVATE_MOBILE_ROUTE.ADD_ART_WORK)}>
                        <img src={artWorkIcon} style={{height:100, marginTop:10}}/>
                        <p className={"add-artwork-label"}>AGGIUNGI ARTWORK</p>
                    </div>
                    <div className={"last-artwork-card"}>
                        <Row style={{display:"flex", justifyContent:"center"}}>
                            <Col>
                                <p className={"last-artwork-label-title"}>Ultimo Artwork</p>
                                <List
                                    size="default"
                                    dataSource={getLastArtWork()}
                                    renderItem={item => {
                                        return (<ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete} cardHeight={"320px"} cardWidth={"320px"} clean={true}/>)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className={"browse-folder-label"} style={{paddingTop:30}} onClick={() => navigate(PRIVATE_MOBILE_ROUTE.ART_WORK)}>Sfoglia Artwork</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </ArtWorkStyleDivMobile>
    )
}

export default HomeContentCreatorMobile
