import React, {useState} from "react";
import {Roles} from "../../../helpers/Roles";
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";
import {useDispatch, useSelector} from "react-redux";
import {TopBarStyleMobile} from "../../../style/mobileStyle/TopBarStyleMobile";
import {getWindowSize} from "../../../helpers/utility";
import actions from "../../../redux/auth/authActions";
import LogoutIcon from "../../../svg/logout.svg"

const {logout} = actions

function TopBarMobile() {

    const dispatch = useDispatch()

    const currentRoute = useSelector(state => state.authReducer.currentRoute);

    const [title, setTitle] = useState(null)

    React.useEffect(()=>{
        switch (currentRoute){
            case PRIVATE_MOBILE_ROUTE.MANAGE_TENANT:
                setTitle("Tenant");
                break;
            case PRIVATE_MOBILE_ROUTE.HOME_PAGE_ADMIN:
                setTitle("Home");
                break;
            case PRIVATE_MOBILE_ROUTE.ADD_ART_WORK:
                setTitle("Aggiungi Artwork");
                break;
             case PRIVATE_MOBILE_ROUTE.MODIFY_ART_WORK:
                 setTitle("Aggiungi Artwork")
                break;
            case PRIVATE_MOBILE_ROUTE.MANAGE_ADMIN:
                setTitle("Utenti")
                break;
            case PRIVATE_MOBILE_ROUTE.ART_WORK:
                setTitle("Artworks")
                break;
            case PRIVATE_MOBILE_ROUTE.FOLDER  :
                setTitle("Cartelle")
                break;
            default:
                setTitle("");
                break;
        }
    },[currentRoute])

    function getLogoutIcon() {
        switch (window.location.pathname){
            case PRIVATE_MOBILE_ROUTE.MANAGE_TENANT:
                return null
            case PRIVATE_MOBILE_ROUTE.ADD_TENANT:
                return null
            case PRIVATE_MOBILE_ROUTE.MODIFY_TENANT:
                return null
            default:
                return(
                    <img key={"user"} src={LogoutIcon} className={"icon"} onClick={() => {dispatch(logout())}}/>
                )
        }
    }

    return(
        <TopBarStyleMobile>
            <div style={{display:"flex", justifyContent:"space-between", flexDirection:"row"}}>
                <p className={"title"}>{title}</p>
                {getLogoutIcon()}
            </div>
            {
                localStorage.getItem("old_token") && localStorage.getItem("roles").includes(Roles.ADMIN) && getWindowSize() > 500 &&
                <>
                    <p className={"label-current-tenant"}>Stai operando come Amministratore del Tenant {localStorage.getItem("tenantName")}</p>
                </>
            }
        </TopBarStyleMobile>
    )
}

export default TopBarMobile
