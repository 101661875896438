import actions from "./catalogActions"

const initState = {
    catalogs : null,
    saved: false,
    updateList: false,
    catalog : null,
    updated : false
}
export default function catalogReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL:
            return{
                ...state,
                updateList: false
            }
        case actions.GET_ALL_SUCCESS:
            return{
                ...state,
                catalogs: action.payload
            }
        case actions.SAVE_CATALOG:
            return {
                ...state,
                saved: false
            }
        case actions.SAVE_CATALOG_SUCCESS:
            return {
                ...state,
                saved: true
            }
        case actions.UPDATE_CATALOG:
            return {
                ...state,
                updated: false
            }
        case actions.UPDATE_CATALOG_SUCCESS:
            return{
                ...state,
                updated: true
            }
        case actions.ENABLE_CATALOG:
        case actions.DISABLE_CATALOG:
        case actions.DELETE_CATALOG:
            return{
                ...state,
                updateList: false
            }
        case actions.ENABLE_CATALOG_SUCCESS:
        case actions.DISABLE_CATALOG_SUCCESS:
        case actions.DELETE_CATALOG_SUCCESS:
            return{
                ...state,
                updateList: true
            }
        case actions.GET_BY_ID_SUCCESS:
            return {
                ...state,
                catalog: action.payload
            }
        case actions.RESET_SAVE_UPDATE:
            return{
                ...state,
                updated: false,
                saved: false,
                catalog: null
            }
        default:
            return state;
    }
}