import {isTokenExpired} from "./utility";
import {endpoints} from "../config/site.config";
import {objectToFormData} from '../utils/objectToFormData';
import {randomFileName} from "../utils/stringUtils";
import createNotification from "../helpers/Notification";

var isShowingAlert = false


const customHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const fileHeader = () => ({
    ...localStorage.getItem('id_token') && {Authorization: 'Bearer ' + localStorage.getItem('id_token')},

});

const baseHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
})



const base = (method, url, data = {}, authenticate = false, postRequestWithFormData = false, errorTypesToNotNotify, isFile = false, customHeaderToAdd = null) => {


    let requestConfig = {
        method,
        credentials: 'include'
    };
    if(authenticate) requestConfig.headers = customHeader();
    else requestConfig.headers = baseHeader();
    if(data){
        if(postRequestWithFormData){
            requestConfig.body= objectToFormData(data,{indices: true});
            requestConfig.headers = fileHeader()
        }else{
            requestConfig.body = JSON.stringify(data)
        }

        if(customHeaderToAdd != null){
            requestConfig.headers = {...requestConfig.headers, ...customHeaderToAdd}
        }
    }

    if(authenticate && isTokenExpired()) {

        let configEndpoints = endpoints
        let refreshTokenRequestConfig = {
            method,
            credentials: 'include'
        }
        refreshTokenRequestConfig.headers = baseHeader()
        let endpointToRefresh =  configEndpoints.refreshToken
        refreshTokenRequestConfig.body = JSON.stringify({refreshToken: localStorage.getItem('refresh_token')})


        return fetch(endpointToRefresh, refreshTokenRequestConfig)
            .then(response => response.json())
            .then(json => {
                localStorage.setItem("id_token",json.access_token)
                localStorage.setItem("expires_in", json.expires_at)
                localStorage.setItem("refresh_token", json.refresh_token)

                if(authenticate){
                    requestConfig["headers"]["Authorization"] = 'Bearer ' + json.access_token
                }

                if(json.access_token != null){
                    return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
                } else {
                    return {}
                }
            })
            .catch(error =>  {
                if(!isShowingAlert) {
                    isShowingAlert = true
                    localStorage.clear()
                    alert("La tua sessione è scaduta, riesegui il login");
                }
                window.location.href = window.location.origin + "/"
            })
    } else {
        return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
    }

};

function _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig){
    return fetch(url, requestConfig)
        .then(response => {
            if (!isFile) {
                return handleResponse(response,errorTypesToNotNotify)
            } else {
                var fileName
                try {
                    fileName = response.headers.get('Content-disposition').split(";")[1].split("\"")[1]
                } catch(e) {
                    fileName = randomFileName(10)
                }
                return {
                    response: response,
                    fileName: fileName
                }
            }
        })
        .catch(error => {
            return {
                error: error
            }
        } );
}

async function handleResponse(response, errorTypesToNotNotify) {
    let errorMessage;
    let json;
    if(response.status === 400) {
        json = await response.json();
        if(json) {
            if(json.detailedErrors && json.detailedErrors.length !== 0) {
                json.detailedErrors.forEach(error => {
                    let messageToShow = "Si è verificato il seguente errore sul campo \"" + error.field + "\": " + error.message
                    createNotification("error", messageToShow)
                })
            } else {
                var messageToShow = json.message
                if(json.validationErrors && json.validationErrors.length > 0) {
                    json.validationErrors.forEach(validationError => messageToShow += " - " + validationError.field)
                }
                createNotification("error", messageToShow)
            }
        }
    }

    if (response.status === 401) {
        createNotification("error", "Le credenziali non sono corrette")
    } else if (response && response.status && (response.status !== 200 && response.status !== 206)) {
        json = await response.json();
        if (!json) {
            errorMessage = "An error has occurred. Error code: " + response.status;
            if (response.statusText) {
                errorMessage += "\nError message: " + errorMessage
            }
        } else {
            errorMessage = json.message
        }
    } else {
        if (response.status === 206) {
            json = response;
        } else {
            json = await response.json();

            if (json && json.errorType && json.errorType !== 0) {
                if (json.message) {
                    errorMessage = json.message
                } else {
                    errorMessage = "An error has occurred"
                }
            }
        }
    }


    if (errorMessage) {

        if(json.validationErrors && json.validationErrors.length > 0) {
            json.validationErrors.forEach(validationError => errorMessage += " - " + validationError.field)
        }

        let errors = json && json.value && json.value.errors ? json.value.errors : null
        let errorResponse = {error: errorMessage, value: errors}
        //if an error type is provided by the backend, check if this error type has to be not notified
        if (json && json.errorType) {
            errorResponse.errorType = json.errorType

            if (!(errorTypesToNotNotify && errorTypesToNotNotify.indexOf(json.errorType) !== -1)) {

                if (errors && errors.length > 0) {
                    errors.map(e => createNotification('error', e.message)
                    )
                } else {
                    createNotification('error', errorMessage);
                }
            }
        } else {
            if (errors && errors.length > 0) {
                errors.map(e => createNotification('error', e.message)
                )
            } else {
                createNotification('error', errorMessage);
            }
        }
        return errorResponse
    } else {
        return {response: json}
    }
}

const SuperFetch = {};
['get', 'post', 'put', 'delete', 'PATCH'].forEach(method => {
    SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
