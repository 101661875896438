import React from "react";
import {InputStyle} from "../style/InputStyle";
import {Input} from "antd";

export default function CustomInput(props) {
    const {id, type, placeholder, value, onChange, disabled, defaultValue} = props

    return(
        <InputStyle>
            <Input id={id} type={type} placeholder={placeholder} value={value} defaultValue={defaultValue} onChange={onChange} disabled={disabled}/>
        </InputStyle>
    )
}