import styled from "styled-components"

export const CollapseStyleMobile = styled.div`
    height: 100%;
    width: 100%;
    max-width: 500px;
    overflow: auto;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: hidden;
`
