import styled from "styled-components"

export const InputStyle = styled.div`
    .ant-input{
        height: ${props => props.size ? props.size : "54px"} !important;
        width: ${props => props.expand ? props.expand : "100%"} !important;
        border-radius: ${props => props.borderRadius ? props.borderRadius : "27px"};
        font-family: GothamBook, sans-serif;
        font-size: ${props => props.fontSize ? props.fontSize : "18px"};
        font-weight: ${props => props.fontWeight ? props.fontWeight : "normal"};
    }
    textarea.ant-input{
        resize: none;
    }
    
    .ant-input-affix-wrapper{
        border-radius: ${props => props.borderRadius ? props.borderRadius : "27px"};
    }
    
    .ant-select-selector {
        min-height: 54px !important;
        width: 100% !important;
        border-radius: ${props => props.borderRadius ? props.borderRadius : "27px"} !important;
        font-family: GothamBook !important;
        font-size: 16px !important;
       
    }
    
    .ant-select-selection-item {
        border-radius: ${props => props.borderRadius ? props.borderRadius : "27px"} !important;
       
    }
    width: 100%;
`
