//let apiUrl = "http://localhost:8080"
//let apiUrl = "http://172.20.1.30:8085"
let apiUrl = "https://ntzai.com/api"

const endpoints = {
    getImageResource: apiUrl + "/file/getImageResource",
    login: apiUrl + "/auth/login",
    refreshPassword: apiUrl + "/auth/refreshPassword",
    refreshToken: apiUrl + "/auth/refreshAuth",
    logAsTenant: apiUrl + "/tenant/logAs",
    logBack: apiUrl + "/tenant/exitFromTenant",

    //Home
    getHomeAdmin: apiUrl + "/admin/landingPage",
    getHomeContentCreator: apiUrl + "/contentCreator/landingPage",

    //Tenant
    findAllTenant: apiUrl + "/tenant",
    findTenantById: apiUrl + "/tenant",
    enableTenant: apiUrl + "/tenant/enable",
    saveNewTenant: apiUrl + "/tenant/new",
    deleteTenant: apiUrl + "/tenant",
    updateTenant: apiUrl + "/tenant/update",

    //Admin
    findAllAdmin: apiUrl + "/admin",
    findAdminById: apiUrl + "/admin",
    saveNewAdmin: apiUrl + "/admin" + "/new",
    updateAdmin: apiUrl + "/admin" + "/update",
    enableAdmin: apiUrl + "/admin" + "/enable",
    deleteAdmin: apiUrl + "/admin",

    //Content Creator
    findAllContentCreator: apiUrl + "/contentCreator",
    findContentCreatorById: apiUrl + "/contentCreator",
    saveNewContentCreator: apiUrl + "/contentCreator" + "/new",
    updateContentCreator: apiUrl + "/contentCreator" + "/update",
    enableContentCreator: apiUrl + "/contentCreator" + "/enable",
    deleteContentCreator: apiUrl + "/contentCreator",

    //Art Work
    findAllArtWorks: apiUrl + "/artWork",
    findArtWorkById: apiUrl + "/artWork",
    findArtWorkByFolderId: apiUrl + "/artWork/findBy",
    findArtWorkByTitle: apiUrl + "/artWork/findBy",
    saveNewArtWork: apiUrl + "/artWork/new",
    updateArtWork: apiUrl + "/artWork/update",
    deleteArtWork: apiUrl + "/artWork",
    incrementVisualizations: apiUrl + "/artWork/incVisualizations",
    downloadFile: apiUrl + "/file/getImageResource",
    checkMarkerQuality: apiUrl + "/artWork/checkMarkerQuality",

    //Folder
    findAllFolder: apiUrl + "/folder",
    deleteFolder: apiUrl + "/folder",
    updateFolder: apiUrl + "/folder/update",

    //Catalog
    getAllCatalog: apiUrl +"/catalog",
    getCataloById: apiUrl + "/catalog",
    saveCatalog: apiUrl +"/catalog",
    enableCatalog: apiUrl +"/catalog" + "/enable",
    disableCatalog: apiUrl +"/catalog" + "/disable",
    deleteCatalog: apiUrl +"/catalog"
}

export {endpoints}
