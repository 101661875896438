import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./adminActions";
import authActions from "../auth/authActions"

export function* getAllAdmins() {

    yield takeEvery(actions.GET_ALL_ADMIN, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAllAdmin, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* getAdminById() {

    yield takeEvery(actions.GET_ADMIN_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAdminById + "/" + data.adminId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ADMIN_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ADMIN_BY_ID_ERROR,
                payload: error
            });
        }
    })
}

export function* saveNewAdmin() {

    yield takeEvery(actions.SAVE_NEW_ADMIN, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveNewAdmin, data.newAdmin, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_NEW_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* updateAdmin() {

    yield takeEvery(actions.UPDATE_ADMIN, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateAdmin + "/" + data.adminId, data.updatedData, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.UPDATE_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteAdmin() {

    yield takeEvery(actions.DELETE_ADMIN, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteAdmin + "/" + data.adminId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* enableAdmin() {
    yield takeEvery(actions.ENABLE_ADMIN, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.PATCH, endpoints.enableAdmin + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_ADMIN_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_ADMIN_ERROR,
                payload: error
            });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getAllAdmins),
        fork(getAdminById),
        fork(saveNewAdmin),
        fork(updateAdmin),
        fork(deleteAdmin),
        fork(enableAdmin)
    ]);
}

