import styled from "styled-components"

export const AddTenantStyle = styled.div`
    padding-bottom: 30px;
    
    .form-header{
        height: 60px;
        width: 100%;
        color: white;
        font-family: GothamBlack,sans-serif;
        font-size: 18px;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(101,113,134);
        background: linear-gradient(0deg, rgba(101,113,134,0.22) 25%, rgba(51,85,145,0.62) 100%);
    }
    
    .form-content{
        //padding: 70px 115px;
        border-bottom: 1px solid rgb(255,255,255,0.20);
        border-right: 1px solid rgb(255,255,255,0.20);
        border-left: 1px solid rgb(255,255,255,0.20);
        border-top: 2px solid white;
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
        background: rgb(255,250,250);
        background: linear-gradient(315deg, rgba(255,250,250,0.16) 0%, rgba(255,252,252,0.11) 70%, rgba(255,255,255,0.015) 100%);
    }
    
    .form-input{
      margin: 100px 50px 50px 50px !important;;
    }
    
    .action-container{
        margin-top: 100px;
    }
    
    .ant-form-item-explain{
        font-family: GothamBook, sans-serif;
        padding-left: 26px;
    }
    
    .ant-input-status-error{
        border-width: 2px;
    }
    
`
