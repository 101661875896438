import React, {useEffect, useState} from "react";
import {AddCatalogStyle} from "../../style/AddCatalogStyle";
import {Button, Col, Form, Image, Input, Row, Typography, notification, Tooltip, Checkbox} from "antd";
import {InputStyle} from "../../style/InputStyle";
import Table from "antd/es/table";
import {useDispatch, useSelector} from "react-redux";
import artWorkActions from "../../redux/artWork/artWorkActions";
import catalogActions from "../../redux/catalog/catalogActions";
import {useNavigate, useParams} from "react-router-dom";
import {TableStyle} from "../../style/TableStyle";
import {ButtonStyle} from "../../style/ButtonStyle";
import CatalogImage from "./catalogImage";

const {Text} = Typography;
const {getAllArtWork} = artWorkActions;
const {saveCatalog, getCatalog, updateCatalog,resetSavedUpdates} = catalogActions;

export default function AddCatalog() {

    let {type, id} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [form] = Form.useForm();

    const artWorks = useSelector(state => state.ArtWork.artWorks)
    const saved = useSelector(state => state.Catalog.saved)
    const catalog = useSelector(state => state.Catalog.catalog)
    const updated = useSelector(state => state.Catalog.updated)

    const [defaultPageSize, setDefaultPageSize] = useState(10)
    const [sourceDataTotalResults, setSoutceDataTotalResults] = useState(0)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        dispatch(getAllArtWork())
        if (id) {
            dispatch(getCatalog(id))
        }
        console.log(selectedRowKeys)
        return ()=> {
            dispatch(resetSavedUpdates())
        }
    }, [])

    useEffect(() => {
        if (saved || updated) {
            navigate(-1)
        }
    }, [saved, updated])

    useEffect(() => {
        if (catalog) {
            form.setFieldsValue({
                name: catalog.name
            })
            setSelectedRowKeys(catalog.artworkIds)
        }
    }, [catalog])

    const columns = [
        {
            title: "NOME",
            key: "title",
            dataIndex: "title",
            align: "center",
            width: "30%",
            render: (text) => {
                return <Text className={"styleBlack"}>{text}</Text>
            }
        },
        {
            title: "DESCRIZIONE",
            key: "description",
            dataIndex: "description",
            width: "30%",
            ellipsis: true,
            render: (description) => <Text style={{color: "white"}}>{description}</Text>
        },
        {
            title: "MEDIA",
            key: "media",
            dataIndex: "markerUrl",
            width: "40%",
            render: (markerUrl) => {
                return <CatalogImage key={markerUrl} media={markerUrl}/>
            }
        }
    ]

    const handleAdd = (fieldValue) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys.length <= 50) {
            if (!id) {
                let data = {
                    "name": fieldValue["name"],
                    "artworkIds": selectedRowKeys,
                    "enabled": false
                }
                dispatch(saveCatalog(data))
            } else {
                let data = {
                    "id": id,
                    "name": fieldValue["name"],
                    "artworkIds": selectedRowKeys,
                    "enabled": catalog.enabled
                }
                dispatch(updateCatalog(data))
            }
        } else {
            openErrorNotification()
        }
    }

    const openErrorNotification = () => {
        notification.open({
            type: "error",
            message: 'Errore Salvataggio Catalogo',
            description: selectedRowKeys.length === 0 ? 'Impossibile salvare, nessun ArtWork selezionato!' : 'Impossibile salvare, è stato superato il numero massimo di 50 elementi selezionabili!'
        });
    }

    const openInfoNotification = () => {
        notification.open({
            type: "warning",
            message: 'Attenzione',
            description: 'Non è possibile selezionare più di 50 elementi per il catologo corrente.'
        });
    }

    const onCancel = () => {
        navigate(-1)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        if (selectedRowKeys && selectedRowKeys.length === 50) {
            openInfoNotification();
            newSelectedRowKeys.checked = false;
        } else setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (row) => {
            return {
                disabled: selectedRowKeys && selectedRowKeys.length >= 50 && !selectedRowKeys.includes(row.artWorkId)
            }
        },
        columnWidth: "150px",
        preserveSelectedRowKeys: true,
        columnTitle: () => {
            return artWorks && <Row>
                <Checkbox
                    style={{marginRight: 10}}
                    indeterminate={selectedRowKeys && selectedRowKeys.length !== 0 && selectedRowKeys.length < artWorks.length }
                    checked={selectedRowKeys && selectedRowKeys.length === artWorks.length}
                    onChange={(e) => {
                        if (e.target.checked === false) {
                            onSelectChange([])
                        } else {
                            onSelectChange(artWorks.map(it => it.artWorkId))
                        }
                    }}/>
                <Text>{selectedRowKeys ? selectedRowKeys.length : 0}/{artWorks.length >= 50 ? "50" : artWorks.length}</Text>
            </Row>
        }
    };

    return (
        <AddCatalogStyle>
            <div className={"form-content"}>
                <Form
                    form={form}
                    name={"registerCatalog"}
                    onFinish={handleAdd}
                    key={"formCatalog"}
                >
                    <InputStyle borderRadius={"17px"}>
                        <Form.Item
                            name={"name"}
                            key={"name"}
                            rules={[{
                                required: true,
                                message: "Nome: il campo è obbligatorio",
                                type: "string",
                            }]}
                        >
                            <Input placeholder={"Nome Catalogo"}/>
                        </Form.Item>
                        <Form.Item
                            name={"artworkIds"}
                            key={"artWorks"}
                            // rules={[{
                            //     required: true
                            // }]}
                        >
                            <TableStyle>
                                <Table
                                    width={"100%"}
                                    rowKey={record => record.artWorkId}
                                    columns={columns}
                                    className="clearTop customTable"
                                    dataSource={artWorks ? artWorks : []}
                                    rowSelection={artWorks ? rowSelection : null}
                                    pagination={{
                                        total: sourceDataTotalResults,
                                        pageSizeOptions: ['10', '20', '30', '50', '100'],
                                        showSizeChanger: true,
                                        defaultPageSize: defaultPageSize
                                    }}
                                />
                            </TableStyle>

                        </Form.Item>
                    </InputStyle>
                    <Row style={{marginTop: "2%"}} gutter={[10, 10]} align={"middle"}>
                        <Col lg={12} sm={24} xs={24} style={{display: "flex", justifyContent: "center"}}>
                            <ButtonStyle color={"black"} border={"2px solid #335591"}><Button
                                style={{width: "250px"}} onClick={onCancel}>CANCELLA</Button></ButtonStyle>
                        </Col>
                        <Col lg={12} sm={24} style={{display: "flex", justifyContent: "center"}}>
                            <ButtonStyle><Button style={{width: "250px"}}
                                                 htmlType={"submit"}>{id ? "SALVA" : "AGGIUNGI"}</Button></ButtonStyle>
                        </Col>
                    </Row>
                </Form>
            </div>
        </AddCatalogStyle>
    )
}
