import actions from "./adminActions";

const initState = {
    admins: null,
    admin: null,
    updateList: false,
    updatePage: true,
    backToList: false
}

export default function adminReducer(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_ADMIN_STATE:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_ADMIN_SUCCESS:
            return {
                ...state,
                admins: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_ADMIN_BY_ID_SUCCESS:
            return {
                ...state,
                admin: action.payload.value,
                updatePage: true
            };
        case actions.SAVE_NEW_ADMIN_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                updateList: true
            };
        case actions.ENABLE_ADMIN_SUCCESS:
            return {
                ...state,
                updateList: true
            };
        default:
            return state;
    }

}