import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getSidebarMobilePages} from "../../../helpers/utility";
import {SidebarStyleMobile} from "../../../style/mobileStyle/SidebarStyleMobile";
import actions from "../../../redux/auth/authActions";
import AddIconBlack from "../../../svg/addBlack.svg";
import AddIconWhite from "../../../svg/addWhite.svg"
import OtherBlack from "../../../svg/otherBlack.svg"
import OtherWhite from "../../../svg/otherWhite.svg"
import {PRIVATE_MOBILE_ROUTE} from "../../../route.constants";

const {setRoute, logout} = actions

function SideBarMobile() {
        const navigate = useNavigate()
        const dispatch = useDispatch()

        const location = useLocation()
        const currentRoute = location.pathname

        const handleClick = (index, route) => {
            dispatch(setRoute(route))
            navigate(route)
        }

        const [bgColor, setBgColor] = useState(false)
        const [icon, setIcon] = useState(false)
        const [type, setType] = useState(null)

        React.useEffect(()=>{
            switch (currentRoute) {
                case PRIVATE_MOBILE_ROUTE.ADD_TENANT:
                    setBgColor(true);
                    setIcon(true);
                    setType("Tenant")
                    break;
                case PRIVATE_MOBILE_ROUTE.MANAGE_TENANT:
                    setBgColor(false);
                    setIcon(false);
                    setType("Tenant")
                    break;
                case PRIVATE_MOBILE_ROUTE.ADD_ART_WORK:
                    setBgColor(true);
                    setIcon(true);
                    setType(null)
                    break;
                case PRIVATE_MOBILE_ROUTE.ADD_DEFAULT_ADMIN:
                    setBgColor(false)
                    setIcon(true)
                    setType(null)
                default:
                    setBgColor(false)
                    setIcon(false);
                    break;
            }
        },[currentRoute])


        function setImage(){
            if(type === "Tenant"){
                return icon ? AddIconWhite : AddIconBlack
            }else{
                return icon ? OtherWhite : OtherBlack
            }
        }

        return(
        <SidebarStyleMobile>
            <div>
                <div className={"sidebar-content"}>
                    <div style={{position:"relative", display:"flex", justifyContent:"center"}}>
                        <div className={"icon-container-circle"} />
                        <div className={bgColor ? "icon-container-circle-central color": "icon-container-circle-central white"}>
                            <img src={setImage()} style={{position:"absolute", top:"50%", left:"50%", transform: "translate(-50%,-50%)"}}/>
                        </div>
                    </div>
                    <div className={"icon-container"}>
                        {getSidebarMobilePages().map((page, index) => {
                            return(
                                <img key={"icon" + index} src={page.icon} className={"page-icon"} onClick={() => {
                                    if(page.route === "/"){
                                        dispatch(logout())
                                    }else{
                                        handleClick(index, page.route)
                                    }
                                }}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </SidebarStyleMobile>
    )
}

export default SideBarMobile
