import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./contentCreatorActions";
import authActions from "../auth/authActions";

export function* getAllContentCreators() {

    yield takeEvery(actions.GET_ALL_CONTENT_CREATOR, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAllContentCreator, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_CONTENT_CREATOR_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export function* getContentCreatorById() {

    yield takeEvery(actions.GET_CONTENT_CREATOR_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findContentCreatorById + "/" + data.contentCreatorId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_CONTENT_CREATOR_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_CONTENT_CREATOR_BY_ID_ERROR,
                payload: error
            });
        }
    })
}

export function* saveNewContentCreator() {

    yield takeEvery(actions.SAVE_NEW_CONTENT_CREATOR, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveNewContentCreator, data.newContentCreator, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_NEW_CONTENT_CREATOR_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export function* updateContentCreator() {

    yield takeEvery(actions.UPDATE_CONTENT_CREATOR, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateContentCreator + "/" + data.contentCreatorId, data.updatedData, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_CONTENT_CREATOR_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteContentCreator() {

    yield takeEvery(actions.DELETE_CONTENT_CREATOR, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteContentCreator + "/" + data.contentCreatorId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_CONTENT_CREATOR_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export function* enableContentCreator() {
    yield takeEvery(actions.ENABLE_CONTENT_CREATOR, function* (data) {
        yield put({type: authActions.LOADING_TRUE});

        const {response, error} = yield call(SuperFetch.PATCH, endpoints.enableContentCreator + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_CONTENT_CREATOR_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_CONTENT_CREATOR_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllContentCreators),
        fork(getContentCreatorById),
        fork(saveNewContentCreator),
        fork(updateContentCreator),
        fork(deleteContentCreator),
        fork(enableContentCreator)
    ]);
}

