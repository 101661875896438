import React from "react";
import Sidebar from "../component/sidebar";
import {Outlet} from "react-router-dom"
import {HomeStyle} from "../style/HomeStyle";
import Topbar from "../component/topbar";
import {Spin} from "antd";
import {useSelector} from "react-redux";

export default function Home() {

    const loading = useSelector(state => state.authReducer.loading)

    return (
            <HomeStyle>
                <div>
                    <Sidebar/>
                    <Topbar/>
                    <div className={"content-container"}>
                        <div className={"content"}>
                            <Spin spinning={loading} size={"large"}>
                                <Outlet/>
                            </Spin>
                        </div>
                    </div>
                </div>}
            </HomeStyle>
    )

}
