import React from "react";
import {Outlet} from "react-router-dom"
import {Spin} from "antd";
import {useSelector} from "react-redux";
import {HomeStyleMobile} from "../../style/mobileStyle/HomeStyle";
import SideBarMobile from "./component/SidebarMobile";
import TopBarMobile from "./component/TopBarMobile";

function HomeMobile() {

    const loading = useSelector(state => state.authReducer.loading)

    return (
        <HomeStyleMobile>
            <TopBarMobile/>
            <div className={"content-container"}>
                <Spin spinning={loading} size={"large"} wrapperClassName={"spin-wrapper"}>
                    <div className={"content"}>
                        <Outlet/>
                    </div>
                </Spin>
            </div>
            <SideBarMobile/>
        </HomeStyleMobile>
    )

}

export default HomeMobile

