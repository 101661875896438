import React, {useEffect, useRef, useState} from "react";
import {endpoints} from "../../config/site.config";
import {getToken} from "../../helpers/utility";
import {Image} from "antd";

export default function CatalogImage(props) {
    const item = props.media
    const [image, setImage] = useState("")
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
        if(item){
            setLoading(true)
            let requestBody = {name: item, width: 400, height: 400, fill: true}
            fetch(endpoints.getImageResource, {
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    }),
                    body: JSON.stringify(requestBody)
                }
            ).then(result => result.blob()).then(blob => {
                setImage(URL.createObjectURL(blob))
                setLoading(false)
            })
        }
    },[])

    return (
        <>
            <Image
                width={100}
                src={image}
            />
        </>
    )
}