import styled from "styled-components";
export const AddCatalogStyle = styled.div`
    background: rgba(255, 255, 255, 0.05) !important;
    border-radius: 26px !important;
    margin-right: 20px !important;
    padding: 20px !important;
    
    .ant-select-selector{
      min-height: 100% !important;
    }
    .ant-table-selection-column{
      padding-left: 20px;
    }
    
    .ant-table-tbody>tr.ant-table-row-selected{
        background: rgb(255,250,250) !important;
        background: linear-gradient(315deg, rgba(255,250,250,0.16) 0%, rgba(255,252,252,0.11) 70%, rgba(255,255,255,0.015) 100%) !important;
    }
    .ant-table-tbody>tr.ant-table-row-selected>td{
        background: none !important;
    }
`
//    background-color: rgb(51, 85, 145);
