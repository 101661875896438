import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "antd";
import Input from "../../component/Input";
import {ButtonStyle} from "../../style/ButtonStyle";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import authActions from "../../redux/auth/authActions"
import adminActions from "../../redux/admin/adminActions";
import contentCreatorActions from "../../redux/contentCreator/contentCreatorActions";
import {AddUserStyle} from "../../style/AddUserStyle";

const {setRoute} = authActions
const {getAdminById, updateAdmin, saveAdmin, initializeAdminState} = adminActions
const {getContentCreatorById, updateContentCreator, initializeContentCreatorState, saveContentCreator} = contentCreatorActions

export default function AddTenant() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let showSwitch = false

    let {type, id} = useParams()

    if (!type) {
        type = "admin"
        showSwitch = true
    }


    const admin = useSelector(state => state.Admin.admin)
    const contentCreator = useSelector(state => state.ContentCreator.contentCreator)
    const backToListContentCreator = useSelector(state => state.ContentCreator.backToList)
    const backToListAdmin = useSelector(state => state.Admin.backToList)

    const [adminName, setAdminName] = useState("")
    const [username, setUsername] = useState("")
    const [contentCreatorFirstName, setContentCreatorFirstName] = useState("")
    const [contentCreatorLastName, setContentCreatorLastName] = useState("")
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")


    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "adminName":
                setAdminName(e.target.value)
                break;
            case "adminUsername":
                setUsername(e.target.value)
                break;
            case "username":
                setUsername(e.target.value)
                break;
            case "mail":
                setMail(e.target.value)
                break;
            case "password":
                setPassword(e.target.value)
                break;
            case "firstName":
                setContentCreatorFirstName(e.target.value)
                break;
            case "lastName":
                setContentCreatorLastName(e.target.value)
                break;
        }
    }

    const handleAdd = (fieldsValue) => {
        if (type === "admin") {
            let data = {
                firstName: fieldsValue['adminName'],
                lastName: fieldsValue['lastName'],
                username: fieldsValue['adminUsername'],
                email: fieldsValue['mail'],
                password: fieldsValue['password'],
                enabled: true,
            }
            if (id) {
                dispatch(updateAdmin(id, data))
            } else {
                dispatch(saveAdmin(data))
            }
        } else if (type === "user") {
            let data = {
                firstName: fieldsValue['firstName'],
                lastName: fieldsValue['lastName'],
                username: fieldsValue['username'],
                email: fieldsValue['email'],
                password: fieldsValue['password'],
                enabled: true,
            }
            if (id) {
                dispatch(updateContentCreator(id, data))
            } else {
                dispatch(saveContentCreator(data))
            }
        }
    }

    const handleCancel = () => {
        navigate("/manageAdmin")
    }

    const clearState = () => {
        setAdminName("")
        setUsername("")
        setContentCreatorFirstName("")
        setContentCreatorLastName("")
        setMail("")
        setPassword("")
    }

    useEffect(() => {
        clearState()
        if (id) {
            if (type === "admin") {
                dispatch(getAdminById(id))
            } else if (type === "user") {
                dispatch(getContentCreatorById(id))
            }
        }
        return () => {
            dispatch(initializeAdminState())
            dispatch(initializeContentCreatorState())
        }
    }, [])


    useEffect(() => {
        if (admin) {
            setAdminName(admin.firstName)
            setMail(admin.email)
            setUsername(admin.username)
        }
    }, [admin])

    useEffect(() => {
        clearState()
    }, [id, type])

    useEffect(() => {
        if (contentCreator) {
            setContentCreatorFirstName(contentCreator.firstName)
            setContentCreatorLastName(contentCreator.lastName)
            setMail(contentCreator.email)
            setUsername(contentCreator.username)
        }
    }, [contentCreator])

    useEffect(() => {
        if (backToListContentCreator || backToListAdmin) {
            navigate("/manageAdmin")
        }
    }, [backToListContentCreator, backToListAdmin])


    return (
        <AddUserStyle>
            {type === "admin" ? <Row gutter={[0, 10]}>
                {showSwitch &&
                <Col span={8} offset={12}>
                    <ButtonStyle><Button block onClick={() => navigate("/home/addOrModify/user")}>AGGIUNGI CONTENT
                        CREATOR</Button></ButtonStyle>
                </Col>
                }
                <Col span={16} offset={4}>
                    <div className={"form-header"}>
                        <span>{id ? "Modifica Amministratore" : "Nuovo Amministratore"}</span>
                    </div>
                    <div className={"form-content"}>
                        <Form
                            name={"register"}
                            onFinish={handleAdd}
                            key={adminName}
                        >
                            <Row gutter={[30, 60]} style={{margin:50}}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"adminName"}
                                        rules={[{
                                            required: true,
                                            message: "Inserire il nome dell'Admin",
                                            type: "string"
                                        }]}
                                        initialValue={adminName}
                                    >
                                        <Input placeholder={"Nome e Cognome"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"adminUsername"}
                                        rules={[{
                                            required: true,
                                            message: "Inserire l'username dell'Admin",
                                            type: "string"
                                        }]}
                                        initialValue={username}
                                    >
                                        <Input placeholder={"Username"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"mail"}
                                        rules={[{required: true, message: "Formato email non valido", type: "email"}]}
                                        initialValue={mail}
                                    >
                                        <Input placeholder={"Mail"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"password"}
                                        rules={[{required: id ? false : true, message: "Inserire una password"}]}
                                        initialValue={password}
                                    >
                                        <Input type={"password"} placeholder={"Password"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[30]} className={"action-container"}>
                                <Col span={12}>
                                    <ButtonStyle color={"rgb(157,159,163)"}><Button block
                                                                                    onClick={handleCancel}>CANCELLA</Button></ButtonStyle>
                                </Col>
                                <Col span={12}>
                                    <ButtonStyle><Button block
                                                         htmlType={"submit"}>{id ? "MODIFICA" : "AGGIUNGI"}</Button></ButtonStyle>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row> : <Row>
                <Col span={16} offset={4}>
                    <div className={"form-header"}>
                        <span>{id ? "Modifica Content Creator" : "Nuovo Content Creator"}</span>
                    </div>
                    <div className={"form-content"}>
                        <Form
                            name={"register"}
                            onFinish={handleAdd}
                            key={contentCreatorFirstName}
                        >
                            <Row gutter={[30, 60]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"firstName"}
                                        rules={[{
                                            required: true,
                                            message: "Inserire il nome del Content Creator",
                                            type: "string"
                                        }]}
                                        initialValue={contentCreatorFirstName}
                                    >
                                        <Input placeholder={"Nome"}/>
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"lastName"}
                                        rules={[{
                                            required: true,
                                            message: "Inserire il cognome del Content Creator",
                                            type: "string"
                                        }]}
                                        initialValue={contentCreatorLastName}
                                    >
                                        <Input placeholder={"Cognome"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"email"}
                                        rules={[{required: true, message: "Formato email non valido", type: "email"}]}
                                        initialValue={mail}
                                    >
                                        <Input placeholder={"Mail"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"username"}
                                        rules={[{
                                            required: true,
                                            message: "Inserire l'username del Content Creator",
                                            type: "string"
                                        }]}
                                        initialValue={username}
                                    >
                                        <Input placeholder={"Username"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} offset={6}>
                                    <Form.Item
                                        name={"password"}
                                        rules={[{required: id ? false : true, message: "Inserire una password"}]}
                                        initialValue={password}
                                    >
                                        <Input type={"password"} placeholder={"Password"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[30]} className={"action-container"}>
                                <Col span={12}>
                                    <ButtonStyle color={"rgb(157,159,163)"}><Button block
                                                                                    onClick={handleCancel}>CANCELLA</Button></ButtonStyle>
                                </Col>
                                <Col span={12}>
                                    <ButtonStyle><Button block
                                                         htmlType={"submit"}>{id ? "MODIFICA" : "AGGIUNGI"}</Button></ButtonStyle>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>}
        </AddUserStyle>
    )
}
