import styled from "styled-components";

export const FolderStyleMobile = styled.div`

    display: flex;
    justify-content: center;
    
    .card-container{
      cursor: pointer;
      width: 60vw;
      max-width: 250px;
      height: 250px;
      border-radius: 26px;
      background-color: #D5D5D5;
      padding: 16px 32px;
      position: relative;
    }
    
    .image-container{
        border-radius: 20px;
        background-color: white;
        height: 65px;
        max-width: 65px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }
    
    .flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .folder-info{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 150px;
        width: 100%;
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(208,208,208,0.6337885495995272) 58%, rgba(128,128,128,0) 100%);
    }
    
    .flex-left{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .flex-right{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: flex-end;
        height: 100%;
        padding-right: 15px;
        padding-bottom: 15px;
    }
    
    .ant-spin-nested-loading{
        width: 100%;
        max-width: 400px;
    }
    
    
`
