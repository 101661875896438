export const PUBLIC_ROUTE ={
    LANDING: '/',
    SIGN_IN: '/signIn'
}

export const PRIVATE_ROUTE = {
    MANAGE_TENANT: "/manageTenant",
    ADD_TENANT: "/addTenant",
    MODIFY_TENANT: "/addTenant/:id",
    ADD_ADMIN_USER: "/addOrModify/:type",
    MODIFY_ADMIN_USERS: "/addOrModify/:type/:id",
    ADD_DEFAULT_ADMIN: "/addOrModify",
    MANAGE_ADMIN: "/manageAdmin",
    HOME_PAGE_ADMIN: "/adminHome",
    HOME_PAGE_CONTENT_CREATOR: "/contentCreatorHome",
    ART_WORK: "/artWork",
    FOLDER_ART_WORK: "/artWork/:id",
    ADD_ART_WORK: "/addArtWork",
    MODIFY_ART_WORK: "/addArtWork/:id",
    FOLDER: "/folder",

    //modifiche
    CATALOG: "/catalog",
    ADD_CATALOG: "/addOrModifyCatalog",
    MODIFY_CATALOG: "/addOrModifyCatalog/:id",

    TESTPATH: "/testPath"
}

export const PRIVATE_MOBILE_ROUTE = {
    MANAGE_TENANT: "/mobile/manageTenant",
    ADD_TENANT: "/mobile/addTenant",
    MODIFY_TENANT: "/mobile/addTenant/:id",
    ADD_ADMIN_USER: "/mobile/addOrModify/:type",
    MODIFY_ADMIN_USERS: "/mobile/addOrModify/:type/:id",
    ADD_DEFAULT_ADMIN: "/mobile/addOrModify",
    MANAGE_ADMIN: "/mobile/manageAdmin",
    HOME_PAGE_ADMIN: "/mobile/admin/home",
    HOME_PAGE_CONTENT_CREATOR: "/mobile/contentCreator/home",
    ART_WORK: "/mobile/artWork",
    FOLDER_ART_WORK: "/mobile/artWork/:id",
    ADD_ART_WORK: "/mobile/addArtWork",
    MODIFY_ART_WORK: "/mobile/addArtWork/:id",
    FOLDER: "/mobile/folder"
}
