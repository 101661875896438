import React from "react";
import {Col, List, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../redux/auth/authActions"
import homeActions from "../../redux/home/homeActions"
import artWorkActions from "../../redux/artWork/artWorkActions";
import {useNavigate} from "react-router-dom";
import artWorkIcon from "../../svg/artwork.svg";


import ArtworkCard from "../artWork/artWorkCard";
import {getRole} from "../../helpers/utility";
import {HomeStyledDiv} from "../admin/homeStyledDiv";


const {setRoute} = authActions;
const {initializeHomeState, getHomeContentCreator} = homeActions
const {deleteArtWork} = artWorkActions;

const theme = {
    backgroundImage: "https://static2-viaggi.corriereobjects.it/wp-content/uploads/2019/10/iStock-905351688.jpg"
}

export default function HomePageContentCreator() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const lastArtWorks = useSelector(state => state.Home.lastArtWorks)
    const updateList = useSelector(state => state.Home.updateList)

    React.useEffect(() => {
        dispatch(setRoute(window.location.pathname))
        dispatch(getHomeContentCreator())
        return ()=>{
            dispatch(initializeHomeState())
        }
    }, [])

    React.useEffect(()=>{
        dispatch(getHomeContentCreator())
    },[updateList])

    const handleEdit = (artWorkId) => {
        navigate("/addArtWork/" + artWorkId)
    };

    const handleDelete = (artWorkId) => {
        dispatch(deleteArtWork(artWorkId))
    };

    return (
        <HomeStyledDiv>
            <Row gutter={[60,60]}>
                <Col span={10}>
                    <div className={"add-artwork-card"} onClick={() => navigate("/addArtwork")}>
                        <img src={artWorkIcon}/>
                        <p className={"add-artwork-label"}>AGGIUNGI ARTWORK</p>
                    </div>
                </Col>
                <Col span={14}>
                    <div className={"last-artwork-card"}>
                        <p className={"last-artwork-label"}>Ultimi Artwork</p>
                        <List
                            itemLayout="horizontal"
                            size="default"
                            grid={{
                                gutter: [50,50],
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 2,
                                xs: 2,
                            }}
                            dataSource={lastArtWorks ?? []}
                            renderItem={item => {
                                return (<List.Item key={item.id}>
                                    <ArtworkCard item={item} handleEdit={handleEdit} handleDelete={handleDelete} cardHeight={"260px"} cardWidth={"260px"}/>
                                </List.Item>)
                            }}
                        />
                        <p className={"browse-folder-label"} onClick={() => navigate("/artWork")}>Sfoglia Artwork</p>
                    </div>
                </Col>
            </Row>
        </HomeStyledDiv>
    )
}
