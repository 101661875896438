import actions from "./homeActions";

const initState = {
    lastArtWorks : null,
    userStatistics: null,
    visualizationStatistics: null,
    totalResult: null,
    updateList: false
}

export default  function homeReducer(state = initState, action){
    switch (action.type) {
        case actions.INITIALIZE_STATE:
            return {
                initState,
                updateList: true
            };
        case actions.GET_HOME_ADMIN_SUCCESS:
            return {
                ...state,
                lastArtWorks: action.payload.value.lastArtWorks,
                uiUserStatistics: action.payload.value.uiUserStatistics,
                uiVisualizationStatistics: action.payload.value.uiVisualizationStatistics,
                totalResult: action.payload.totalResult,
                updateList: false
            }
        case actions.GET_HOME_CONTENT_CREATOR_SUCCESS:
            return {
                ...state,
                lastArtWorks: action.payload.value.lastArtWorks,
                totalResult: action.payload.totalResult,
                updateList: false
            }
        default:
            return state;
    }
}

