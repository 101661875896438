import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Button, Col, Input, message, Popover, Row} from 'antd';
//import logo from '../img/Logo.png'
import logo from '../svg/Logo.svg'
import {useDispatch, useSelector} from "react-redux";
import actions from "../redux/auth/authActions";
import {ReactComponent as CloseIcon} from '../svg/close.svg';
import {ReactComponent as AlertIcon} from '../svg/alert.svg';
import {LoginStyle} from "../style/Login.style"
import {getRouteLandingPage, createNotification} from "../helpers/utility";

const {login, resetErrorMessage, setIdToken, recoverPassword, resetMessage} = actions

export default function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const token = useSelector(state => state.authReducer.idToken)
    const errorMessage = useSelector(state => state.authReducer.errorMessage)
    const recoverPasswordSuccess = useSelector( state => state.authReducer.recoverPasswordSuccess)
    const confirmMessage = useSelector( state => state.authReducer.message)

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [visible, setVisible] = useState(false)
    const [popoverMessage, setPopoverMessage] = useState("")
    const [showPasswordRecovery, setShowPasswordRecovery] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (token) {
            navigate(getRouteLandingPage())
        }
    }, [token])

    useEffect(() => {
        if (errorMessage) {
            setVisible(true)
            setPopoverMessage(errorMessage)
            dispatch(resetErrorMessage())
        }
    }, [errorMessage])

    useEffect(() => {
        if(confirmMessage){
            message.success(confirmMessage)
            setShowPasswordRecovery(false)
            dispatch(resetMessage())
        }
    }, [confirmMessage])

    useEffect(() => {
        dispatch(setIdToken())
        let message = searchParams.get("redirectMessage")
        if(message){
            createNotification("info", message)
        }
    },[])


    const handleClick = () => {
        setVisible(false)
        if (username !== "" && password !== "") {
            let data = {
                username: username,
                password: password
            }
            dispatch(login(data))
        } else {
            setVisible(true)
            setPopoverMessage("Valorizzare tutti i campi")
        }
    }

    const handlePasswordRecovery = () => {
        setVisible(false)
        if (username !== "" && newPassword !== "" && confirmNewPassword !== "") {
            if(newPassword === confirmNewPassword){
                let data = {
                    username: username,
                    newPassword: newPassword,
                }
                dispatch(recoverPassword(data))
            }else{
                setVisible(true)
                setPopoverMessage("Le password non corrispondono")
            }
        } else {
            setVisible(true)
            setPopoverMessage("Valorizzare tutti i campi")
        }
    }

    const handleBackPage = () => {
        setShowPasswordRecovery(false)
    }


    const closePopover = () => {
        setVisible(false)
    }

    return (
        <LoginStyle>
            <Row>
                <Col xs={24}
                     sm={{span: 12, offset: 6}}
                     md={{span: 10, offset: 7}}
                     lg={{span: 8, offset: 8}}
                     xl={{span: 6, offset: 9}}
                     xxl={{span: 4, offset: 10}}
                     className={"flex-center"}>
                    <img src={logo}/>
                    {showPasswordRecovery ? <>
                        <Input
                            placeholder={"Username"}
                            type={"text"}
                            onChange={(event) => setUsername(event.target.value)}
                        />
                        <Input
                            placeholder={"Nuova Password"}
                            type={"password"}
                            onChange={(event) => setNewPassword(event.target.value)}
                        />
                        <Input
                            placeholder={"Conferma Password"}
                            type={"password"}
                            onChange={(event) => setConfirmNewPassword(event.target.value)}
                        />
                        <Popover
                            content={
                                <div className={"flex-center-row"}>
                                    <AlertIcon className={"alert-icon"}/>
                                    {popoverMessage}
                                    <CloseIcon className={"close-icon"} onClick={closePopover}/>
                                </div>

                            }
                            color={"#E02020"}
                            placement={"bottomRight"}
                            visible={visible}
                        >
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <Button
                                    id={"passwordRecovery"}
                                    onClick={handlePasswordRecovery}>INVIA</Button>
                                <Button
                                    id={"back"}
                                    onClick={handleBackPage} className={"backButton"}>INDIETRO</Button>
                            </div>
                        </Popover>
                    </> : <>
                        <Input
                            placeholder={"Username"}
                            type={"text"}
                            onChange={(event) => setUsername(event.target.value)}
                        />
                        <Input
                            placeholder={"Password"}
                            type={"password"}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <Popover
                            content={
                                <div className={"flex-center-row"}>
                                    <AlertIcon className={"alert-icon"}/>
                                    {popoverMessage}
                                    <CloseIcon className={"close-icon"} onClick={closePopover}/>
                                </div>

                            }
                            color={"#E02020"}
                            placement={"bottomRight"}
                            visible={visible}
                        >
                            <Button
                                id={"login"}
                                onClick={handleClick}
                            >LOG IN</Button>
                        </Popover>
                        <p
                            style={{color: "white", textDecoration: "underline", fontSize: "16px", cursor: "pointer"}}
                            onClick={() => setShowPasswordRecovery(true)}
                        >
                            Hai dimenticato la password?
                        </p>
                    </>}
                </Col>
            </Row>
        </LoginStyle>
    )
}
