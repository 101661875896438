import styled from "styled-components"

export const TopBarStyleMobile = styled.div`
    
    .title{
      height: 70px;
      padding-top: 30px;
      padding-left: 60px;
      color: white;
      font-size: 18px;
      font-family: Gotham, sans-serif;
      font-weight: lighter;
      font-style: italic;
    }
    
    .icon{
      padding-right: 60px;
    }
`
