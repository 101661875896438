import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Popconfirm, Row, Spin} from "antd";
import dateFormat from "dateformat";
import PlayIcon from "../../svg/playIconBlack.svg";
import PauseIcon from "../../svg/Pausa.svg"
import EditIcon from "../../svg/editIconBlack.svg";
import DeleteIcon from "../../svg/trashIconBlack.svg";
import {CardStyledDiv} from "../../style/CardStyle";
import {endpoints} from "../../config/site.config";
import {getToken} from "../../helpers/utility";

export default function ArtworkCard(props) {

    const item = props.item
    const [image, setImage] = useState("")
    const [video, setVideo] = useState("")
    const [showVideo, setShowVideo] = useState(false)
    const [showImage, setShowImage] = useState(true)
    const videoRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [mediaUrl, setMediaUrl] = useState(null)
    const [gifOrImgObj, setGifOrImgObj] = useState(null)
    const [showGifOrImg, setShowGifOrImg] = useState(false)

    useEffect(() => {
        if (image === "") {
            setLoading(true)
            let requestBody = {name: item.markerUrl, width: 400, height: 400, fill: true}
            fetch(endpoints.getImageResource, {
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    }),
                    body: JSON.stringify(requestBody)
                }
            ).then(result => result.blob()).then(blob => {
                setImage(URL.createObjectURL(blob))
                setLoading(false)
            })
        }

        if(item.mediaUrl.includes("http:") || item.mediaUrl.includes("https:") || item.mediaUrl.includes("www")) {
            setMediaUrl(item.mediaUrl)
        } else if(item.mediaUrl.includes("mp4")) {
            if (video === "") {
                setLoading(true)
                let requestBody = {name: item.mediaUrl}
                fetch(endpoints.getImageResource, {
                        method: 'post',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken(),
                        }),
                        body: JSON.stringify(requestBody)
                    }
                ).then(result => result.blob()).then(blob => {
                    setVideo(URL.createObjectURL(blob))
                    setLoading(false)
                })
            }
        } else {
            setLoading(true)
            let requestBody = {name: item.mediaUrl}
            fetch(endpoints.getImageResource, {
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    }),
                    body: JSON.stringify(requestBody)
                }
            ).then(result => result.blob()).then(blob => {
                setGifOrImgObj(URL.createObjectURL(blob))
                setLoading(false)
            })
        }

        return () => {
            setImage("")
        }
    }, [])

    const handlePlay = () => {
        if (showVideo === false) {
            setShowImage(false)
            setShowVideo(true)
            videoRef.current.play()
        } else {
            setShowImage(true)
            setShowVideo(false)
            videoRef.current.pause()
            videoRef.current.load()
        }

    };

    const handlePlayGifOrImg = () => {
        if (showGifOrImg === false) {
            setShowImage(false)
            setShowGifOrImg(true)
        } else {
            setShowImage(true)
            setShowGifOrImg(false)
        }
    }


    return (
        <CardStyledDiv backgroundImage={showImage ? image : showGifOrImg ? gifOrImgObj : ""} cardHeight={props.cardHeight} cardWidth={props.cardWidth}>
            <Spin spinning={loading}>
                <div style={{width: "100%", maxWidth: props.cardWidth, maxHeight: props.cardHeight}}>
                    <div className={"crop-container aspect-ratio-16x9"}>
                        <video style={{display: !showVideo && "none"}} ref={videoRef} src={video}/>
                    </div>
                    <Card className={"whiteCard"} >
                        <div>
                            <Row style={{textAlign: "right"}}>
                                <p style={{
                                    lineHeight: "20px",
                                    fontSize: (window.innerWidth < 1600) ? "16px" : "20px",
                                }}>VIEWS</p>
                            </Row>
                            <Row style={{textAlign: "right"}}>
                                <p style={{fontWeight: "bold", lineHeight: "25px"}}>{item.views}</p>
                            </Row>
                            <Row>
                                <div style={{width: "100%"}}>
                                    <div className={"title"}>
                                        <p style={{
                                            fontWeight: "bold",
                                            fontSize: (window.innerWidth < 1600) ? "16px" : "20px",
                                            lineHeight: "20px"
                                        }}>{item.title}</p>
                                    </div>
                                </div>
                            </Row>
                            <Row className={"fullWidth"}>
                                <Col lg={12} sm={12} xs={12}>
                                    <Row><p style={{
                                        fontSize: (window.innerWidth < 1600) ? "13px" : "16px",
                                        lineHeight: "16px"
                                    }}>{dateFormat(new Date(item.creationDataTimestamp).toISOString(), "dd-mm-yyyy")}</p>
                                        {localStorage.getItem("roles") === "ROLE_ADMIN" && item.creatorUsername !== null &&
                                        <p style={{
                                            fontSize: (window.innerWidth < 1600) ? "13px" : "16px",
                                            lineHeight: "16px"
                                        }}>Creato
                                            da {item.creatorUsername === localStorage.getItem("username") ? "Te" : item.creatorUsername}</p>}
                                    </Row>
                                </Col>
                                <Col lg={12} sm={12} xs={12}>
                                    <Row/>
                                    <Row className={"rightRow"} justify={"space-around"} gutter={5}>
                                        <Col>
                                            {mediaUrl ? <a href={mediaUrl} target="_blank">
                                                <img src={PlayIcon} className={"icon"}/>
                                            </a> : video ? <img src={showVideo ? PauseIcon : PlayIcon} onClick={() => handlePlay()} className={"icon"}/>
                                                : gifOrImgObj ? <img src={showGifOrImg ? PauseIcon : PlayIcon} onClick={() => handlePlayGifOrImg()} className={"icon"} /> : <></>
                                            }
                                        </Col>
                                        <Col><img src={EditIcon}
                                                  onClick={() => props.handleEdit(item.artWorkId)} className={"icon"}/></Col>
                                        <Col><Popconfirm color={"white"}
                                                         title={"Sei sicuro di voler eliminare?"}
                                                         onConfirm={() => props.handleDelete(item.artWorkId)}>
                                            <img src={DeleteIcon} className={"icon"}/>
                                        </Popconfirm>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </div>
            </Spin>
        </CardStyledDiv>
    )
}
