import styled from "styled-components";
import backgroundImage from "../img/background.png"

export const LoginStyle = styled.div`
        background:  url(${backgroundImage}) no-repeat #0E0E0D center !important;
        background-size: 700px !important;
        height: 100vh;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        
        .flex-center{
          display: flex !important;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: 30vh;
        }
        
        .flex-center-row{
          display: flex !important;
          justify-content: center;
          flex-direction: row;
          align-items: center;
        }
        
        
        img{
          margin-bottom: 54px;
        }
        
        .ant-input{
          margin-top: 10px !important;
          border-radius: 16px !important;
          max-width: 320px;
        }
        
        .ant-btn{
          border-radius: 16px !important;
          width: 100vw;
          max-width: 320px;
          background-color: #335591 !important;
          border: 0 !important;
          color: white !important;
          margin-top: 10px;
        }
        
        .backButton{
          background-color: #9D9FA3 !important;
        }
        
        @media screen and (max-width: 576px){
          .ant-row{
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        
        input{
          font-family: GothamBook !important;
        }
        
        button{
          font-family: GothamRoundedBold !important;
        }
`
