import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./catalogActions";
import authActions from "../auth/authActions";

export function* getAll() {
    yield takeEvery(actions.GET_ALL, function* () {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCatalog, null, true)
        if(response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_SUCCESS,
                payload: response
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ERROR,
                payload: error
            })
        }
    })
}

export function* saveCatalog() {

    yield takeEvery(actions.SAVE_CATALOG, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveCatalog, data.newCatalog, true)

        if(response){
            yield put({
                type: actions.SAVE_CATALOG_SUCCESS,
                payload: response
            })

        }else{
            yield put({
                type: actions.SAVE_CATALOG_ERROR,
                payload: error
            })
        }

    })

}

export function* enableCatalog() {
    yield takeEvery(actions.ENABLE_CATALOG, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.enableCatalog + "/" + data.id, null, true)
        if(response){
            yield put({
                type: actions.ENABLE_CATALOG_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({
                type: actions.ENABLE_CATALOG_ERROR,
                payload: error
            })
        }
    })

}

export function* disableCatalog() {
    yield takeEvery(actions.DISABLE_CATALOG, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.disableCatalog + "/" + data.id, null, true)
        if(response){
            yield put({
                type: actions.DISABLE_CATALOG_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({
                type: actions.DISABLE_CATALOG_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteCatalog() {
    yield takeEvery(actions.DELETE_CATALOG, function* (data){
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteCatalog + "/" + data.id, null, true)
        if(response){
            yield put({
                type: actions.DELETE_CATALOG_SUCCESS,
                payload: response
            })
        }else{
            yield put({
                type: actions.DELETE_CATALOG_ERROR,
                payload: error
            })
        }
    })

}

export function* getCatalog() {
    yield takeEvery(actions.GET_BY_ID, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCataloById + "/" + data.id, null, true)
        if(response){
            yield put({
                type: actions.GET_BY_ID_SUCCESS,
                payload: response
            })
        }else{
            yield put({
                type: actions.GET_BY_ID_ERROR,
                payload: error
            })
        }
    })
}

export function* updateCatalog(){
    yield takeEvery(actions.UPDATE_CATALOG, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.saveCatalog + "/" + data.id, data.updatedCatalog, true)
        if(response){
            yield put({
                type: actions.UPDATE_CATALOG_SUCCESS,
                payload: response
            })
        }else{
            yield put({
                type: actions.UPDATE_CATALOG_ERROR,
                payload: error
            })
        }
    })
}
export default function* rootSaga() {
    yield all([
        getAll(),
        saveCatalog(),
        enableCatalog(),
        disableCatalog(),
        deleteCatalog(),
        getCatalog(),
        updateCatalog()
    ]);
}