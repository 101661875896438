import styled from "styled-components"

export const SidebarStyleMobile = styled.div`

    position: fixed;
    height: 90px;
    z-index: 1000;
    
    .sidebar-content{
        width: 100vw;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .icon-container{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        background: rgb(255,255,255);
        background: linear-gradient(135deg, rgba(255,255,255,0.01) 0%, rgba(255,250,250,0.15) 100%);
        border: solid 0.5px rgba(255, 255, 255, .33);
        border-radius: 25px;
    }
    
    .icon-container-rectangle{
        position: absolute;
        width: 100px;
        height: 100px;
        background: rgb(255,255,255);
        background: linear-gradient(135deg, rgba(255,255,255,0.01) 0%, rgba(255,250,250,0.15) 100%);
        border: solid 0.5px rgba(255, 255, 255, .33);
    }
    
    .icon-container-circle{
      position: absolute;
      width: 80px;
      height: 40px;
      background: linear-gradient(135deg, rgba(26,32,33) 0%, rgba(38,38,29) 100%);
      border: solid 0.5px rgba(255, 255, 255, .33);
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      bottom: -1px;
      border-bottom: 0;
    }
    
    .icon-container-circle-central{
      margin-top: -28px;
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      
    }
    
    .color{
        background-color: #335591;
    }
    .white{
        background-color: #FFFFFF;
    }
    
    .pointer-container{
        position: absolute;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        padding-left: 28px;
        padding-right: 12px;
    }
    
    .pointer{
        transform: rotate(90deg);
        position: relative;
        height: 23px;
        float: right;
        margin-right: 14px;
    }
    
    .page-icon{
        margin: auto;
        height: 100vh;
        max-height: 30px;
    }
      
    .icon-container{
        img{
            cursor: pointer;
        }
    }
    
`
