import authSaga from "./auth/authSaga";
import tenantSaga from "./tenant/tenantSaga";
import adminSaga from "./admin/adminSaga";
import contentCreatorSaga from "./contentCreator/contentCreatorSaga";
import artWorkSaga from "./artWork/artWorkSaga";
import folderSaga from "./folder/folderSaga";
import homeSaga from "./home/homeSaga"

import {all} from 'redux-saga/effects';
import catalogSaga from "./catalog/catalogSaga";

export function* rootSaga() {
    yield all([
        authSaga(),
        tenantSaga(),
        adminSaga(),
        contentCreatorSaga(),
        artWorkSaga(),
        folderSaga(),
        homeSaga(),
        catalogSaga()
    ]);
}
