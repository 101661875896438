import styled from "styled-components";

export const HomeStyledDiv = styled.div`   
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 150px;
    
    .add-artwork-card{
        height: 400px;
        width: 100%;
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 20px;
    }
    
    @media screen and (max-width: 1600px){
        .add-artwork-card{
          height: 350px;
        }
    }
    
    .add-artwork-label{
        font-size: 26px;
        color: white;
    }
    
    .last-artwork-card{
        height: 400px;
        width: 100%;
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        display: flex;
        flex-direction: column;
        padding: 20px 50px;
    }
    
    @media screen and (max-width: 1600px){
        .last-artwork-card{
          height: 350px;
          padding: 20px 30px;
        }
    }
    
    .last-artwork-label{
        color: white;
        font-size: 20px;
        font-family: GothamBlack,sans-serif;
    }
    
    .browse-folder-label{
        position: absolute;
        bottom: 10px;
        right: 80px;
        text-decoration: underline;
        font-size: 14px;
        font-family: GothamBook,sans-serif;
        color: white;
        cursor: pointer;
    }
    
    .line-chart-container{
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        padding: 20px;
        height:400px;
    }
    
    .bar-chart-container{
        border: solid 0.5px rgb(255,255,255, 0.33);
        border-radius: 26px;
        background: linear-gradient(135deg, rgba(255,255,255,0.023144291896446068) 0%, rgba(255,252,252,0.09317230310092783) 32%, rgba(255,250,250,0.1632003143054097) 100%);
        padding: 20px 20px 20px 10px;
        height: 400px;
    }
    
    .line-chart-label{
        color: white;
        font-size: 20px;
        font-family: GothamBlack,sans-serif;
    }
    
`
