import styled from "styled-components";

export const CardStyledDiv = styled.div`

    display: flex;
    justify-content: center;
  
    .ant-row {
        padding-top: unset !important;
    }
  
    .ant-card {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1)), url(${props => props.backgroundImage}) !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        border: 0 !important;
        border-radius: 30px !important;
        min-height: ${props => props.cardHeight ? props.cardHeight : "400px"} !important;
        margin-bottom: ${props => props.setMarginBottom ? props.setMarginBottom : "20px"} !important;
        margin-top: ${props => props.setMarginTop ? props.setMarginTop : "20px"} !important;
    }
    
    .ant-card-body {
        height: 100% !important;
        width: 100%;
    }
    
    .ant-card-body p {
        color: black !important;
        margin: unset !important;
        
    }
    
    .ant-card-body a {
        text-decoration: underline;
        text-decoration-color: white;
    }
    
    .centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .fullHeight {
        height: 100% !important;
    }
    
    .fullWidth {
        width: 100% !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        padding: unset !important;
    }
    
    .whiteCard {
        max-height: ${props => props.cardHeight ? props.cardHeight : "400px"} !important;
        background: linear-gradient(#a5a5a5, #ffffff);
        border: 0 !important;
        border-radius: 30px !important;
        width: 100vw;
        display: flex;
        align-items: flex-end;
        max-width: ${props => props.cardWidth ? props.cardWidth : "400px"} !important;
    }
    
    .whiteCard div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    .ant-row .rightRow {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-end !important;
    }
    
    .ant-list-empty-text{
        color: white !important;
    }
    
    video {
      position: absolute;
      max-height: 100%;
      max-width: ${props => props.cardWidth ? props.cardWidth : "400px"} !important;
      top: 50%;
      transform: translateY(-50%);
    }
    
    .card-container{
      cursor: pointer;
    }
    
    .ant-spin-nested-loading{
        width: 100%;
        max-width: ${props => props.cardWidth ? props.cardWidth : "400px"} !important;
    }
    
    .gifOrImg {
      position: absolute;
      max-height: 100%;
      max-width: ${props => props.cardWidth ? props.cardWidth : "400px"} !important;
      top: 50%;
      transform: translateY(-50%);
    }
    
    .title{
      position: relative;
      display: block !important;
      p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    @media screen and (max-width: 1600px){
        .icon{
          width: 20px;
        }
    }
    
`
