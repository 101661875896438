import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'

import {endpoints} from "../../config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./tenantActions";
import authActions from "../auth/authActions";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_TENANT, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.findAllTenant, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_TENANT_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_TENANT_ERROR,
                payload: error
            });
        }
    })
}

export function* enable() {
    yield takeEvery(actions.ENABLE_TENANT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.PATCH, endpoints.enableTenant + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.ENABLE_TENANT_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_TENANT_ERROR,
                payload: error
            });
        }
    })
}

export function* saveNewTenant() {
    yield takeLatest(actions.SAVE_NEW_TENANT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.saveNewTenant, data.payload, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_TENANT_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_NEW_TENANT_ERROR,
                payload: error
            })
        }
    })

}

export function* deleteTenant() {
    yield takeLatest(actions.DELETE_TENANT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.delete, endpoints.deleteTenant + "/" + data.id, null, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_TENANT_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_TENANT_ERROR,
                payload: error
            })
        }
    })

}

export function* findTenantById() {
    yield takeLatest(actions.GET_TENANT_BY_ID, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.get, endpoints.findTenantById + "/" + data.id, null, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_TENANT_BY_ID_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_TENANT_BY_ID_ERROR,
                payload: error
            })
        }
    })

}

export function* updateTenant() {
    yield takeLatest(actions.UPDATE_TENANT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.updateTenant + "/" + data.id, data.data, true)
        if (response){
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_TENANT_SUCCESS,
                payload: response.value
            })
        }else{
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_TENANT_ERROR,
                payload: error
            })
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(enable),
        fork(saveNewTenant),
        fork(deleteTenant),
        fork(findTenantById),
        fork(updateTenant)
    ]);
}

