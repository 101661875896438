const actions = {

    INITIALIZE_STATE: "INITIALIZE_STATE",

    GET_HOME_ADMIN: "GET_HOME_ADMIN",
    GET_HOME_ADMIN_SUCCESS: "GET_HOME_ADMIN_SUCCESS",
    GET_HOME_ADMIN_ERROR: "GET_HOME_ADMIN_ERROR",

    GET_HOME_CONTENT_CREATOR: "GET_HOME_CONTENT_CREATOR",
    GET_HOME_CONTENT_CREATOR_SUCCESS: "GET_HOME_CONTENT_CREATOR_SUCCESS",
    GET_HOME_CONTENT_CREATOR_ERROR: "GET_HOME_CONTENT_CREATOR_ERROR",

    initializeHomeState: () => ({
        type: actions.INITIALIZE_STATE
    }),

    getHomeAdmin : ()=>({
       type: actions.GET_HOME_ADMIN
    }),

    getHomeContentCreator : ()=>({
        type: actions.GET_HOME_CONTENT_CREATOR
    }),
}

export default actions
